import useSWR from "swr";
import {CredentialFetcher} from "hooks-sna";
import {ColumnsType} from "antd/es/table";
import React, {useContext} from "react";
import {Table, Tag} from "antd";
import useAuth from "auth-sna";
import {TranslateContext} from "translate-provider";


const BASE_URL_API = process.env.REACT_APP_API_V1;
const API2 = process.env.REACT_APP_API_V2;

export default function ImportOrderTable(){

    const user = useAuth();
    const userData = user.getUser();
    const { t } = useContext(TranslateContext);

    const {data, loading, error} = useSWR(`${API2}/import-orders`, CredentialFetcher);

    const columns: ColumnsType = [
        {
            title: t('importDate'),
            dataIndex: 'DateImport',
            key: 'DateImport',
            sorter: (a, b) => new Date(a.DateImport) - new Date(b.DateImport),
            sortDirections: ['descend', 'ascend'],
            render: (date) => <>
                {
                    date !== '1900-01-01T00:00:00.000Z'
                        ? new Date(date).toLocaleDateString('fr-FR', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                        })
                        : '-'
                }
            </>,
            onFilter: (value, record) => {
                const recordDate = new Date(record.DateImport).setHours(0, 0, 0, 0);
                const filterDate = new Date(value).setHours(0, 0, 0, 0);

                return recordDate === filterDate;
            },
        },
        {
          title: 'importId',
          dataIndex: 'IdClientExp_dition',
          key: 'IdClientExp_dition',
        },
        {
            title: t('status'),
            dataIndex: 'Import_',
            key: 'procress',
            render: (data) => {
                if (!data) {
                    return <Tag color="blue">{t('inProcess')}</Tag>
                } else {
                    return <Tag color="green">{t('completed')}</Tag>
                }
            }
        },
    ]

    return(
        <div>
            <Table
                columns={columns}
                dataSource={data || []}
                loading={loading}
                rowKey="IdClientExp_dition"
            />
        </div>
    )
}