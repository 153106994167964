import React, {memo} from "react";
import {LogoSNA, SigleSNA} from "sna-style";
import {Header} from "antd/es/layout/layout";
import UploadProduct from "import-article";
import {Route, Routes} from "react-router-dom";
import {COLOR} from "theme-provider";
import {AsideMenu, GetMaintenance, UserNav} from "sna-components";
import Logistic from "logistic/Logistic";
import Stock from "sna-stock";
import UploadKit from "import-kit";
import ImportOrder from "import-commandes";
import FindRelay from "mondial-relay/src/FindRelay";
import RelayError from "mondial-relay/src/RelayError";
import Profil from "profil-utilisateur/src/Profil"
import { Notification } from "sna-components";

const showNotification = process.env.REACT_APP_SHOW_NOTIFICATION === 'true';

function DashBoard() {

    const headerStyle = {
        textAlign: "end",
        color: COLOR["TEXT_COLOR"],
        backgroundColor: "transparent",
    };

    return (
        <div className="container-fluid custom-bg ">
            <div className="row justify-content-between">
                {GetMaintenance()}
                <AsideMenu excludeNavItems={['intranet']} logo={LogoSNA} logoCollapsed={SigleSNA} />
               
                <div className="col d-flex flex-column vh-100 overflow-y-auto">
                    <Header className="mt-2 d-flex flex-row justify-content-end ps-2 align-items-center" style={headerStyle}>
                        <UserNav />
                    </Header>

                      {showNotification && <Notification />}
                    <Routes>
                        {showNotification && <Route path='profil' element={<Profil/>}/>}
                        <Route path="import/*">
                            <Route path="product-import" element={<UploadProduct />} />
                            <Route path="import-kit" element={<UploadKit />} />
                            <Route path="import-orders" element={<ImportOrder />} />
                        </Route>


                        <Route path="logistic/*" >
                            <Route path='stock' element={<Stock />} />
                            <Route path="commands" element={<Logistic />} />
                            <Route path="mondial-relay" element={<FindRelay />} />
                            <Route path="mondial-relay-errors" element={<RelayError />} />
                        </Route>

                    </Routes>
                </div>
            </div>
        </div>
    );
}
//maj

export default memo(DashBoard);
