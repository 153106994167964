import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Space, Spin, Steps, Typography, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { ToastContext } from "toast-provider";
import { useKitCsvToJson, useUploadCsv } from "hooks-sna/index";
import { TranslateContext } from "translate-provider";
import UseNewkit from "./src/newKit/UseNewKit";

const { Text } = Typography;

const { Dragger } = Upload;

const BASE_URL_API = process.env.REACT_APP_API_V1;

export default function UploadKit() {
    const toast = useContext(ToastContext);
    const [form] = Form.useForm();
    const [csvUploaded, setCsvUploaded] = useState(false);
    const { t } = useContext(TranslateContext);
    const [step, setStep] = useState(0);
    const formData = new FormData();
    const [csv, setCsv] = useState(null);
    const [csvFileList, setCsvFileList] = useState([]);
    const [operation, setOperation] = useState('');
    const [error, setError] = useState(null);
    const [onSubmit, cancelUploadHandler, uploadProgress, cancelUpload, loading, result] = useUploadCsv(toast, t, setError)
    const [jsonData, handleFileChange, customer, fileValid] = useKitCsvToJson();
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (result) {
            toast.success(result.message, 15);
            reinit();
        }

    }, [result]);


    const reinit = () => {
        form.resetFields();
        setIsValid(false);
        setOperation('')
        setCsvUploaded(false);
        setStep(0);
        setCsv(null);
        setCsvFileList([]);
    }

    const nextStep = () => {
        setStep(step + 1);
    };

    const prev = () => {
        if (step !== 0) setStep(step - 1)

    }

    const stepsItem = [
        {
            title: <p className='d-none d-lg-block d-sm-none'>{t('csvImport')}</p>,
            content: <>
                {error && <Text type="danger">{error.response?.data?.message ? error.response?.data?.message : "une erreur est survenue, veuillez contacter un administrateur"}</Text>}
                {loading ? <Spin size="large" />
                    :
                    <>
                        <Form.Item name="zip"  >
                            <Dragger
                                showUploadList={csvUploaded}
                                fileList={csvFileList}
                                onChange={({ fileList }) => {
                                    setCsvFileList(fileList)
                                }}
                                beforeUpload={async (file) => {
                                    const isCSV = file.type === 'text/csv';
                                    if (!isCSV) {
                                        setCsvFileList([]);
                                        toast.error('fichier non conforme', 15);
                                        setCsvUploaded(isCSV);
                                        return false;
                                    }

                                    //ENVOI DU FICHIER DANS LE HOOK useKitCsvToJson
                                    await handleFileChange(file)

                                    setCsvUploaded(isCSV);
                                    setIsValid(true)
                                    return isCSV;
                                }}
                                onRemove={file => {
                                    setCsvFileList(prev => prev.filter(f => f.uid !== file.uid));
                                    setCsvUploaded(false);
                                    setIsValid(false)
                                }}
                                defaultFileList={[]}
                                maxCount={1}
                                listType="picture"
                                multiple={false}
                                accept=".csv"
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text"> {t('uploadCSV')} </p>
                                <div className='d-none d-lg-block d-sm-none'>
                                    <p className="ant-upload-hint">

                                    </p>
                                </div>
                            </Dragger>
                        </Form.Item>
                        <Form.Item>
                            <Space direction={"vertical"}>
                                <Button
                                    type="primary"
                                    onClick={nextStep}
                                    disabled={isValid === false}
                                >
                                    Suivant
                                </Button>
                            </Space>
                        </Form.Item>
                    </>
                }
            </>
        },
        {
            title: <p className='d-none d-lg-block d-sm-none'>{t('kitImport')}</p>,
            content: <UseNewkit prev={prev} jsonData={jsonData} resetFields={reinit} isValid={isValid} />,
        }
    ]
    return (
        <div className="d-flex justify-content-center " >
            <Form
                form={form}
                onFinish={async () => {
                    if (fileValid) {
                        // Append the files to formData
                        formData.append('csv', jsonData);
                        await onSubmit(jsonData, { customer: customer }, `${BASE_URL_API}/item`);
                        if (!error && error !== null) {
                            nextStep();
                        }
                    } else {
                        toast.error('Des champs obligatoires ne sont pas remplis dans le fichier.', 15);
                    }
                }}
                layout="vertical"
                className="rounded-5 p-3 w-90"
            >
                <Steps
                    className='d-none d-sm-none d-md-flex d-lg-flex  m-0 '
                    size="small"
                    current={step}
                    items={stepsItem}
                />
                <div className="d-flex d-md-flex flex-column justify-content-center p-sm-0 ">
                    {stepsItem[step].content}
                </div>
            </Form>
        </div>
    );
};