import {useCallback, useEffect, useState} from "react";
import axios from "axios";

const cache = new Map();

let interval;
let requestInterceptorId;
let responseInterceptorId;

function setupRequestInterceptor(setProgress) {
    requestInterceptorId = axios.interceptors.request.use((config) => {
        setProgress(0);
        return config;
    });
}

function setupResponseInterceptor(setProgress) {
    responseInterceptorId = axios.interceptors.response.use(
        (response) => {
            setProgress(100);
            return response;
        },
        (error) => {
            setProgress(100);
            return Promise.reject(error);
        }
    );
}

function progressInterval() {

}

function useFetch({ url, body = null, filter, page = null, pageSize = null, cacheMode = false, method = 'GET' } = {}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [progress, setProgress] = useState(0);
    const [targetProgress, setTargetProgress] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [downloadProgress, setDownloadProgress] = useState(0);

    const fetchData = useCallback(async () => {
        setProgress(0);
        setLoading(true);

        const cacheKey = JSON.stringify({url, filter, page, pageSize, body});


        if (cache.has(cacheKey) && cacheMode!==false) {
            console.log("cacheKey",cacheKey);
            console.log("from cache",cache.get(cacheKey));
            setData(cache.get(cacheKey));
            setLoading(false);
            return;
        }

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        let params = {
            withCredentials: true,
            method: method,
            skip: page,
            take: pageSize,
            url: url,
            params: {...filter, skip: page, take: pageSize},
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            cancelToken: source.token,
            onDownloadProgress: (progressEvent) => {

                    const percentCompleted = 50 + Math.round(
                        (progressEvent.loaded * 50) / progressEvent.total
                    );
                    setProgress(percentCompleted);

            },
        };

        if (method !== 'GET') {
            params.data = body;
        }


        try {

            setError(false);
            let response = await axios(params).then((response) => {

                setProgress(100);
                // console.log("from useFetch",response);
                return response;
            });

            if(response.status && response.status === 204){
                setProgress(100);
                setError(true);
                setLoading(false);
                return;
            }

            setData(response.data);
            setProgress(100);
            setLoading(false);

            if(cacheMode){
                cache.set(cacheKey, response.data);
            }

        } catch (e) {
            if (axios.isCancel(e)) return;
            setProgress(100)
            setLoading(false);
            setError(true);
            setHasMore(false);
        }


        return () => {
            clearInterval(progressInterval);
            source.cancel('Operation canceled by the user.');
        };
    }, [url, filter, page, pageSize, body, method, cacheMode, loading]);

    useEffect(() => {
        setupRequestInterceptor(setProgress);
        setupResponseInterceptor(setProgress);

        return () => {
            axios.interceptors.request.eject(setupRequestInterceptor);
            axios.interceptors.response.eject(setupResponseInterceptor);
        };
    }, []);

    return [loading, error, data, hasMore, metadata, progress, fetchData];
}




export default useFetch;
