import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './page/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import './assets/style/style.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));


if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
}


root.render(
    // <React.StrictMode>
        <BrowserRouter>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </BrowserRouter>
    // </React.StrictMode>
);
//hotfix sna

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
