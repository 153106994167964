import React, {Fragment, useState} from 'react';
import spinner from '../assets/Spinner-sna.gif';
import {Col, Image} from 'antd';
 export default function ImageCustom({ src , width = '100%' , height = '100%' }) {
    const [loading, setLoading] = useState(true);
    const [displayImg, setDisplayImg] = useState(true);

    const handleImageLoad = () => {
        setLoading(false);
    };
    const handleImageError = () => {
        setDisplayImg(false);
        setLoading(false)
    };



    return (
        <Fragment>
            <Col span={8}>
            <div className='d-flex justify-content-center align-items-center w-100'>
                {loading && <img width={75} src={spinner} alt={""}></img>}
            </div>
            {displayImg &&
                    <Image
                        onLoad={handleImageLoad}
                        onError={handleImageError}
                        src={src}
                        // width={width}
                        height={height}
                        alt="image"
                        style={{ display: loading ? 'none' : 'block' }}
                    />
            }
            </Col>
        </Fragment>
    );
}
