import React, { memo, Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { Badge, Button, Descriptions, Form, Image, Input, InputNumber, Modal, Modal as AntModal, Popconfirm, Select, Skeleton, Space, Typography } from "antd";
import useFetchOrder from "hooks-sna/src/useFecthOrders";
import TagOrder from "./TagOrder";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ArticleTable from "./table/ArticleTable";
import ColisTable from "./table/ColisTable";
import { EnvironmentOutlined } from "@ant-design/icons";
import { animated, config, useSpring } from '@react-spring/web'

import moment from 'moment';

import { TranslateContext } from "translate-provider";
import { ToastContext } from "toast-provider";
import useAuth from "auth-sna";
import { webSocketContext } from "websocket-provider";
import { Spinner } from "sna-components";

const { Link, Text, Paragraph } = Typography;


const BASE_URL = process.env.REACT_APP_API_V2;

// 30 min inactivity timer
const INACTIVITY_TIMEOUT = 30 * 60 * 1000;

//test Inactivity timer 30s
const TEST_INACTIVITY_TIMEOUT = 30 * 1000;


/**
 * creation de la modification de la commande, la création de la modification est stockée dans la base SQL, et un trigger est lancé pour stocker sur rabbitmq
 * la commande va passer en 'en cour de modification' du fait que la colonne "proceeded" est en false
 * au traitement de la modification par le back, la colonne "proceeded" passe en true
 *
 * @param orderData
 * @param toast
 * @return {Promise<any|null>}
 */
async function createApiModificationOrder(orderData, toast) {

    const response = await fetch(`${BASE_URL}/update-order/new`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
    });

    if (response.ok) {
        const newOrder = await response.json();
        return newOrder;
    } else {
        toast.error('Failed to update order, try later');
        return null;
    }
}


function checkUserActivity(callback) {
    /**
     * GESTION DE L'INACTIVITE DE L'UTILISATEUR
     */
    const [inactivityTimer, setInactivityTimer] = useState(null);

    const resetTimer = (() => {
        if (inactivityTimer) {
            clearTimeout(inactivityTimer);
        }

        const timer = setTimeout(() => {
            // Votre logique pour unlock la commande et passer en editMode false
            callback();
        }, TEST_INACTIVITY_TIMEOUT); // 30 minutes

        setInactivityTimer(timer);
    });

    useEffect(() => {
        // Écouteurs d'événements pour détecter l'activité de l'utilisateur
        window.addEventListener("mousemove", resetTimer);
        window.addEventListener("keydown", resetTimer);

        // Initialisation du timer lors du montage du composant
        resetTimer();

        return () => {
            // Nettoyage lors du démontage du composant
            if (inactivityTimer) {
                clearTimeout(inactivityTimer);
            }
            window.removeEventListener("mousemove", resetTimer);
            window.removeEventListener("keydown", resetTimer);
        };
    }, [resetTimer, inactivityTimer]);
}

function ModalOrder({ show, setShow, id, socket }) {

    const { t } = useContext(TranslateContext);
    let toast = useContext(ToastContext);
    const ws = useContext(webSocketContext);
    const auth = useAuth();

    const [loading, , data, Load] = useFetchOrder(`${BASE_URL}/logistic/order/${id}`)
    const [, , dataDest, LoadDest] = useFetchOrder(`${BASE_URL}/user/client-destination`)
    const [loadingCountry, , dataCountry, loadCountry] = useFetchOrder(`${BASE_URL}/utils/get-country`)
    const [loadingService, , dataService, loadService] = useFetchOrder(`${BASE_URL}/user/client-trans-service`)
    const [saving, setSaving] = useState(false);
    const [loadingCancelation, setLoadingCancelation] = useState(false);
    const [form] = Form.useForm();
    const [editMode, setEditMode] = useState(false)
    const [loadingMsg, setLoadingMsg] = useState("");

    // checkUserActivity(()=>{
    //     if(!ws.socket) return
    //     ws.socket.emit("unlockOrder", {
    //         order: id,
    //         client: auth.user.Login,
    //     });
    //     setEditMode(false);
    // });


    useEffect(() => {

        try {
            if (ws && ws.socket) ws.socket.on("lockOrderResponse", (response) => {
                console.log(response);
                if (response.success) {
                    setEditMode(true);
                    setLoadingCancelation(false)
                    setLoadingMsg("");
                } else {
                    setEditMode(false)
                    console.log("setEditMode false lockorderresponse");
                    handleWiggle();
                    toast.error(`Failed to lock order: ${response.message}`);
                }
            });

            if (ws && ws.socket) ws.socket.on("unlockOrderResponse", (response) => {
                console.log(response);
                if (response.success) {
                    console.log("editMode", editMode);
                    Load().then(r => {
                        setEditMode(false);
                        setSaving(false);
                        setLoadingCancelation(false)
                        SetLoadingMsg("");
                    })
                } else {
                    setEditMode(true)
                    console.log(response);
                    handleWiggle();
                    toast.error(`Failed to unlock order: ${response.message}`);
                }
            });
            if (ws && ws.socket) ws.socket.on("unlockOrderResponseAfterUpdate", (response) => {
                console.log(response);
                if (response.success) {
                    console.log("editMode", editMode);
                    Load().then(r => {
                        setLoadingCancelation(false)
                        setEditMode(false);
                        setSaving(false);
                    })
                } else {
                    setEditMode(true)
                    console.log(response);
                    handleWiggle();
                    toast.error(`Failed to unlock order: ${response.message}`);
                }
            });

            if (ws && ws.socket) ws.socket.on("reload", async (response) => {
                console.log(response);
                if (response.success) {
                    console.log(response);
                    console.log(data.IdCdeLogis)
                    if (response.orderId === id) resetAndLoad()
                }
            });

            if (ws && ws.socket) ws.socket.on("orderModificationResponse", async (response) => {
                console.log(response);
                if (response.success) {
                    toast.success(`Order updated successfully`);
                    resetAndLoad()
                } else {
                    setEditMode(true);
                    setSaving(false)
                    setLoadingCancelation(false)
                    console.log(response);
                    handleWiggle();
                    toast.error(`An error occur: ${response.message}`);
                }
            });


            if (ws && ws.socket) ws.socket.on("Unauthorized", (response) => {
                setEditMode(false)
                console.log("setEditMode false Unauthorized");
                console.log(response);
                toast.error(`Failed to lock order: ${response.message}`);
            });


        } catch (e) {
            console.log(e);
        }

    }, [socket]);


    const lockOrder = useCallback(() => {
        setLoadingCancelation(true)
        setLoadingMsg("Locking order...");
        if (!ws.socket) {
            toast.error("Failed to lock order, try later");
            return;
        }
        try {
            ws.lockOrder(id, auth.user.Login)
        } catch (e) {
            console.log(e);
        }

    }, [socket, id, auth.user.id]);

    const [backUp, setBackUp] = useState(null)

    const handleEdit = () => {
        if (editMode) {
            //we save current data in form
            AntModal.confirm({
                style: { color: 'red' },
                centered: true,
                mask: false,
                content: "Are you sure you want to close the modal without saving changes?",
                okText: "Yes",
                cancelText: "No",
                onOk: () => {
                    setLoadingCancelation(true)
                    setLoadingMsg("Unlocking order...");
                    ws.unlockOrder(id, auth.user.Login)
                },
                onCancel: () => {
                    // Do nothing
                }
            });

        } else {
            console.log(form.getFieldsValue());
            setBackUp(JSON.stringify(data.CdeLogisLiv?.[0]))
            lockOrder();
        }
    }


    useEffect(() => {
        if (show) {
            Load();
            loadCountry()
        }
    }, [show, id]);


    const [destinataire, setDestinataire] = useState(null)

    useEffect(() => {
        if (data) {
            setDestinataire(data.CdeLogisLiv?.[0])
        }
    }, [data])



    const createOptions = (data) => {
        let options = [];
        data.map((item) => {
            options.push({
                value: item['NomD'],
                label: item['NomD'],
                data: item,
            });
        });
        return options;
    };



    const createCountryOptions = (data) => { //transfrom dataCountry objet {CodeLettre:  "FR", pays_en: "France"} to array [{value: "FR", label: "France"}]
        let options = [];
        Object.entries(data).map(([key, value]) => {
            options.push({
                value: value['CodeLettre'],
                label: value['pays_en'],
            });
        });
        return options;
    };



    const destinataireFields = {
        NomDestinataire: {
            label: t('Destinataire'),
            dataName: 'contactFirstName',
            defaultValue: data.CdeLogisLiv?.[0]?.NomDestinataire,
            formType: 'string',
            maxLength: 150,
            type: 'string',
            rules: [{
                required: true,
                message: 'Nom obligatoire',
            }, {
                //pattern that includes space
                pattern: /^[^/>=+]+$/,
                message: 'Nom invalide',
            }, {
                max: 150,
                message: 'Name must be less than 150 characters',
            }],

        },
        NomContact: {
            label: t('nomContact'),
            dataName: 'NomContact',
            defaultValue: data.CdeLogisLiv?.[0]?.NomContact,
            formType: 'string',
            maxLength: 50,
            type: 'string',
            rules: [{
                required: true,
                message: 'Nom obligatoire',
            }, {
                //pattern that includes space
                pattern: /^[^/>=+]+$/,
                message: 'Nom invalide',
            }, {
                max: 150,
                message: 'Name must be less than 150 characters',
            }],

        },
        Adresse1: {
            label: t('Adress'),
            dataName: 'adr1',
            defaultValue: data.CdeLogisLiv?.[0]?.Ad1,
            formType: 'string',
            maxLength: 35,
            type: 'string',
            rules: [{
                required: true,
                message: 'Adresse obligatoire',
            }, {
                //pattern avec espace
                pattern: /^[^>=+]+$/,
                message: 'Adresse invalide',
            }, {
                max: 35,
                message: 'Adresse must be less than 35 characters',
            }],

        },
        Adresse2: {
            label: t('Adress 2'),
            dataName: 'adr2',
            defaultValue: data.CdeLogisLiv?.[0]?.Ad2,
            formType: 'string',
            maxLength: 35,
            type: 'string',
            rules: [{
                required: false,
            }, {
                pattern: /^[^>=+]+$/,
                message: 'Adresse invalide',
            }, {
                max: 35,
                message: 'Adresse must be less than 35 characters',
            }],
        },
        Adresse3: {
            label: 'Adresse 3',
            dataName: 'adr3',
            defaultValue: data.CdeLogisLiv?.[0]?.Ad3,
            formType: 'string',
            maxLength: 35,
            type: 'string',
            rules: [{
                required: false,
            }, {
                pattern: /^[^>=+]+$/,
                message: 'Adresse invalide',
            }, {
                max: 35,
                message: 'Adresse must be less than 35 characters',

            }],

        },
        CodePostal: {
            label: 'Code postal',
            dataName: 'zipCode',
            defaultValue: data.CdeLogisLiv?.[0]?.CodePostal,
            formType: 'string',
            rules: [{
                required: true,
                message: 'Postal code obligatoire',
            },
            // {
            //     pattern: /^[0-9]{2,11}$/,
            //     message: 'Code postal invalide',
            //
            // },
            {
                max: 11,
                message: 'Code postal too long (max 11 characters)',
            }
            ],
        },
        CodeEtat: {
            label: 'state',
            dataName: 'CodeEtat',
            defaultValue: data.CdeLogisLiv?.[0]?.CodeEtat,
            formType: 'string',
            rules: [
                //is required onyl if country is US
                {
                    required: data.CdeLogisLiv?.[0]?.CodePaysLettre === 'US' || form.getFieldsValue('state') === 'US',
                    message: 'state obligatoire',
                }, {
                    max: 35,
                    message: 'state too long (max 11 characters)',
                }
            ],
        },
        Ville: {
            label: 'Ville',
            dataName: 'city',
            defaultValue: data.CdeLogisLiv?.[0]?.Ville,
            formType: 'string',
            masxLength: 35,
            rules: [{
                required: true,
                message: 'Ville obligatoire',
            }, {
                pattern: /^[^>=+]+$/,
                message: 'Ville invalide',
            }, {
                max: 35,
                message: 'Ville must be less than 35 characters',

            }],

        },
        CodePaysLettre: {
            label: 'Pays',
            dataName: 'countryCode',
            defaultValue: dataCountry && data.CdeLogisLiv?.[0]?.CodePaysLettre ? dataCountry[data.CdeLogisLiv?.[0]?.CodePaysLettre]?.CodeLettre : undefined,
            formType: 'select',
            maxLength: 2,
            optionSelect: dataCountry && data.CdeLogisLiv?.[0]?.CodePaysLettre ? createCountryOptions(dataCountry) : [],

            rules: [{
                required: true,
                message: 'Pays obligatoire',
            }, {
                enum: dataCountry && data.CdeLogisLiv?.[0]?.CodePaysLettre ? createCountryOptions(dataCountry) : [],
                message: 'Pays invalide',
            }],
        },
        Tel: {
            label: 'Téléphone',
            dataName: 'contactPhone',
            defaultValue: data.CdeLogisLiv?.[0]?.T_l_phone,
            formType: 'string',
            maxLength: 50,
            validator: 'phone',
            rules: [
                // {
                //     required: true,
                //     message: 'Téléphone obligatoire',
                // },
                // {
                //     pattern: /^[+]?[\s0-9]*([0-9][\s0-9]*){10}$/,
                //     message: 'Téléphone invalide',
                // }

                {
                    max: 50,
                    message: 'Téléphone must be less than 50 characters',
                }],

        },
        Mail: {
            label: 'Email',
            dataName: 'contactEmail',
            defaultValue: data.CdeLogisLiv?.[0]?.Mail,
            formType: 'string',
            maxLength: 150,
            rules: [
                {
                    required: true,
                    message: 'Please input the E-mail!',
                }
                ,
                {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                }],
        },
        IdPointRelais: {
            label: 'Id point relais',
            dataName: 'IdPointRelais',
            defaultValue: data.CdeLogisLiv?.[0]?.IdPointRelais,
            formType: 'string',
            maxLength: 50,
        }
    }


    const resetAndLoad = () => {
        setSaving(false)
        setLoadingCancelation(false)
        setLoadingMsg("")
        Load().then(r => {
            setEditMode(false);
        })
    }

    /**
     * generate the destination  with edit mode
     * @return {JSX.Element}
     */
    const renderDestinaire = (editMode = false) => {

        //store data in current form

        return Object.entries(destinataireFields).map(([key, value]) => {

            if (editMode) {
                return <Form.Item
                    label={value.label}
                    name={value.dataName}
                    initialValue={value.defaultValue}
                    rules={value.rules}
                    key={key}
                >
                    {generateInput(value)}
                </Form.Item>
            }

            return <Descriptions.Item key={key} label={t(value.label)}>
                {value.defaultValue}
            </Descriptions.Item>
        })
    }

    const generateInput = (item) => {
        //switch case for input type
        switch (item.formType) {
            case 'select':
                return <Select
                    showSearch
                    style={{ width: '100%' }}
                    allowClear={true}
                    placeholder={item.label}

                    options={item.optionSelect}
                />


            case 'number':
                return <InputNumber
                    allowClear={true}
                    placeholder={item.label}


                />
            default:
                return <Input
                    allowClear={true}
                    placeholder={item.label}
                />
        }

    }

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
    };

    const [saveEnabled, setSaveEnabled] = useState(false)


    const handleSave = async (cancel = false, cancelMessage?: String) => {
        setSaving(true);
        setLoadingCancelation(true)
        setLoadingMsg("Processing order update...")
        try {
            // Validate form fields
            const values = await form.validateFields();


            // on rajoute les champs qui ne sont pas dans le form
            values.IdCdeLogisLiv = data.CdeLogisLiv?.[0]?.IdCdeLogisLiv
            values.IdCdeLogis = data.IdCdeLogis
            values.Annuler = cancel
            values.backUp = backUp
            values.wsId = ws.currentWebSocketId
            console.log(values);
            cancelMessage ? values.remarqueAnnulation = cancelMessage : values.remarqueAnnulation = null

            //on envoie les données au serveur, la réponse est traitée dans le useEffect ( trigger sur la base SQL pour stocker sur rabbitmq, l'info de validation )
            await createApiModificationOrder(values, toast)
            // resetAndLoad()

        } catch (error) {
            toast.error('Failed to update order, try later');
            setLoadingMsg("")
            setSaving(false);
            setLoadingCancelation(false)
            if (error.errorFields) {
                // Example: Setting specific error messages (You can customize this based on your error object)
                form.setFields(
                    error.errorFields.map(fieldError => ({
                        name: fieldError.name,
                        errors: [fieldError.message || "This field is required!"], // default message
                    }))
                );
            }
        }
    };


    const formatDate = (dateString) => {
        if (!dateString) {
            return 'date non renseignée';
        }

        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        return formattedDate === '01/01/1900' ? 'date non renseignée' : formattedDate;
    };


    function renderData() {
        return <div className='row'>
            <div className='col-4'>
                <Descriptions column={1} title={t('detailCommande').toUpperCase()}>
                    {/* <Descriptions.Item label={t('tarifExpedition')}>{data.CdeLogisColis?.[0]?.KLS_Contenant?.KLS_Expedition?.PrixVente ? <> {data.CdeLogisColis?.[0]?.KLS_Contenant?.KLS_Expedition?.PrixVente} € <sub>ht</sub> </>   : '-'} </Descriptions.Item> */}
                    <Descriptions.Item label={t('libelleCde')}>{data.Libell_Cde ? data.Libell_Cde : '-'}</Descriptions.Item>
                    <Descriptions.Item label={t('refCde')}>{data.RefCde ? data.RefCde : '-'}</Descriptions.Item>
                    <Descriptions.Item label={t('dateCde')}>{data.DateCdeClient ?
                        new Date(data.DateCdeClient).toLocaleDateString('fr-FR', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        }) : '-'}</Descriptions.Item>
                    <Descriptions.Item label={t('transporteur')}>{data.CdeLogisLiv?.[0]?.Transporteur}</Descriptions.Item>
                    <Descriptions.Item label={t('service')}>{data.CdeLogisLiv?.[0]?.Service}</Descriptions.Item>
                    <Descriptions.Item label={t('Ne pas expédier avant le ')}>{formatDate(data.CdeLogisLiv?.[0]?.NePasExp_dierAvantLe)}</Descriptions.Item>
                    <Descriptions.Item label={t("Type d'emballage")}>{data.CdeLogisColis?.[0]?.Emballage}</Descriptions.Item>
                </Descriptions>
            </div>

            {
                editMode ?
                    <Form
                        disabled={loadingCancelation}
                        className='container-fluid bg-transparent col-8'
                        form={form}
                        {...formItemLayout}
                        //check if field is valid
                        onFieldsChange={(changedFields, allFields) => {
                            setSaveEnabled(allFields.every(f => f.errors.length === 0))
                        }}
                        //check if form is valid
                        onValuesChange={(changedValues, allValues) => {
                            setSaveEnabled(form.getFieldsError().every(f => f.errors.length === 0))
                        }}
                        //finish form, validate all fields, and submit data
                        onFinish={(e) => {

                            handleSave().then(r => {
                                console.log(r);
                            })
                        }}
                    >
                        {dataCountry && data.CdeLogisLiv?.length > 0 ? renderDestinaire(editMode) : null}
                        {editMode ? (
                            <Space className="w-100 d-flex flex-row justify-content-center">
                                {/*//cancel button*/}
                                <Button disabled={loadingCancelation} onClick={handleCancel} >{t('cancelOrder')}</Button>
                                <Button disabled={loadingCancelation} onClick={handleClose} type="primary">{t('closeWithoutSaving')}</Button>
                                {/*//submit button*/}
                                <Button loading={saving} type="primary" htmlType="submit" disabled={!saveEnabled || loadingCancelation}>{t('save')}</Button>
                            </Space>

                        ) : null}

                    </Form> :
                    <Descriptions className='col-8' column={1} title={t('destination').toUpperCase()} >
                        {dataCountry && data.CdeLogisLiv?.length > 0 ? renderDestinaire(editMode) : null}
                    </Descriptions>
            }

        </div>
            ;
    }




    const handleClose = () => {

        if (editMode) {
            AntModal.confirm({
                style: { colorText: 'red' },
                centered: true,
                mask: false,
                content: "Are you sure you want to close the modal without saving changes?",
                okText: "Yes",
                cancelText: "No",
                onOk: () => {

                    //reset form
                    form.resetFields();

                    setLoadingCancelation(true)
                    setLoadingMsg("Unlocking order...")
                    if (ws && ws.socket) ws.socket.emit("unlockOrder", {
                        order: id,
                        client: auth.user.Login,
                    });
                },
                onCancel: () => {
                    // Do nothing
                }
            });
        } else {
            setShow(false)
            if (editMode) {
                setEditMode(false);
                console.log("setEditMode false handleClose");
            }

        }
    }

    const canBeUpdate = () => {
        return data.DemandeAnnulationParSystIntSnaUser === ""
            && data.PickingVerrouPosteUser === ''
            && data.Pr_paVerrouPosteUser === ''
            && data.CdeLogisSynth_seCdes.StatutCde === 'Reçue'
            && data.CptClient !== '232490'
            && auth.havePermissionTo('orderUpdateAuthorization')
    }



    const handleCancel = () => {
        let cancelReason = ''; // variable to store the cancellation reason

        const onInputChange = (e) => {
            cancelReason = e.target.value;
        }

        const inputField = (
            <div>
                <p>Please provide a reason for cancellation:</p>
                <Input type='text' onChange={onInputChange} />
            </div>
        );

        AntModal.confirm({
            style: { colorText: 'red' },
            centered: true,
            mask: false,
            title: 'Are you sure you want to cancel this order?',
            content: inputField,
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
                handleSave(true, cancelReason).then(r => {
                    console.log(r);
                })
            },
            onCancel: () => {
                // Do nothing
            }
        });
    };


    function disabledPastDates(current) {
        // Can not select days before today and today
        return current && current < Date.now();
    }


    const disabledDeliveryDates = (current) => {
        const desiredDepartureDate = formRef.current?.getFieldValue("desiredDepartureDate");
        return current && (current < moment().startOf("day") || (desiredDepartureDate && current <= desiredDepartureDate));
    };

    /**
     * Save the order
     *
     * @returns {JSX.Element}
     * @constructor
     */
    const CustomFooter = () => (
        <div>  <Button onClick={handleClose}>Close</Button>

        </div>
    );



    const [wiggleAnimation, setWiggle] = useSpring(() => ({
        transform: 'translate3d(0%, 0, 0)',
    }));

    const handleWiggle = () => {
        setWiggle({
            from: { transform: 'translate3d(-5%, 0, 0)' },
            to: { transform: 'translate3d(5%, 0, 0)' },
            config: config.wobbly,
            reset: true,
            reverse: true,
            loop: { times: 20 },
        });
    };


    return (
        data.length === 0 ? '' :
            <Modal
                mask={false}


                destroyOnClose={true}
                // maskClosable={false}

                footer={<CustomFooter />}
                title={<Space>
                    Order : {id} {data.Libell_Cde ? ' - ' + data.Libell_Cde : ''}
                    <TagOrder data={data.API_syntheseCde} />
                    <Space>
                        {data.CdeLogisColis?.[0]?.KLS_data?.RetourURLTracking ?
                            <Link type="danger" href={data.CdeLogisColis?.[0]?.KLS_data?.RetourURLTracking} target="_blank"
                                rel='noreferrer'> <EnvironmentOutlined /> Suivre le colis </Link>
                            : null}

                        {canBeUpdate() ? <>
                            <animated.div style={wiggleAnimation}>
                                <Space>

                                    {!editMode ?
                                        <>
                                            <Button disabled={loadingCancelation} onClick={handleCancel} >{t('cancelOrder')}</Button>
                                            <Button
                                                loading={loadingCancelation}
                                                disabled={!socket || !data} onClick={
                                                    () => {
                                                        handleEdit();
                                                    }
                                                } type="primary" > {t('updateOrder')}</Button>
                                        </>
                                        : null}

                                </Space>

                            </animated.div>
                        </>

                            : <Text italic>{t('noUpdatable')}</Text>
                        }



                        {data.API_Modification_order.length > 0 ?
                            <Text type="secondary">last update at {moment(data.API_Modification_order[data.API_Modification_order.length - 1].dateModification).format('YYYY-MM-DD HH:mm')}</Text>
                            : null
                        }

                    </Space>
                </Space>
                }
                centered
                onCancel={handleClose}
                open={show}
                width={1500}
            >

                {/*Loading div centered*/}
                <div style={
                    {
                        position: "absolute",
                        top: "50%", left: "50%",
                        //when loadingCancellation move div to the top
                        transform: loadingCancelation ? "translate(-50%, -100%)" : "translate(-50%, -50%)",
                        transition: "all 0.5s ease-in-out",
                        opacity: loadingCancelation ? 1 : 0,
                        zIndex: loadingCancelation ? 1000 : -1
                    }}
                    className="text-center">
                    <Space direction={"vertical"} >
                        <Image src={Spinner} width={100} preview={false} />
                        <Text>{loadingMsg}</Text>
                    </Space>

                </div>

                <div className={loadingCancelation ? "opacity-25" : ''}>
                    <div className='header-modal container-fluid'>
                        <div className='row'>
                            {renderData()}
                        </div>
                    </div>

                    <Suspense fallback={<div>loading...</div>}>
                        <Tabs
                            defaultActiveKey={'article'}
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        // onSelect={onChange}
                        >
                            <Tab eventKey="article" title={
                                <Badge
                                    count={data.CdeLogisArticle.length}
                                    offset={[10, -10]}
                                    overflowCount={999}
                                    color="geekblue">
                                    Article
                                </Badge>}>
                                {loading ?
                                    <Space direction={'vertical'} className="w-100">
                                        <Skeleton size={"small"} block={true} active
                                            avatar paragraph={{ rows: 0 }}
                                        />
                                    </Space>
                                    : <ArticleTable data={data.CdeLogisArticle} />}

                            </Tab>

                            {
                                // data.API_syntheseCde.idStatutCommande >= 3  ?
                                data.CdeLogisColis && data.CdeLogisColis[0] !== null ?
                                    <Tab eventKey="colis" title={
                                        <Badge
                                            count={data.CdeLogisColis.length}
                                            offset={[10, -10]}
                                            overflowCount={999}
                                            color="gray">
                                            Colis
                                        </Badge>}>
                                        <ColisTable data={data.CdeLogisColis || []} />
                                    </Tab>
                                    : null
                            }
                        </Tabs>
                    </Suspense>
                </div>

                <Popconfirm
                    title="Are you sure you want to close the modal without saving changes?"
                    onConfirm={() => handleClose()}
                    okText="Yes"
                    cancelText="No"
                ></Popconfirm>

            </Modal>
    );
}
export default memo(ModalOrder);