import {useContext} from "react";
import AuthProvider, {AuthContext} from "./AuthContext";
import RequireAuth from "./RequireAuth";
import SignIn from "./formPage/SignIn";
import RequestPassword from "./formPage/RequestPassword";
import NewPassword from "./formPage/NewPassword";

export {RequireAuth,AuthProvider,SignIn,RequestPassword,NewPassword};
export default function useAuth(){
    return useContext(AuthContext);
}