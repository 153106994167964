import React, { useState, useEffect, useContext } from 'react';
import { Button, Typography, Row, Col } from 'antd';
import useAuth from 'auth-sna';
import { ToastContext } from "toast-provider";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;
const BASE_URL = process.env.REACT_APP_API_V2;

export default function Profil() {
  const auth = useAuth();
  const [identifiant, setIdentifiant] = useState('');
  const [email, setEmail] = useState('');
  const toast = useContext(ToastContext);
  let navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const login = auth.login;

        if (!login) {
          throw new Error('Login non défini');
        }

        setIdentifiant(login);

        const response = await fetch(`${BASE_URL}/user/${login}`, {
          credentials: 'include',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données utilisateur');
        }

        const userData = await response.json();
        console.log("userData :", userData)
        setEmail(userData.Mail || 'Non défini');
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
        toast.error('Erreur lors de la récupération des données utilisateur', 20);
      }
    };

    fetchUserData();
  }, [auth.login, toast]);

  const handleResetPassword = async () => {
    try {
      const resetPasswordUrl = `${BASE_URL}/auth/reset-password`;
      console.log('URL de réinitialisation du mot de passe:', resetPasswordUrl);

      const response = await fetch(resetPasswordUrl, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: identifiant,
          destination: email,
        }),
      });
      console.log("response :", response)

      if (!response.ok) {
        toast.error('Erreur lors de la réinitialisation du mot de passe', 20);
      }

      const data = await response.json();
      console.log("Réponse de l'API :", data);
      toast.success(`Un mail a été envoyé à ${email} pour réinitialiser le mot de passe`, 20);
      navigate("/")
    } catch (error) {
      console.error('Erreur lors de la réinitialisation du mot de passe :', error);
      toast.error('Erreur lors de la réinitialisation du mot de passe', 20);
    }
  };

  return (
    <div style={{ padding: 20, textAlign: 'center', minHeight: '60vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Title level={1}>Profil utilisateur</Title>
      <Row justify="center">
        <Col span={12} xs={24} sm={18} md={12} lg={8}>
          <div style={{ marginBottom: 16 }}>
            <Text strong>Identifiant:</Text> <br />
            <Text type="secondary">{identifiant}</Text>
          </div>
          <div style={{ marginBottom: 16 }}>
            <Text strong>Email:</Text> <br />
            <Text type="secondary">{email}</Text>
          </div>
          <Button type="primary" onClick={handleResetPassword} style={{ width: '80%' }}>
            Réinitialiser le mot de passe
          </Button>
        </Col>
      </Row>
    </div>
  );
}
