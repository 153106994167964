import React, {memo, useEffect} from 'react';
import {ColumnsType} from "antd/es/table";
import {Space, Spin, Table} from "antd";
import {useFetch} from "hooks-sna";


const BASE_URL = process.env.REACT_APP_API_V2;
function StockMouvTable({id}: any) {

    const [loadingStock, errorStock, dataMouv, , , , fetchDataStock] = useFetch({url: `${BASE_URL}/article/stock-mouv?idProduit=${id}&NaturePrd=EC`})



    useEffect(() => {
        fetchDataStock()
    }, [id])
    const renderColor = (status: string) => {

        switch (status) {
            case 'Inventaire':
                return '#af5fbe';
            case 'Retour':
                return '#2C7ABEFF';
            case 'BLStock':
                return '#2C7ABEFF';
            case 'Destruction d\'éléments sur problème qualité':
                return '#d04a4a';
            case 'Sortie manuelle':
                return 'pink';
            case 'Distribution DtoC':
                return '#ffb42a';
            case 'Réception':
                return 'green';
            default:
                return 'pink';
        }
    }

    const columns: ColumnsType= [
        {
            title: 'Date',
            dataIndex: 'DateMvt',
            key: 'date',
            render: (date) =>
                //affichage de la date en 26/12/2020
                date !== '' ? new Date(date).toLocaleDateString('fr-FR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                }) : '-'
        },
        {
            title: 'Mouvement',
            key: 'mouvement',
            render: (_, record) => (
                <Space size="middle">
                    <span style={{color:renderColor(record.Mouvement)}} >{record.Mouvement}</span>
                </Space>
            ),

        },
        {
            title: 'Qte',
            dataIndex: 'Qté',
            key: 'qte',
            render: (qte) => <>{qte || '-'}</>,
        },
        {
            title: 'Id Mouvement',
            dataIndex: 'IdMvt',
            key: 'mouvement',
            render: (mouv) => <>{mouv || '-'}</>,
        },

    ];

    return (loadingStock ? <Spin /> : <Table pagination={{pageSize:4}} size={'small'} columns={columns} dataSource={dataMouv} />)
}

export default memo(StockMouvTable);