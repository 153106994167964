import React, { memo, useContext, useEffect, useState } from "react";
import useAuth from "auth-sna";
import { useNavigate, Link } from "react-router-dom";
import {
    PoweroffOutlined,
    UserOutlined,
    SettingOutlined, MailOutlined, AppstoreOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, Menu, Switch, Tooltip } from "antd";
import type { MenuProps } from 'antd';
import { Space, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Affix, Button } from 'antd';

import useFetch from "hooks-sna/src/useFetch";
import { CustomThemeContext } from "theme-provider";
import { TranslateContext } from "translate-provider";

const { Text } = Typography;
const showNotification = process.env.REACT_APP_SHOW_NOTIFICATION === 'true';
console.log("process.env.REACT_APP_SHOW_NOTIFICATION  :", process.env.REACT_APP_SHOW_NOTIFICATION )
console.log("process.env.REACT_APP_VERSION  :", process.env.REACT_APP_VERSION )

function UserNav() {
    let auth = useAuth();
    let navigate = useNavigate();
    const [current, setCurrent] = useState('mail');
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        const currentUrl = window.location.href;
        const isUrlEnabled = currentUrl.includes('http://localhost:3001') || currentUrl.includes('https://portail.sna-gz.com/');
        setIsDisabled(!isUrlEnabled);
    }, []);


    const { toggleTheme, getTheme } = useContext(CustomThemeContext);
    const { t, getLanguageMenu } = useContext(TranslateContext);

    const theme = getTheme();

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Link to="profil">
                    <UserOutlined /> {t('profile')}
                </Link>
            ),
            disabled: !showNotification || isDisabled,
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                    <SettingOutlined /> {t('settings')}
                </a>
            ),
            disabled: true,
        },
        {
            key: '3',
            label: (
                <a onClick={() => {
                    auth.signOut(() => { });
                }} href="#">
                    <PoweroffOutlined /> {t('logout')}
                </a>
            ),
        },
        {
            key: '4',
            label: (
                <>
                    <Text>{t('darkmode')} :</Text>
                    <Switch
                        label={t('darkmode')}
                        checked={theme === 'dark'}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onClick={() => {
                            toggleTheme();
                        }}
                    />
                </>
            ),
        },
        {
            key: '5',
            label: (
                <>
                    {getLanguageMenu()}
                </>
            ),
        }

    ];


    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
    };


    return (

        <div className="d-flex flex-row  align-items-center">
            <Text>{t('welcome')} {auth.login} </Text>
            <Dropdown menu={{ items }} placement="bottom" arrow={{ pointAtCenter: true }}>
                <Avatar icon={<UserOutlined />} />
                {/*<Tooltip title="search">*/}
                {/*    <Button shape="circle" icon={<SearchOutlined />} />*/}
                {/*</Tooltip>*/}
            </Dropdown>
        </div>




    );
}

export default memo(UserNav);