import React, { memo, useContext, useEffect, useState } from 'react';
import { ColumnsType } from "antd/es/table";
import { Badge, Popover, Table, Tooltip, Typography } from "antd";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { GiCardboardBoxClosed } from "react-icons/gi";
import { FaShippingFast } from "react-icons/fa";
import { BsFillCheckCircleFill } from "react-icons/bs";
import useAuth from "auth-sna";
import { useFetch } from "hooks-sna";
import { ImageCustom } from "sna-components";
import { BellFilled, BellOutlined, SearchOutlined } from "@ant-design/icons";
import { TranslateContext } from "translate-provider";

const { Text, Link } = Typography;


const BASE_URL = process.env.REACT_APP_API_V2;
function StockTable({ data, filter, updateFilter, openModal, openModalLimit, onPageChange, pageSize, total, loading, page, activeTab }) {

    const user = useAuth();
    const [isAdmin, setIsAdmin] = useState(['ROLE_ADMIN', 'ROLE_STAFF'].includes(user.role));
    const { t } = useContext(TranslateContext)

    let param = {
        url: `${BASE_URL}/stock/type-produit${isAdmin ? '-admin' : ''}`,
        filter: filter,
    }
    //get all distinct type produit
    const [loadingFilter, error, dataFilter, hasMore, metadata, progress, fetchData] = useFetch(param)

    const [filterType, setFilterType] = useState([]);

    useEffect(() => {
        // Fetch the data
        if (dataFilter.length === 0) fetchData();
    }, []);

    useEffect(() => {
        // Check if dataFilter has data
        if (dataFilter.length > 0) {
            const filterRecord = dataFilter.map((item: any) => {
                return {
                    text: item.TypeProduit,
                    value: item.TypeProduit,
                };
            });
            setFilterType(filterRecord);
        }
    }, [dataFilter]);

    useEffect(() => {
        fetchData();
    }, [filter.client]);


    const renderNumber = (nb: number) => {
        if (nb <= 0) return (<Text type='danger'>{nb || 0}</Text>)
        if (nb === 0) return (<Text type='secondary'>{nb || 0}</Text>)
        if (nb > 0) return (<Text type='success'>{nb || 0}</Text>)
    }


    const columns: ColumnsType = [{

        dataIndex: 'id_Produit',
        key: 'Détail commande',
        width: 40,
        render: (id, record) => {
            if (record.Obsol_te) {
                return null; // Ne rien rendre si record.Obsol_te est vrai
            }
            return (
                <a onClick={(event) => {
                    event.preventDefault();
                    openModal(id)
                }}>
                    <SearchOutlined />
                </a>
            );
        }
    },
    {
        // dataIndex: ['API_alerteMail'],
        key: 'Limite de stock',
        width: 40,

        render: (data) => (
            <Tooltip title={data.API_alerteMail.length > 0 ? `Limite du seuil ${data.API_alerteMail[0].seuil}` : 'Aucune limite de seuil'}>
                <button className='btn-ghost text-white' onClick={
                    (event) => {
                        event.preventDefault();
                        openModalLimit(data.API_alerteMail[0], data.id_Produit)
                    }
                } > {data.API_alerteMail.length > 0 ? <BellFilled size={30} /> : <BellOutlined />}
                </button>
            </Tooltip>
        ),
    },
    {
        title: t('IdProduit'),
        dataIndex: 'id_Produit',
        sorter: true,
        width: 90,
        key: 'id_Produit',
        render: (text) => <Text type="secondary"><a onClick={
            (event) => {
                event.preventDefault();
                openModal(text)
            }
        }>{text}</a></Text>,
    },
    //add second column with image
    // columns.splice(1, 0, {
    {
        title: t('Image'),
        dataIndex: 'LienURLImage',
        key: 'image',
        width: 80,
        render: (text) => {
            if (text !== '' && text !== null) {
                return <ImageCustom src={text} />
            } else {
                return null
            }
        },
    },
    // })
    {
        title: t('RefProduit'),
        dataIndex: 'RefProduit',
        width: 200,
        sorter: true,
        render: (text) => <Text keyboard>{text || '-'}</Text>,
        key: 'RefProduit',

    },
    {
        title: t('Titre'),
        dataIndex: 'Titre',
        key: 'Titre',
        width: 150,
        sorter: true,
        render: (text) => <Text type="secondary">{text || '-'}</Text>,
    },
    {
        title: t('TypeProduit'),
        dataIndex: 'TypeProduit',
        key: 'TypeProduit',
        width: 150,
        filterMode: 'tree',
        filters: filterType,
        render: (text) => <Text type='secondary'>{text}</Text>,

    },{
            title:"date com.",
            dataIndex:['DateCommercialisation'],
            key:'DateCommercialisation',
            sorter: true,
            width: 95,
            render: (date) => <> {
                date !=='1900-01-01T00:00:00.000Z' ? new Date(date).toLocaleDateString('fr-FR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                }) : '-'
            }</>
        },
    {
        title: t('enStock'),
        dataIndex: ['TrigStockPhys'],
        key: 'TrigStockPhys',
        sorter: true,
        width: 80,
        render: (text) => renderNumber(text),
    },
    {
        title: t('Avenir'),
        dataIndex: ['aVenir'],
        key: 'TrigTotalAppro',
        sorter: true,
        width: 80,
        render: (text) => renderNumber(text),
    },
    {
        title: t('QteBloqué'),
        dataIndex: ['qteBloque'],
        key: 'QtéBloquée',
        // sorter: true,
        width: 80,
        render: (text) => renderNumber(text),
    },
    {
        title: t('BesoinProd'),
        dataIndex: ['TrigTotalACdt'],
        key: 'TrigTotalACdt',
        sorter: true,
        width: 80,
        render: (text) => renderNumber(text),
    },
    {
        title: t('BesoinDtoC'),
        dataIndex: ['Qt_R_serv_eDtoC'],
        key: 'Qt_R_serv_eDtoC',
        // sorter: true,
        width: 80,
        render: (text) => renderNumber(text),
    },
    {
        title: t('Reste en stock'),
        key: 'totalStock',
        // sorter: true,
        width: 80,
        render: (d) => {
            const total = d.TrigStockPhys + d.aVenir - (d.qteBloque + d.BesoinProd + d.BesoinDtoC);
            return renderNumber(total)
        },
    },
    {
        title: t('nbDeCde'),
        dataIndex: ['nbCommandeSurArticle'],
        key: 'nbCommandeSurArticle',
        // sorter: true,
        width: 80,
        render: (text) => renderNumber(text),
    },

    {

        dataIndex: 'id_Produit',
        key: 'Détail commande',
        width: 40,
        render: (id) => <a onClick={
            (event) => {
                event.preventDefault();
                openModal(id)
            }
        }><SearchOutlined /> </a>,
    },
    {

        // dataIndex: ['API_alerteMail'],
        key: 'Limite de stock',
        width: 40,
    }
    ];



    //Si l'utilisateur est un administrateur on affiche le nom du client
    if (['ROLE_ADMIN', 'ROLE_STAFF'].includes(user?.role)) {
        columns.splice(2, 0, {
            title: 'Client',
            key: 'NomClient',
            width: 150,
            //reduce the font
            className: 'text-sm',
            render: (text) => <Text style={{ fontSize: '0.8em' }} type="secondary" >{
                text?.clientName + " ( " + text?.CptClient + " )"
            }</Text>,
        })

        // //add second column with image
        // columns.splice(1, 0, {
        //     title: 'Image',
        //     dataIndex: 'LienURLImage',
        //     key: 'image',
        //     render: (text) => {
        //         if(text !== '' && text !== null){
        //             return <ImageCustom src={text} width={60} />
        //         }else{
        //             return null
        //         }
        //     },
        // })
    }


    //set the sortfield and sortorder from filter and apply it on the table
    const sortField = filter.sortField || 'id_Produit';
    const sortOrder = filter.sortOrder;


    //on ajoute le sortorder et le sortfield à la colonne correspondante
    columns.forEach((column) => {
        if (column.key === sortField) {
            column.sortOrder = sortOrder;
        }
    });




    const handleTableChange = (pagination,
        filters: Record<string, FilterValue>,
        sorter: SorterResult) => {

        const blockedSorter = ['action'];



        if (filters.TypeProduit) {
            updateFilter({
                TypeProduit: filters.TypeProduit,
            });
        } else {
            updateFilter({
                TypeProduit: null,
            });
        }

        if (!blockedSorter.includes(sorter.columnKey)) {

            updateFilter({
                sortField: sorter.order ? sorter.field : null,
                sortOrder: sorter.order ? sorter.order : null,
            });

        }
        onPageChange(pagination.current - 1, pagination.pageSize);
    };


    return (<Table
        pagination={{
            current: page + 1,
            pageSize: pageSize,
            total: total
        }}
        size={'small'}
        scroll={{ y: 600 }}
        columns={columns}
        loading={loading}
        dataSource={data}
        onChange={handleTableChange}
        rowClassName={(record, index) => record.Obsol_te ? 'Obsol_te-true' : 'Obsol_te-false'}
    />)
}

/**
 * popOverColis : affiche le nombre de colis et les liens de tracking lorsque l'on passe la souris sur l'icone
 * @param colis
 * @return {JSX.Element}
 */
const popOverColis = (colis: [string]) => {
    const content = <div>
        {colis.length > 0 ?
            colis.length > 0 && colis.map((colis, index) => {
                return <div key={index}>Colis Id : {colis.IdContenantTMS} -
                    {/*Affichage du statut de livraison*/}
                    {colis.KLS_Contenant.RetourDateLivr_ !== "1900-01-01T00:00:00.000Z" &&
                        <Text type='success'> Delivered <BsFillCheckCircleFill color={'green'} size={20} /> - </Text>}
                    {/*Affichage du lien de tracking*/}
                    <Link underline type={"warning"} href={colis.KLS_Contenant.RetourURLTracking} target='_blank' rel='noopener noreferrer'>
                        Track shipping <FaShippingFast size={20} />
                    </Link>
                </div>
            }) :
            <p>No tracking URL available at the moment</p>
        }
    </div>


    return <Popover content={content} trigger='hover'>
        <Badge color={colis.length > 0 ? 'green' : 'grey'} count={colis.length} showZero>
            <GiCardboardBoxClosed size={20} />
        </Badge>
    </Popover>
}

export default memo(StockTable);