import { Route, Routes } from "react-router-dom";
import ToastProvider from "toast-provider";
import NoMatch from "./NoMatch";
import Home from "./home/Home";
import DashBoard from "./dashBoard/DashBoard";
import React, { Suspense } from "react";
import { AuthProvider, RequireAuth, SignIn } from "auth-sna";
import { CustomThemeProvider } from "theme-provider";
import { Spin } from "antd";
import TranslateProvider from "translate-provider";
import { FilterProvider } from "hooks-sna";
import ResetPassword from "profil-utilisateur/src/ResetPassword"
import AskResetPassword from "profil-utilisateur/src/AskResetPassword"

function App() {
    return (

        <div className="App d-flex flex-column justify-content-between h-100">
            <CustomThemeProvider>
                <ToastProvider>
                    <AuthProvider>
                        <FilterProvider>
                            <TranslateProvider>
                                <Routes>
                                    <Route path="*" element={<NoMatch />} />
                                    <Route path="/" element={<Home />} >
                                        <Route path='new-password/:token' element={<ResetPassword />} />
                                        <Route path='/request-new-password' element={<AskResetPassword />} />
                                        <Route index path="SignIn" element={<SignIn />} />
                                    </Route>
                                    <Route path="dashboard/*" element={
                                        <RequireAuth>
                                            <Suspense fallback={<Spin />}>
                                                {/* <WebSocketProvider> */}
                                                <DashBoard />
                                                {/* </WebSocketProvider> */}
                                            </Suspense>
                                        </RequireAuth>
                                    }>
                                    </Route>
                                </Routes>
                            </TranslateProvider>
                        </FilterProvider>
                    </AuthProvider>
                </ToastProvider>
            </CustomThemeProvider>
        </div>
    );
}
//maj 1.3.2

export default App;
