export const CredentialFetcher = (...args) => fetch(...args, { method: 'GET', credentials: 'include' }).then(res => res.json())

//same but with POST method that can take a body
export const CredentialPoster = async (url, body, ...args) => {
    const result = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        ...args
    })
    return await result.json()
}