import {useNavigate, useParams} from 'react-router-dom';
import {Button, Form, Input, Typography} from "antd";
import React, {useContext} from "react";
import {LockOutlined} from "@ant-design/icons";
import {ToastContext} from "toast-provider";

const { Text } = Typography;
const BASE_URL = process.env.REACT_APP_API_V2;
const REACT_APP_API_V2 = process.env.REACT_APP_REACT_APP_API_V2;


export default function NewPassword(){
    let { token } = useParams();
    const [form] = Form.useForm();
    const toast = useContext(ToastContext);
    let navigate = useNavigate();


    const [loading, setLoading] = React.useState(false);

    const onFinish = async (values: any) => {
        setLoading(true)
        try {
            //validation des deux mots de passe
            if (values.password !== values.confirmPassword) {
                setLoading(false)
                toast.error('The two passwords that you entered must be the same')
            }

            let body = {
                password: values.password,
                token: token
            }

            let response = await fetch(`https://api.snagz.fr/api2/auth/new-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })

            if(response.ok){
                setLoading(false)
                toast.success('Your password has been changed successfully')
                //redirection vers la page de connexion
                navigate('/SignIn')
            }else {
                let data = await response.json();
                toast.error(data.message)
                setLoading(false)
            }
        } catch (errInfo) {
            toast.error('An error occurred')
            setLoading(false)
        }
    };

    // The rules for the password confirmation field
    const passwordRules = [
        { required: true, message: 'Please confirm your password' },
        // Use a validator function to compare the passwords
        ({ getFieldValue }) => ({
            validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                }
                return Promise.reject('The two passwords that you entered do not match!');
            },
        }),
    ];




    return (
                    <Form
                        name="normal_login"
                        className="login-form"
                        onFinish={onFinish}
                        rootClassName={"login-form "}
                    >

                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your new Password' }]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="New Password"
                            />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            rules={passwordRules}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Confirm Password"
                                iconRender={() => null}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                                reset password
                            </Button>
                        </Form.Item>
                    </Form>
    );
}