import React, {memo, useContext, useState} from 'react';
import {ColumnsType} from "antd/es/table";
import {Table} from "antd";
import useFetchStocks from "hooks-sna/src/useFetchStock";
import {Stock} from "sna-components";
import {TranslateContext} from "translate-provider";

const BASE_URL = process.env.REACT_APP_API_V2;
function ArticleTable({data}: any) {




    const [stocks] = useFetchStocks(data, BASE_URL);
    const {t} = useContext(TranslateContext)

    const columns: ColumnsType= [
        {
            title: 'Type ',
            dataIndex: ['EltClient', 'TypeProduit'],
            key: 'typeProduit',
            render: (typeProduit) => {
                //remove the 'Client' and 'client' from the string
               return typeProduit.replace(/client/gi, '').replace(/Client/gi, '')
            },
        },
        {
            title: t('idCdeSNA'),
            dataIndex: 'IdCdeLogisArticle',
            key: 'IdCdeLogisArticle',
            render: (text) => <>{text || '-'}</>,
        },
        {
            title: t('idProduit'),
            key: 'IdProduit',
            render: (text) => <>{text.NaturePrd+'-'+text.IdProduit || '-'}</>,

        },
        {
            title: t('refProduit'),
            dataIndex: 'RefProduit',
            key: 'RefProduit',
            render: (text) => <>{text || '-'}</>,
        },
        {
            title: t('titreProduit'),
            dataIndex: 'EltClient',
            key: 'titre',
            render: (text) => <>{text.Titre || '-'}</>,
        },
        {
            title: 'Qte',
            dataIndex: 'Qt_',
            key: 'quantité',
            render: (qte) => <>{qte || '-'}</>,
        },
    ];



    const [expandedRowKeys, setExpandedRowKeys] = useState([]);


    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = [...expandedRowKeys];
        const rowIndex = newExpandedRowKeys.indexOf(record.IdProduit);

        if (expanded) {
            if (rowIndex === -1) {
                newExpandedRowKeys.push(record.IdProduit);
            }
        } else {
            if (rowIndex !== -1) {
                newExpandedRowKeys.splice(rowIndex, 1);
            }
        }
        setExpandedRowKeys(newExpandedRowKeys);
    };

    const dataDisplay ={
        // FabSNAPrixVente: {
        //     suffix: '€',
        //     NameToDisplay: 'Prix de vente'
        // },
        Prix:{
            suffix: '€',
            NameToDisplay: 'Prix de vente'
        },
        CodeEAN13: '',
        CodeDouane:'',
        Longueur: 'cm',
        Largeur: 'cm',
        Hauteur: 'cm',
        Poids: 'g'}

    return (<Table
                     rowKey={(record) => record.IdProduit}
                     pagination={{ pageSize: 17 }}
                     expandable={{expandedRowRender: (record) => {
                         //add record.Prix to EltClient
                           record.EltClient.Prix = record.Prix;
                           record.EltClient.id_Produit = record.IdProduit;
                         return <Stock data={record.EltClient} dataDisplay={dataDisplay} />
                         },
                         expandedRowKeys: expandedRowKeys,
                         onExpand: handleExpand,
                         rowExpandable: record => record.stock.length !== 0 && Array.isArray(stocks),
                   }}

                     size={'small'}
                     columns={columns}
                     dataSource={Array.isArray(stocks) ? stocks : []} />)
}

export default memo(ArticleTable);