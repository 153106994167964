// Modèle de base avec les colonnes communes
const baseColumnToDataMapping = {
    "CptClient": "CptClient",
    "RefProduit": "sku",
    "TypeProduit": "productType",
    "Titre": "title",
    "Artiste": "artist",
    "DateCommercialisation": "marketingDate",
    "Poids": "weight",
    "Couleur": "color",
    "Taille": "size",
    "Genre": "gender",
    "Prix": "unitPrice",
    "CodeDouane": "custodyCode",
    "LibellePrCN23": "CN23Label",
    "PrixPrestationSNA": "SNABilling"
};

const baseColumnOrder = [
    "CptClient",
    "RefProduit",
    "TypeProduit",
    "Titre",
    "Artiste",
    "DateCommercialisation",
    "Poids",
    "Couleur",
    "Taille",
    "Genre",
    "Prix",
    "CodeDouane",
    "LibellePrCN23",
    "PrixPrestationSNA"
];

// Configuration pour le modèle Textile Print
export const configCsv = {
    textilePrint: {
        columnToDataMapping: {
            ...baseColumnToDataMapping,
            "RefSupportVierge": "blankMediaRef",
            "StockSupportVierge": "blankMediaStock",
            "OperationVisuelA": "visualOperationA",
            "RefVisuelA": "visualReferenceA",
            "ImprFonceeVisuelA": "visualDarkPrintA",
            "LienUrlPreviewVisuelA": "visualPreviewUrlA",
            "OperationVisuelB": "visualOperationB",
            "RefVisuelB": "visualReferenceB",
            "ImprFonceeVisuelB": "visualDarkPrintB",
            "LienUrlPreviewVisuelB": "visualPreviewUrlB",
            "OperationVisuelC": "visualOperationC",
            "RefVisuelC": "visualReferenceC",
            "ImprFonceeVisuelC": "visualDarkPrintC",
            "LienUrlPreviewVisuelC": "visualPreviewUrlC",
            "OperationVisuelD": "visualOperationD",
            "RefVisuelD": "visualReferenceD",
            "ImprFonceeVisuelD": "visualDarkPrintD",
            "LienUrlPreviewVisuelD": "visualPreviewUrlD"
        },
        columnOrder: [
            ...baseColumnOrder,
            "RefSupportVierge",
            "StockSupportVierge",
            "OperationVisuelA",
            "RefVisuelA",
            "ImprFonceeVisuelA",
            "LienUrlPreviewVisuelA",
            "OperationVisuelB",
            "RefVisuelB",
            "ImprFonceeVisuelB",
            "LienUrlPreviewVisuelB",
            "OperationVisuelC",
            "RefVisuelC",
            "ImprFonceeVisuelC",
            "LienUrlPreviewVisuelC",
            "OperationVisuelD",
            "RefVisuelD",
            "ImprFonceeVisuelD",
            "LienUrlPreviewVisuelD"
        ]
    },

    // Configuration pour le modèle BtoC Product
    btoCProduct: {
        columnToDataMapping: {
            ...baseColumnToDataMapping,
            "Remarque": "Remarque",
            "LienUrlVisuelArticle": "imageUrl",
            "CodeEAN13": "barCode",
            "OCC": "occ",
            "OccCodeEAN13Specifique": "occBarCode",
            "Boitage": "numberOfItemsPerBox",
            "NbrPage": "numberOfPages",
            "DtoC": "DtoC",
            "PrdFini": "finishedProduct",
            "DescriptifPrdFini": "finishedProductComposition",
            "Hauteur": "height",
            "Largeur": "width",
            "Longueur": "length",
            "NbrCD": "numberOfCD",
            "NbrDVD": "numberOfDVD",
            "NbrBD": "numberOfBD",
            "NbrVinyle": "numberOfVinyls"
        },
        columnOrder: [
            ...baseColumnOrder,
            "Remarque",
            "LienUrlVisuelArticle",
            "CodeEAN13",
            "OCC",
            "OccCodeEAN13Specifique",
            "Boitage",
            "NbrPage",
            "DtoC",
            "PrdFini",
            "DescriptifPrdFini",
            "Hauteur",
            "Largeur",
            "Longueur",
            "NbrCD",
            "NbrDVD",
            "NbrBD",
            "NbrVinyle"
        ]
    },

     // Configuration pour le modèle Full 
    fullModel: {
        columnToDataMapping: {
            ...baseColumnToDataMapping,
            "RefSupportVierge": "blankMediaRef",
            "StockSupportVierge": "blankMediaStock",
            "OperationVisuelA": "visualOperationA",
            "RefVisuelA": "visualReferenceA",
            "ImprFonceeVisuelA": "visualDarkPrintA",
            "LienUrlPreviewVisuelA": "visualPreviewUrlA",
            "OperationVisuelB": "visualOperationB",
            "RefVisuelB": "visualReferenceB",
            "ImprFonceeVisuelB": "visualDarkPrintB",
            "LienUrlPreviewVisuelB": "visualPreviewUrlB",
            "OperationVisuelC": "visualOperationC",
            "RefVisuelC": "visualReferenceC",
            "ImprFonceeVisuelC": "visualDarkPrintC",
            "LienUrlPreviewVisuelC": "visualPreviewUrlC",
            "OperationVisuelD": "visualOperationD",
            "RefVisuelD": "visualReferenceD",
            "ImprFonceeVisuelD": "visualDarkPrintD",
            "LienUrlPreviewVisuelD": "visualPreviewUrlD",
            "Remarque": "Remarque",
            "LienUrlVisuelArticle": "imageUrl",
            "CodeEAN13": "barCode",
            "OCC": "occ",
            "OccCodeEAN13Specifique": "occBarCode",
            "Boitage": "numberOfItemsPerBox",
            "NbrPage": "numberOfPages",
            "DtoC": "DtoC",
            "PrdFini": "finishedProduct",
            "DescriptifPrdFini": "finishedProductComposition",
            "Hauteur": "height",
            "Largeur": "width",
            "Longueur": "length",
            "NbrCD": "numberOfCD",
            "NbrDVD": "numberOfDVD",
            "NbrBD": "numberOfBD",
            "NbrVinyle": "numberOfVinyls",
            "Prix": "unitPrice"
        },
        columnOrder: [
            ...baseColumnOrder,
            "RefSupportVierge",
            "StockSupportVierge",
            "OperationVisuelA",
            "RefVisuelA",
            "ImprFonceeVisuelA",
            "LienUrlPreviewVisuelA",
            "OperationVisuelB",
            "RefVisuelB",
            "ImprFonceeVisuelB",
            "LienUrlPreviewVisuelB",
            "OperationVisuelC",
            "RefVisuelC",
            "ImprFonceeVisuelC",
            "LienUrlPreviewVisuelC",
            "OperationVisuelD",
            "RefVisuelD",
            "ImprFonceeVisuelD",
            "LienUrlPreviewVisuelD",
            "Remarque",
            "LienUrlVisuelArticle",
            "CodeEAN13",
            "OCC",
            "OccCodeEAN13Specifique",
            "Boitage",
            "NbrPage",
            "DtoC",
            "PrdFini",
            "DescriptifPrdFini",
            "Hauteur",
            "Largeur",
            "Longueur",
            "NbrCD",
            "NbrDVD",
            "NbrBD",
            "NbrVinyle"
        ]
    }
};
