import React, {Fragment, useEffect, useState} from 'react'
import {useFetch} from "hooks-sna";
import {Card, Col, Row, Statistic} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";

const BASE_URL = process.env.REACT_APP_API_V2;


const zeroColor = { color: '#737373' }
const negativeColor = { color: '#cf1322' }
const greenColor = { color: '#3f8600' }

export default function BilanStock({idProduit}) {

    const [stockData, setStockData] = useState(null)
    const [loading,
        error,
        dataStock,
        hasMore,
        metadata,
        progress,
        fetchData] = useFetch({url: `${BASE_URL}/article/bilan-stock?idProduit=${idProduit}`});

    useEffect(() => {
        if (dataStock.length === 0) {
           fetchData();
        }
    }, [])


    useEffect(() => {


        if (dataStock[0] !== undefined) {

            setStockData((prev) => {
                return {
                    ...dataStock[0],
                    stockAVenir: dataStock[0].TrigTotalAppro + dataStock[0].TrigStockPhys,
                    totalBesoin: dataStock[0].BesoinProd + dataStock[0].BesoinDtoC,
                    totalStock:(dataStock[0].aVenir + dataStock[0].TrigStockPhys) - (dataStock[0].BesoinProd + dataStock[0].BesoinDtoC+dataStock[0].qteBloque)
                }
            })

        }
    }, [dataStock])



    useFetch(()=>{

    },[stockData])


    if(loading || dataStock.length === 0){
        return <div>Chargement du stock...</div>
    }

    return (
        <Fragment>
            <h5>
            Stock {stockData?.Etat}
            <QuestionCircleOutlined style={{ fontSize: '30px', color: 'white', marginLeft:'5px' }} />

        </h5>
     <Row gutter={5} align={"middle"}>
        <Col span={3}>
            <Card bordered={false}>
                <Statistic
                    title="En stock"
                    groupSeparator={" "}
                    style={{textAlign: 'center'}}
                    value={stockData?.TrigStockPhys}
                    valueStyle={stockData?.TrigStockPhys === 0 ? zeroColor : greenColor}
                />
            </Card>
        </Col>
       +
         <Col span={3}>
            <Card bordered={false}>
                <Statistic
                    title="A venir"
                    groupSeparator={" "}
                    style={{textAlign: 'center'}}
                    value={stockData?.TrigTotalAppro}
                    valueStyle={stockData?.TrigTotalAppro === 0 ? zeroColor : greenColor}
                />
            </Card>
        </Col>
  =
        <Col span={4}>
            <Card bordered={false}>
                <Statistic
                    title="Stock + à Venir"
                    groupSeparator={" "}
                    style={{textAlign: 'center'}}
                    value={stockData?.TrigStockPhys + stockData?.aVenir}
                    valueStyle={stockData === 0 ? zeroColor : greenColor}

                />
            </Card>
        </Col>
         -
         <Col span={3}>
             <Card bordered={false}>
                 <Statistic
                     title="Qte bloquée"
                     groupSeparator={" "}
                     style={{textAlign: 'center'}}
                     value={stockData?.qteBloque}
                     valueStyle={{ color: '#2c7abe' }}

                 />
             </Card>
         </Col>

        <Col span={3}>
            <Card bordered={false}>
                <Statistic
                    title="Besoin Prod"
                    groupSeparator={" "}
                    style={{textAlign: 'center'}}
                    value={stockData?.BesoinProd}
                    valueStyle={{ color: '#2c7abe' }}
                />
            </Card>
        </Col>
         <Col span={3}>
             <Card bordered={false}>
                 <Statistic
                     title="Besoin DtoC"
                     groupSeparator={" "}
                     style={{textAlign: 'center'}}
                     value={stockData?.BesoinDtoC}
                     valueStyle={{ color: '#2c7abe' }}
                 />
             </Card>
         </Col>
=
        <Col span={3}>
            <Card bordered={false}>
                <Statistic
                    title="Stock Disponible"
                    groupSeparator={" "}
                    style={{textAlign: 'center'}}
                    value={stockData?.totalStock}
                    valueStyle={stockData?.totalStock === 0 ? zeroColor : stockData?.totalStock < 0 ? negativeColor : greenColor}
                />
            </Card>
        </Col>
    </Row>

        </Fragment>
    )
}