import React, { useContext, useState, useEffect } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import useAuth from "../useAuth";
import { TranslateContext } from "translate-provider";


function SignInForm({ onFinish, t, errorMessage, loading }) {
    let validateStatus = "";
    let helpMessage = "";
    if (errorMessage) {
        validateStatus = "error";
        helpMessage = errorMessage;
    }

    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        const currentUrl = window.location.href;
        const isUrlEnabled = currentUrl.includes('http://localhost:3001') || currentUrl.includes('https://portail.sna-gz.com/');
        setIsDisabled(!isUrlEnabled);
    }, []);



    return (
        <Form
            name="normal_login"
            className="login-form"
            onFinish={onFinish}
            rootClassName={"login-form "}
        >
            <Form.Item
                name="username"
                rules={[{ required: true, message: t('loginRules') }]}
                validateStatus={validateStatus}
                help={helpMessage}
                status={errorMessage ? t('error') : ""}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t('account')} />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: t('passwordRules') }]}
                validateStatus={validateStatus}
                status={errorMessage ? t('error') : ""}

            >
                <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder={t('password')}

                />
            </Form.Item>
            <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>{t("rememberMe")}</Checkbox>
                </Form.Item>
                {!isDisabled &&
                    <Link className="login-form-forgot" to={"/request-new-password"}>
                        {t("forgotPassword")}
                    </Link>
                }
            </Form.Item>

            <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                    {t("login")}
                </Button>
            </Form.Item>


        </Form>
    );
}



function SignIn({ user, from, error, mode }) {
    let navigate = useNavigate();
    let auth = useAuth();
    const { t, getLanguageMenu } = useContext(TranslateContext);



    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const onFinish = async (values) => {
        setLoading(true)
        const response = await auth.signIn(
            values.username,
            values.password,
            () => {
                navigate(from || "/dashboard");
            },
            setLoading,
            values.remember,
            setErrorMessage,
            mode);



    };




    let validateStatus = "";
    let helpMessage = "";
    if (errorMessage) {
        validateStatus = "error";
        helpMessage = errorMessage;
    }

    const MemoizedSignInForm = React.memo(SignInForm);

    return (
        <>
            <MemoizedSignInForm onFinish={onFinish} t={t} errorMessage={errorMessage} loading={loading} />
            {getLanguageMenu('white')}
        </>
    );
}

export default React.memo(SignIn);