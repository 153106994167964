import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Table, Input, Select } from 'antd';
import { configCsv } from './configCsv';

export default function CsvDataViewer({ data, onDataChange, model }) {
    const [columns, setColumns] = useState([]);

    // Fonction pour obtenir le dataIndex
    const getDataIndex = useCallback((key) => {
        const { columnToDataMapping } = configCsv[model] || {};
        return columnToDataMapping[key] || key;
    }, [model]);

    // Fonctions pour formater la date
    const formatDateForDisplay = (value) => {
        if (!value) return '01-01-1900';
        const [year, month, day] = value.split("-");
        return `${day}-${month}-${year}`;
    };

    const formatDateForDatabase = (value) => {
        if (!value) return '1900-01-01';
        const [day, month, year] = value.split("-");
        return `${year}-${month}-${day}`;
    };

    // Fonction de rendu des cellules
    const renderCell = useCallback((key, record) => {
        const value = record[getDataIndex(key)];

        if (key === 'DateCommercialisation') {
            return (
                <TextInputCell
                    value={formatDateForDisplay(value)}
                    onChange={(newValue) => handleCellChange(formatDateForDatabase(newValue), record, key)}
                />
            );
        }

        return (
            <EditableCell
                value={value}
                onChange={(newValue) => handleCellChange(newValue, record, key)}
            />
        );
    }, [getDataIndex]);


    const columnsMemo = useMemo(() => {
        if (!data?.length || !model) return [];

        const { columnOrder } = configCsv[model] || {};
        if (!columnOrder) return [];

        return columnOrder.map((key, index) => ({
            title: key,
            dataIndex: getDataIndex(key),
            key,
            fixed: index < 4 ? 'left' : undefined, // Figer les 4 premières colonnes
            render: (text, record) => renderCell(key, record),
            onCell: () => ({
                style: {
                    zIndex: index < 4 ? 3 : 1,
                    position: index < 4 ? 'relative' : undefined,
                }
            }),
        }));
    }, [data, model, getDataIndex, renderCell]);



    useEffect(() => {
        setColumns(columnsMemo);
    }, [columnsMemo]);

    const handleCellChange = (value, record, field) => {
        const { columnToDataMapping } = configCsv[model] || {};
        const newData = [...data];
        const dataIndex = data.indexOf(record);
        if (dataIndex >= 0) {
            newData[dataIndex][columnToDataMapping[field] || field] = value;
            onDataChange(newData);
        }
    };

    // Composant générique pour les cellules éditables (texte ou booléen)
    const EditableCell = ({ value, onChange }) => {
        if (typeof value === 'boolean') {
            return <BooleanSelectCell value={value} onChange={onChange} />;
        }
        return <TextInputCell value={value} onChange={onChange} />;
    };

    const TextInputCell = ({ value, onChange }) => {
        const [editing, setEditing] = useState(false);
        const [currentValue, setCurrentValue] = useState(value);
        const [inputWidth, setInputWidth] = useState(null);
        const inputRef = useRef(null);
        const spanRef = useRef(null);

        // Fonction pour calculer la largeur de la cellule
        const calculateWidth = useCallback(() => {
            if (spanRef.current) {
                const newWidth = spanRef.current.offsetWidth + 20;
                setInputWidth(newWidth);
            }
        }, []);

        // Calcule la largeur à l'affichage ou lorsque la valeur change
        useEffect(() => {
            calculateWidth();
        }, [currentValue, calculateWidth]);

        const handleChange = (e) => {
            setCurrentValue(e.target.value);
        };

        const handleBlur = () => {
            setEditing(false);
            onChange(currentValue);
        };

        const handleEdit = () => {
            setEditing(true);
        };


        useEffect(() => {
            if (editing && inputRef.current) {
                inputRef.current.focus();
            }
        }, [editing]);

        return editing ? (
            <div style={{ display: 'inline-block', minWidth: inputWidth }}>
                <Input
                    ref={inputRef}
                    value={currentValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onPressEnter={handleBlur}
                    style={{
                        width: `${inputWidth}px`,
                        border: 'none',
                        outline: 'none',
                        boxShadow: 'none',
                        cursor: 'text',
                        padding: 0,
                    }}
                />
            </div>
        ) : (
            <div
                onClick={handleEdit}
                style={{
                    minWidth: '50px',
                    width: 'auto',
                    cursor: 'pointer',
                    height: '24px',
                    display: 'inline-block'
                }}
            >
                {/* Span caché pour mesurer la largeur du contenu */}
                <span ref={spanRef} style={{ visibility: 'hidden', position: 'absolute', whiteSpace: 'pre' }}>
                    {currentValue || ''}
                </span>
                {currentValue || ''}
            </div>
        );
    };


    // Composant pour les cellules de type booléen (oui/non)
    const BooleanSelectCell = ({ value, onChange }) => {
        const handleChange = (newValue) => {
            onChange(newValue === 'oui');
        };

        return (
            <Select
                value={value ? 'oui' : 'non'}
                onChange={handleChange}
                style={{ width: 80 }}
            >
                <Select.Option value="oui">oui</Select.Option>
                <Select.Option value="non">non</Select.Option>
            </Select>
        );
    };

    return (
        <div style={{ maxWidth: '100%' }}>
            <Table
                columns={columns}
                size={'large'}
                dataSource={data}
                rowKey={(record) => record.sku}
                pagination={false}
                scroll={{ x: 'max-content' }}
                className="custom-csv-table"
            />
        </div>
    );
}



