import React, {useState} from "react";
import {Button, Modal} from "antd";

export interface ModalOptions {
    title?: string,
    width?: number,
    buttons?: React.ReactNode
}

export function OkButton(callback){
    return (
        <Button onClick={callback}>Ok</Button>
    )
}

function useBasicModal(mask = false, closeCb=null, openCb=null , options?:ModalOptions, footer) {
    const [show, setShow] = useState(false);

    const open = (callback = null) =>{
        setShow(true);
        if(callback) callback();
        if(openCb) openCb();
    }

    const close = () =>{
        setShow(false);
        if(closeCb) closeCb();
    }

    const modal = (children) =>{
        return (
            <Modal
                title={options?.title || ""}
                width={options?.width || 520}
                mask={mask}
                open={show}
                onCancel={close}
                footer={footer ? footer : OkButton(close)}
                zIndex={1000}
            >
                {children}
            </Modal>
        )
    }


    return  [open, close, modal]

}

export default useBasicModal;