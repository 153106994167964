import React, {memo, useContext} from 'react';
import {ColumnsType} from "antd/es/table";
import {Badge, Popover, Table, Typography} from "antd";
import TagOrder from "../TagOrder";
import {FilterValue, SorterResult} from "antd/es/table/interface";
import {GiCardboardBoxClosed} from "react-icons/gi";
import {FaShippingFast} from "react-icons/fa";
import {BsFillCheckCircleFill} from "react-icons/bs";
import {IoSearchSharp} from "react-icons/io5";
import useAuth from "auth-sna";
import type {OrderTableProps} from "../../../Logistic";
import {TranslateContext} from "translate-provider";

const {Text, Link} = Typography;

function OrderTable({data,filter, updateFilter, openModal, onPageChange, pageSize, total, loading, page, activeTab, filterBoutiqueList}: OrderTableProps) {
    const user = useAuth();
    const {t} = useContext(TranslateContext);
    const createShopFilter = (shopList) =>{
        if(!shopList) return null;

        

        const filter = [];
        if( !shopList || shopList.length === 0) return filter;

        shopList.forEach((order) => {
            if (order.Boutique) {
                filter.push({
                    text: order.Boutique,
                    value: order.Boutique,
                });
            } else if (order.PlateformeBoutique) {
                filter.push({
                    text: order.PlateformeBoutique,
                    value: order.PlateformeBoutique,
                });
            }
        });
        return filter;
    }



    const columns: ColumnsType= [{
        title: t('shop'),
        filterMode: 'tree',
        filters: createShopFilter(filterBoutiqueList) || null,
        filteredValue: filter.boutique ,
        width: 100,
        key: 'Boutique',
        render: (text) => <>{text?.Boutique ? text?.Boutique : text?.PlateformeBoutique ? text?.PlateformeBoutique : '-' }</>,
    },
        {
            title: t('idCdeSNA'),
            dataIndex: 'IdCdeLogis',
            sorter: true,
            width: 100,
            key: 'IdCdeLogis',
            render: (text) => <a onClick={
                (event) => {
                    event.preventDefault();
                    openModal(text)
                }
            }>{text}</a>,
        },
        {
            title: t("refCde"),
            dataIndex: 'RefCde',
            sorter: true,
            width: 100,
            key: 'RefCde',

        },
        {
            title: t('libelleCde'),
            dataIndex: 'Libell_Cde',
            key: 'Libell_Cde',
            sorter: true,
            width: 150,
            render: (text) => <>{text || '-'}</>,
        },
        {
            title: t('dateCde'),
            dataIndex: 'DateCdeClient',
            key: 'DateCdeClient',
            sorter: true,
            width: 100,
            render: (date) => <>{
                date !=='1900-01-01T00:00:00.000Z' ? new Date(date).toLocaleDateString('fr-FR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                }) : '-'
            }</>,
        },{
            title:t('colis'),
            dataIndex: ['CdeLogisColis'],
            width: 75,
            className: 'text-center',
            render: (colis) => popOverColis(colis),
        },{
            title: t('nbArticles'),
            dataIndex: ['_count', 'CdeLogisArticle'],
            width: 75,
            className: 'text-center',
            key: 'nbArticle',
        },
        {
            title: t('destinataire'),
            dataIndex: ['API_syntheseCde', 'nomDestinataire'],
            key: 'NomDestinataire',
            sorter: true,
            responsive: ['xxl'],
            render: (text) => <>{
               text
            }</>,
        },
        {
            title: t('adresse'),
            dataIndex: ['API_syntheseCde'],
            key: 'Ad1 ',
            responsive: ['xxl'],
            render: (text) => <>{
                text?.ad1 + ' ' + text?.codePostal + ' ' + text?.ville
            }</>,
        },
        {
            title: t('pays'),
            dataIndex: [ 'API_syntheseCde', 'codeLettrePays'],
            key: 'CodePaysLettre',
            sorter: true,
            width: 50,
            render: (text) => <>{
                text
            }</>,
        },
        {
            title: t('transporteur'),
            dataIndex: ['API_syntheseCde','CdeLogisLiv', 'Transporteur'],
            key: 'Transporteur',
            width: 125,
            responsive: ['xxl'],
            sorter: true,
            render: (text) => <>{
                text
            }</>,
        },
        {
            title: t('service'),
            dataIndex: ['API_syntheseCde','CdeLogisLiv', 'Service'],
            key: 'Service',
            responsive: ['xxl'],
            sorter: true,
            render: (text) => <>{
                text
            }</>,
        },
        {
            title: t('statut'),
            // dataIndex: ['API_syntheseCde'],
            width: 100,
            filterMode: 'tree',
            filters: [],
            filteredValue: filter.subStatutCde || null,
            key: 'StatutCde',
            render: (data) =><TagOrder bloque={data.Bloqu_e}  data={data.API_syntheseCde} />
        },
        {
            title: t('Détail commande'),
            dataIndex: 'IdCdeLogis',
            width: 100,
            key: 'Détail commande',
            render: (text) => <a onClick={
                (event) => {
                    event.preventDefault();
                    openModal(text)
                }
            }><IoSearchSharp  size={20} />  </a>,
        },
    ];

    //Si l'utilisateur est un administrateur on affiche le nom du client
    if (['ROLE_ADMIN', 'ROLE_STAFF'].includes(user?.role)) {
        columns.splice(0, 0, {
            title: 'Client',
            dataIndex: ['Clients'],
            // responsive: ['xl'],
            key: 'NomClient',
            //reduce the font
            className: 'text-sm',
            render: (text) => <>{
                text?.CLINOM + " ( " + text?.CLICOD+" )"
            }</>,
        })
    }

    //set the sortfield and sortorder from filter and apply it on the table
    const sortField = filter.sortField || 'IdCdeLogis';
    const sortOrder = filter.sortOrder;


    //on ajoute le sortorder et le sortfield à la colonne correspondante
    columns.forEach((column) => {
        if (column.key === sortField) {
            column.sortOrder = sortOrder;
        }
    });


    //Si l'active tab est "AWAITING" on ajoute le filtreà la colonne statut
    if (activeTab === 'AWAITING') {
        columns[columns.length-2].filters = [
            {
                text: t('enAttente'),
                value: 'En attente de traitement',
            },
            {
                text: t('enPreparation'),
                value: 'En préparation',
            },
            {
                text: t('prepared'),
                value: 'Préparée',
            },
        ]
    }

    const handleTableChange = (pagination,
                               filters: Record<string, FilterValue>,
                               sorter: SorterResult ) => {

        const blockedSorter = ['action'];

        console.log('sorter', sorter);
        console.log('filters', filters);

        if (filters.Boutique) {
            updateFilter({
                boutique: filters.Boutique,
            });
        }else {
            updateFilter({
                boutique: null,
            });
        }



          if (filters.StatutCde) {
              updateFilter({
                    subStatutCde: filters.StatutCde,
                });
            }else{
                updateFilter({
                    subStatutCde: null,
                });
          }

        if (!blockedSorter.includes(sorter.columnKey)) {

                updateFilter({
                    sortField: sorter.order?sorter.field:null,
                    sortOrder: sorter.order?sorter.order:null,
                });

        }
        onPageChange(pagination.current-1, pagination.pageSize);
    };


    return (<Table    pagination={{
                        current: page+1,
                        pageSize: pageSize,
                        total: total}}
                      size={'small'}
                      scroll={{ y: 600 }}
                      columns={columns}
                      loading={loading}
                      dataSource={data.results}
                      onChange={handleTableChange}
    />)
}

/**
 * popOverColis : affiche le nombre de colis et les liens de tracking lorsque l'on passe la souris sur l'icone
 * @param colis
 * @return {JSX.Element}
 */
const popOverColis = (colis:[string])=>{
    const content =  <div>
        {colis.length > 0 ?
            colis.length > 0 && colis.map((colis, index) => {
                return <div key={index}>Colis Id : {colis.IdContenantTMS} -

                    {/*Affichage du statut de livraison*/}
                    { colis.KLS_Contenant.RetourDateLivr_ !== "1900-01-01T00:00:00.000Z" &&

                     <Text type='success'> Delivered <BsFillCheckCircleFill color={'green'} size={20} /> - </Text>}

                    {/*Affichage du lien de tracking*/}
                    <Link underline type={"warning"} href={colis.KLS_Contenant.RetourURLTracking} target='_blank' rel='noopener noreferrer'>
                        Track shipping <FaShippingFast size={20} />
                    </Link>
                </div>
            }):
            <p>No tracking URL available at the moment</p>
        }
    </div>


    return <Popover content={content} trigger='hover'>
        <Badge color={colis.length>0?'green':'grey'} count={colis.length} showZero>
            <GiCardboardBoxClosed size={20} />
        </Badge>
    </Popover>
}

export default memo(OrderTable);