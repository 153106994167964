import {useFetch} from "hooks-sna";
import {useEffect, useState} from "react";
import moment from "moment";
import {Typography} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import {HiWrenchScrewdriver} from "react-icons/hi2";

const { Text } = Typography;
const BASE_URL = process.env.REACT_APP_API_V2;
function GetMaintenance() {

    const [visible, setVisible] = useState(true);

    // Utilisez useEffect pour récupérer l'état de visibilité stocké localement lors du montage du composant
    useEffect(() => {
        const storedVisible = localStorage.getItem('maintenanceVisible');
        if (storedVisible) {
            setVisible(JSON.parse(storedVisible));
        }
    }, []);

    // Utilisez useEffect pour stocker localement l'état de visibilité à chaque changement
    useEffect(() => {
        localStorage.setItem('maintenanceVisible', JSON.stringify(visible));
        // console.log("visible :", visible);
    }, [visible]);


    let params = {
        url: `${BASE_URL}/administration/get-next-maintenance`,
    }

    useEffect(() => {
        if (data.length === 0) {
            fetchData();
            // console.log('fetch')
        }
    }, []);

    const [loading, error, data, hasMore, metadata, progress, fetchData] = useFetch(params);

    if (data.length === 0) {
        return (<div></div>)
    }

    return (
        visible && (
            <div style={{height:30}} className="bg-anthra d-flex flex-row justify-content-between ps-2 pe-2">
                    <div></div>
                    <Text type="warning">
                    <HiWrenchScrewdriver style={{color: '#faad14',  fontSize: 20, marginRight: 5 }} /> {data?.message} de: {moment(data?.startAt).utc().format('DD/MM/YYYY HH:mm:ss')} à {moment(data?.endAt).utc().format('DD/MM/YYYY HH:mm:ss')}
                    </Text>
                    <CloseOutlined style={{color: '#faad14'}} onClick={()=>{setVisible(false)}} />                   
            </div>
        )
    )
}


export default GetMaintenance;