import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import fr from "./french.json";
import en from "./english.json";
import ita from "./italian.json";
import de from "./german.json";
import es from "./spanish.json";

i18n.use(LanguageDetector) // détermine la langue du navigateur
  .use(initReactI18next) // passe i18n vers react-i18next
  .init({
    resources: {
      ...fr,
      ...en,
      ...de,
      ...ita,
      ...es
    },
    fallbackLng: "en",
    // debug: true,

    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false,

    interpolation: {
      escapeValue: false, // pas besoin d'échapper à React déjà le fait en toute sécurité
    },

    react: {
      wait: true,
    },
  });


export default i18n;
