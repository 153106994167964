import React, {Fragment, useCallback, useEffect, useReducer, useState} from 'react';
import {Autocomplete, Circle, GoogleMap, InfoWindow, Marker, useJsApiLoader,} from "@react-google-maps/api";
import {Card, Form, Input, Slider, Spin, Tag, Typography} from 'antd';
import {useFetch} from "hooks-sna";
import {debounce} from "lodash";

// définir les constantes requises
const { Meta } = Card;
const { Text, Title } = Typography;
const REACT_APP_API_V2 = process.env.REACT_APP_API_V2;
const libraries = ["places"];

const defaultIcon = { url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png' };
const highlightedIcon = { url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png' };
const centerParis = { lat: 48.856614, lng: 2.3522219 };

// Composant pour afficher les marqueurs
const RenderMarkers = React.memo(({ data, hoveredItem, setHoveredItem, error }) => {
    let dataReady = data && data.length !== 0 && data.PointRelais_Details;
    if(error){
        data.PointRelais_Details = [];
    }
    // Add state for the selected item
    const [selectedItem, setSelectedItem] = useState(null);

    return dataReady ? (
        <>
            {Object.entries(data.PointRelais_Details).map(([key, item], index) => (

                <Marker
                    key={index}
                    animation={google.maps.Animation.DROP}
                    position={{
                        lat: parseFloat(item.Latitude.replace(',', '.')),
                        lng: parseFloat(item.Longitude.replace(',', '.'))
                    }}
                    icon={hoveredItem === item.Num ? highlightedIcon : defaultIcon}
                    onMouseOver={() => setHoveredItem(item.Num)}
                    onMouseOut={() => setHoveredItem(null)}
                    onClick={() => { // Add this block

                        selectedItem === item ? setSelectedItem(null) : setSelectedItem(item);
                        item.ref.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest',
                            inline: 'start'
                        });
                    }}
                >
                    {selectedItem === item && ( // Add this block
                        <InfoWindow onCloseClick={() => setSelectedItem(null)}>
                            <Meta
                                className="align-right"
                                title={<> <Text className="p-1" >{item.LgAdr1}</Text>
                                    <Text strong>
                                        ID Point Relai :
                                    <Text copyable strong>{(item.Pays + "-" + item.Num)}</Text>
                                    </Text>
                                </>}
                                description={item.LgAdr3+" " + item.CP + " " + item.Ville}
                            />
                        </InfoWindow>
                    )}
                </Marker>
            ))}
        </>
    ) : null;
});

export const renderDateTag = (item) => {
    // console.log(item)
    if (!item.Informations_Dispo.Periode) return null;

    const startDate = new Date(item.Informations_Dispo?.Periode.Debut).toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
    const endDate = new Date(item.Informations_Dispo?.Periode.Fin).toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });

    return (
        <Tag color={'warning'}>
            <Text color={'red'}>
                {`Indisponible du ${startDate} au ${endDate}`}
            </Text>
        </Tag>
    );
};

// Composant pour afficher la carte
function RenderCard(props) {

    if (props.data && props.data.PointRelais_Details) {
        return Object.entries(props.data.PointRelais_Details).map((key, index) => {

            //for each car create ref to scroll to it
            let item = props.data.PointRelais_Details[index];
            item.ref = React.createRef();

            const IDRelay = item.Pays + ":" + item.Num;

            return (
                <Card
                    hoverable
                    ref={item.ref}
                    key={item.Num}
                    className={`mb-2 p-1 ${props.hoveredItem === item.Num ? 'highlighted' : ''}`}
                    style={{ height: 100 }}
                    onMouseEnter={() => props.setHoveredItem(item.Num)}
                    onMouseLeave={() => props.setHoveredItem(null)}
                >
                    <div
                        style={{textAlign: "left"}}
                        className="d-flex flex-row justify-content-between align-items-center"
                    >
                        <Meta
                            className="align-right"
                            title={<> <Text className="p-1" >{item.LgAdr1}</Text>


                                <Text
                                    strong
                                >
                                    ID Point Relai :
                                    <Tag><Text copyable strong>{IDRelay}</Text></Tag>

                                </Text>
                            </>}


                            description={<div className="d-flex flex-column">
                                <p>{item.LgAdr3} {item.CP} {item.Ville}</p>
                                {item.Informations_Dispo &&  renderDateTag(item)}
                            </div>}



                        />


                        <img
                            className="img-fluid"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "https://th.bing.com/th/id/OIP.1JsDVQiy43gY51qSvI3m-QHaHa?pid=ImgDet&rs=1";
                            }}
                            src={item.URL_Photo ? item.URL_Photo : "https://th.bing.com/th/id/OIP.1JsDVQiy43gY51qSvI3m-QHaHa?pid=ImgDet&rs=1"}
                            alt="logo"
                            style={{width: 80, height:80    }}
                        />
                    </div>
                </Card>
            );
        });
    }

}

export default function FindRelay() {

    const [center, setCenter] = useState(centerParis);
    const [distance, setDistance] = useState(20);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [place, setPlace] = useState(null);
    const [query, setQuery] = useState("");

    const queryParam = new URLSearchParams(window.location.search).get('idrelais');

    //DATA
    const [filter, updateFilter] = useReducer(
        (filter, updates) => ({ ...filter, ...updates }),
        { pays: "", cp: "", rayonDeRecherche: distance, latitude: "", longitude: "", query: queryParam? queryParam : ""}
    );
    
    const [loading, error, data, hasMore, metadata, progress, fetchData] = useFetch({
        url: `${REACT_APP_API_V2}/mondial-relay/get-relay`,
        method: "POST",
        body: filter,
    });




    useEffect(() => {

        if(filter.query !==''){
            fetchData();
        }

        if(filter.latitude !=='' && filter.longitude !==''){
            fetchData();
        }

    }, [filter]);


    useEffect(() => {
       if(Object.values(data).length === 1){
           setCenter({
               lat: parseFloat(data.PointRelais_Details[0].Latitude.replace(',', '.')),
               lng: parseFloat(data.PointRelais_Details[0].Longitude.replace(',', '.'))
           });
       }
    }, [data]);


    //GOOGLE MAPS
    const { isLoaded, google, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries,
    });
    const containerStyle = { width: '50%', height: '90vh' };

    //GEOLOCATION
    const [userLocation, setUserLocation] = useState({});
    navigator.geolocation.getCurrentPosition(position => {
        if (!userLocation.lat && !userLocation.lng) {
            setUserLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            });
        }
    });

    //AUTOCOMPLETE
    const [autocomplete, setAutocomplete] = useState(null);
    const onLoad = useCallback((autocomplete) => { setAutocomplete(autocomplete); }, []);
    const [showSlider, setShowSlider] = useState(false);

    const onPlacesChanged = () => {

        if (autocomplete) {
            setPlace(autocomplete.getPlace());
            let place = autocomplete.getPlace();



            let addressComponents = place.address_components;
            let postalCode, countryIso;

            //s'il n'y a pas de composant d'adresse, on ne fait rien
            if (!addressComponents) return

            //on récupère le code postal et le pays
            for (let i = 0; i < addressComponents.length; i++) {
                if (addressComponents[i].types.includes('postal_code')) { postalCode = addressComponents[i].short_name; }
                if (addressComponents[i].types.includes('country')) { countryIso = addressComponents[i].short_name; }
            }

            setCenter({
                lat: autocomplete.getPlace().geometry.location.lat(),
                lng: autocomplete.getPlace().geometry.location.lng()
            });
            // à chaque fois que l'utilisateur change de lieu, on met à jour le filtre
            updateFilter({
                pays: countryIso,
                cp: postalCode,
                latitude: autocomplete.getPlace().geometry.location.lat(),
                longitude: autocomplete.getPlace().geometry.location.lng(),
                query: ""
            });

            setShowSlider(true);
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };

    if (!isLoaded) { return <Spin />; }



    return (
        <Fragment>
            {isLoaded &&
                // Flex container pour aligner les éléments enfants
                <div className="d-flex flex-row justify-content-center align-items-start mt-3">
                    <Form className="bg-transparent me-2" style={{width: "50%"}}>
                        <div className="w-100 d-flex flex-column ">
                            <Autocomplete
                                onLoad={onLoad}
                                onPlaceChanged={onPlacesChanged}
                                className="Item bg-transparent"
                            >
                                <Form.Item>
                                    <Input
                                        //onchange with debounce
                                        onChange={debounce((e) => {
                                            // si la recherche commence par deux lettre et un '-' après les deux première lettre on considère que c'est une recherche de point relai précis
                                            if(e.target.value.length > 4 && e.target.value[2] === ":") {
                                                updateFilter({
                                                                 pays: "",
                                                                 cp: "",
                                                                 latitude: "",
                                                                 longitude: "",
                                                                 query: e.target.value
                                                             })
                                                setShowSlider(false)
                                                return
                                            }

                                            if(e.target.value === "") {
                                                //reset filter
                                                updateFilter({
                                                                 pays: "",
                                                                 cp: "",
                                                                 latitude: "",
                                                                 longitude: "",
                                                                 query:""
                                                             })
                                                setShowSlider(false)
                                            }else {
                                                setShowSlider(true)
                                            }
                                        }, 1000)}


                                        placeholder="Ville, Pays etc"
                                        //set slider to false when user change input
                                        onBlur={(e) => {
                                            if(e.target.value === "") {
                                                setShowSlider(false)
                                            }else {
                                                setShowSlider(true)
                                            }
                                        }}
                                        onFocus={() => setShowSlider(false)}
                                        //on press enter select the first adresse

                                    />
                                </Form.Item>

                            </Autocomplete>
                            {showSlider &&
                                <div className="d-flex flex-column mt-3">
                                        <Text className="text-lg-start">Rayon de recherche :</Text>
                                        <Slider

                                            defaultValue={distance}
                                            onAfterChange={(e) => {
                                                setDistance(e)
                                                updateFilter({
                                                    rayonDeRecherche: e
                                                })
                                            }}
                                            //tooltip with "km" at the end
                                            tipFormatter={value => `${value} km`}
                                        />


                                </div>
                            }
                        </div>

                        <div className="overflow-scroll" style={{maxHeight: '80vh'}}>
                            {loading && <Spin/>}
                            {error ? <Text>No result</Text> :   <RenderCard data={data} setHoveredItem={setHoveredItem} hoveredItem={hoveredItem} />}
                        </div>
                    </Form>

                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={10}>

                        <RenderMarkers
                            data={data}
                            setHoveredItem={setHoveredItem}
                            hoveredItem={hoveredItem}
                            center={center}
                            distance={distance}
                            error={error}
                        />

                        <Circle
                            center={center}
                            radius={distance * 1000}
                            options={{
                                strokeColor: "#ee8a5e",
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: "#ffa38f",
                                fillOpacity: 0.35,
                            }}
                        />
                        <Marker
                            position={center}
                            //blue pin
                            icon={{
                                url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",

                            }}
                        />
                    </GoogleMap>
                </div>
            }
        </Fragment>
    )
}