import { useCallback, useState } from "react";
import csvtojson from "csvtojson";

export default function useKitCsvToJson() {
    const [jsonData, setJsonData] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [fileValid, setFileValid] = useState(false);

    const handleFileChange = useCallback(async (file) => {
        const reader = new FileReader();

        reader.onload = async (e) => {
            let text = e.target.result;

            const json = await csvtojson({ delimiter: ";" }).fromString(text);

            // console.log("JSON résultant de la conversion :", json);


            setCustomer(json[0].CptClient);

            const mappedJson = json.map((item) => {

                const prixUnitTTC = item.PrixUnitTTC?.trim();
                const prixUnitTTCValue = prixUnitTTC ? parseInt(prixUnitTTC, 10) : 0;

                if (item.CptClient !== "" && item.RefKit !== "" && item.RefProduit !== "") {
                    setFileValid(true);
                    return {
                        CptClient: item.CptClient?.trim(),
                        RefKit: item.RéfKit?.trim(),
                        RefProduit: item.RefProduit?.trim(),
                        EAN13Kit: item.EAN13Kit?.trim() || "",
                        NbrOccurence: item.NbrExemplaire?.trim() || "",
                        PrixUnitTTC: prixUnitTTCValue,
                        Remarque: item.Remarque?.trim() || "",
                    };
                    
                 
                } else {
                    setFileValid(false);
                    console.log("Objet exclu :", item);
                    return null;
                }
            });
            setJsonData((prevData) => {
                const newData = mappedJson.filter((item) => item !== null);
                console.log("JSON résultant de la conversion :", newData);
                return newData;
              });
        };
        reader.readAsText(file, "ISO-8859-1");
    }, []);

    const setData = (item, defaultValue) => {
        return item || defaultValue;
    };

    return [jsonData, handleFileChange, customer,fileValid,];
}





