import React, {createContext} from 'react';
import {message} from 'antd';

export let ToastContext = createContext(null);
export default function ToastProvider({ children }){

    const [messageApi, contextHolder] = message.useMessage();
    //show only one message at a time
  const currentToast = React.useRef(null);


    const success = (message) => {
        if (currentToast.current) {
            return;
        }
       currentToast.current = messageApi.open({
            type: 'success',
            content: message,
            onClose: () => {
               currentToast.current = null;
           }
        });
    };

    const error = (message, duration = 5) => {
        if (currentToast.current) {
            return;
        }
        currentToast.current = messageApi.open({
            type: 'error',
            duration: duration,
            content: message,
            onClose: () => {
                currentToast.current = null;
            }
        });
    };

    const warning = (message) => {
        if (currentToast.current) {
            return;
        }
        currentToast.current =  messageApi.open({
            type: 'warning',
            content: message,
            onClose: () => {
                currentToast.current = null;
            }
        });
    };


    let value = {
        success,
        error,
        warning
    }
    // return ( <>
    //     {contextHolder}
    //     <Space>
    //         <Button onClick={success}>Success</Button>
    //         <Button onClick={error}>Error</Button>
    //         <Button onClick={warning}>Warning</Button>
    //     </Space>
    // </>);
return <ToastContext.Provider value={value}>{contextHolder}{children}</ToastContext.Provider>


}