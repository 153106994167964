import React, {Fragment, memo, useContext, useEffect, useReducer, useRef, useState} from "react";
import type {TabsProps} from 'antd';
import {Badge, Button, Progress, Space, Tabs, Typography} from "antd";
import {ReloadOutlined} from "@ant-design/icons";
import useAuth from "auth-sna";
import { ToastContext } from "toast-provider";
import SearchForm from "./src/component/SearchForm";
import ModalStock from "./src/component/ModalStock";
import ModalLimitStock from "./src/component/ModalLimitStock";
import { useFetch } from "hooks-sna";
import { isEqual } from 'lodash';
import ProgressBarLoader from "sna-components/src/ProgressBarLoader";
import axios from "axios";
import { exportParamInterface, useDataExport } from 'sna-export';
import StockTable from "./src/component/table/StockTable";
import { webSocketContext } from "websocket-provider";
import Cookies from "js-cookie";
import { AdminPanel } from "sna-components";
import { data } from "./../dataExport/src/useDataExport";


const { Text } = Typography;

const BASE_URL = process.env.REACT_APP_API_V2;

const tabMapping = {
    CURRENT_STOCK: { color: "gray", text: "Current Stock" },
    // OUT_OF_STOCK: {color: "red", text: "Out of Stock"},
}

//interface for logistic page "awaiting, shipped, received, cancelled, under_modification
export type OrderMode = "CURRENT_STOCK" | "OUT_OF_STOCK";

export interface OrderTableProps {
    data: any;  // Define the exact type if you can
    filter: any;  // Define the exact type if you can
    updateFilter: any;  // Define the exact type if you can
    openModal: any;  // Define the exact type if you can
    onPageChange: any;  // Define the exact type if you can
    pageSize: number;
    total: number;
    loading: boolean;
    page: number;
    activeTab: OrderMode;
    openModalLimit: any;
}

function Stock() {
    const auth = useAuth();
    const toast = useContext(ToastContext);
    /**
     * State for logistic page
     */
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(17);
    const [id, setId] = useState(null); // id of order to show in modal
    const [modalLimitContent, setModalLimitContent] = useState(null); // id of order to show in modal
    const [showModal, setShowModal] = useState(false);
    const [showModalLimit, setShowModalLimit] = useState(false);
    const [activeTab, setActiveTab] = useState(
        localStorage.getItem("defaultTab") || "CURRENT_STOCK"
    );
    const [isAdmin, setIsAdmin] = useState(['ROLE_ADMIN', 'ROLE_STAFF'].includes(auth.role));
    const createLogisticURL = logisticURL();


    const [url, setUrl] = useState(
        createLogisticURL("/stock/", isAdmin)
    );


    const initialFilter = {
        query: null,
        transporteur: [],
        service: [],
        city: null,
        country: [],
        client: Cookies.get('clientQuery') || null,
        orderDateBegin: null,
        orderDateEnd: null,
        sortField: null,
        sortOrder: null,
        TypeProduit: null,
    };

    /**
     * Search filter
     */
    const [filter, updateFilter] = useReducer(
        (filter, updates) => ({ ...filter, ...updates }),
        initialFilter
    );
    const prevFilterRef = useRef();

    const [countLoading, countError, countData, , , , fetchDataCount] = useFetch({ url: createLogisticURL("/stock/count", isAdmin), filter: filter });

    const [loading, error, data, , , progress, fetchData] = useFetch({ url, filter, page, pageSize });

    const [loadingStockUpdate, setLoadingStockUpdate] = useState(false);
    const [stockUpdate, setStockUpdate] = useState(window.localStorage.getItem("stockUpdate") || null);
    const [updatePourcentage, setUpdatePourcentage] = useState(0);

    const updateStock = () => {
        setLoadingStockUpdate(true);
        axios.get(createLogisticURL("/stock/update", false), {
            withCredentials: true,
            params: {
                ...filter,
                isAdmin: isAdmin
            }
        }).then((response) => {
            if (response.status === 200) {
                window.localStorage.setItem("stockUpdate", Date.now().toString());
                setStockUpdate(Date.now().toString());
            }
        }).catch((error) => {
            toast.error("Error while updating stock");
        }).finally(() => {
            setLoadingStockUpdate(false);
        });
    }


    const ws = useContext(webSocketContext);

    useEffect(() => {

        if (ws && ws.socket !== null) ws.socket.on("stockUpdate", (e) => {

            setUpdatePourcentage(e);
        });
    }, []);


    useEffect(() => {
        setLoadingStockUpdate(updatePourcentage !== 0 && updatePourcentage !== 100);
        if (updatePourcentage === 100) {
            fetchData();
            setUpdatePourcentage(0)
        }
    }, [updatePourcentage]);




    useEffect(() => {
        fetchData();
        fetchDataCount();
        if (!isEqual(prevFilterRef.current, filter)) {
            setPage(0);
        }
        prevFilterRef.current = filter;
    }, [url, filter, page, pageSize]);

    const handlePageChange = (newPage, newPageSize) => {
        setPage(newPage);
        setPageSize(newPageSize);
    };

    const onChangeTab = (key: OrderMode) => {
        setActiveTab(key);
        //reinit all filter
        updateFilter(initialFilter);
        setPage(0);
        setUrl(createLogisticURL(`client-${key.toLowerCase()}-orders`, isAdmin));
    }

    const showModalOrder = (idModal) => {
        setId(idModal);
        setShowModal(true);
    };

    const showModalLimitStock = (data, idEltClient) => {
        console.log("Ouverture de la modal pour l'ID :", data);

        data = {
            ...data,
            idEltClient: idEltClient
        }

        setModalLimitContent(data)
        setShowModalLimit(true);
    };


    const items: TabsProps['items'] =
        Object.keys(tabMapping).map((tabKey: OrderMode) => ({
            key: tabKey,
            label: (
                <Badge
                    count={countData[tabKey.toLowerCase()]}
                    offset={[10, -10]}
                    overflowCount={99999}
                    color={tabMapping[tabKey].color}
                >
                    <Text type={"secondary"}>{tabMapping[tabKey].text}</Text>
                </Badge>
            ),
            children: (
                <>
                    <ProgressBarLoader loading={loading} progress={progress} />
                    <StockTable
                        filter={filter}
                        updateFilter={updateFilter}
                        data={data}
                        error={error}
                        loading={loading}
                        openModal={showModalOrder}
                        openModalLimit={showModalLimitStock}
                        onPageChange={handlePageChange}
                        pageSize={pageSize}
                        total={countData[tabKey.toLowerCase()]}
                        page={page}
                        activeTab={activeTab}
                    />
                </>

            )
        }))



    let exportParams: exportParamInterface = {
        urlRequest: `${BASE_URL}/data-export/request-spreadsheet-stock${isAdmin ? '-admin' : ''}`,
        outputName: 'SNA-export-stock',
        filter: filter,
        errorCallback: (error) => {
            toast.error("Error while exporting data");
        },
    }

    const [loadingExport, handleDataExport] = useDataExport(exportParams);
    console.log('user', auth.user)

    // In the return part of the Logistic component, replace Bootstrap's Tabs with antd's Tabs
    return (
        <Fragment>


            {
                isAdmin && <>
                    <AdminPanel currentClient={filter.client} updateFilterDasboard={updateFilter} />
                    <Button className={'mb-1'} disabled={loadingStockUpdate} loading={loadingStockUpdate} onClick={updateStock}> {loadingStockUpdate? 'update : ' +updatePourcentage + " %" : "mettre à jour le stock"}</Button>
                    {/*</>  : null}*/}
                    <Progress className={'mb-3'} percent={updatePourcentage} status="active" strokeColor={{ from: '#108ee9', to: '#87d068' }} />

                </>
            }

            <SearchForm filter={filter} updateFilter={updateFilter} />

            {showModal && <ModalStock show={showModal} setShow={setShowModal} id={id} onHide={() => setShowModal(false)} />}
            {showModalLimit && <ModalLimitStock fetchData={fetchData} show={showModalLimit} setShow={setShowModalLimit} data={modalLimitContent} />}

            <Tabs
                tabBarExtraContent={<Space>
                    {/* <Button onClick={showModalLimitStock}>Limite Stock</Button> */}
                    <Button onClick={() => {
                        fetchData()
                        fetchDataCount()
                    }} > <> <ReloadOutlined /> ReloadData </> </Button>
                    <Button loading={loadingExport} onClick={handleDataExport} > {loadingExport ? "Processing Data...please wait" : "Export Data"}</Button>
                </Space>
                }
                activeKey={activeTab}
                items={items}
                onTabClick={onChangeTab}
                tabPosition="top"
                animated />

        </Fragment>
    );
}
/**
 * Generate Url for logistic
 * @return {function(*, *): string}
 */
function logisticURL() {
    return (endpoint, isAdmin) => {
        const adminSuffix = isAdmin ? "-admin" : "";
        return `${BASE_URL}${endpoint}${adminSuffix}`;
    };
}



export default memo(Stock);
