export const BaseCsv = {
    CptClient : {
        name:'CptClient',
        default: '',
        operation: (value) => value?.trim()
    },
    RefProduit : {
        name:'sku',
        operation: (value) => value?.trim()
    },
    TypeProduit : {
        name:'productType',
        operation: (value) => value?.trim()
    },
    Titre : {
        name:'title',
        default: '',
        operation: (value) => value?.trim()
    },
    Artiste : {
        name:'artist',
        default: '',
        operation: (value) => value?.trim()
    },
    DateCommercialisation : {
        name:'marketingDate',
        default: '1900-01-01',
        operation: (value) => value ? value.split("/").reverse()?.join("-") : "1900-01-01"
    },   
    Poids : {
        name:'weight',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    Prix : {
        name:'unitPrice',
        default: 0.0,
        operation: (value) => Number(value.replace(",", ".")) || 0.0
    },
    PrixPrestationSNA : {
        name:'SNABilling',
        default: 0.0,
        operation: (value) => Number(value.replace(",", ".")) || 0.0
    },
    CodeDouane : {
        name:'custodyCode',
        default: '',
        operation: (value) => value?.trim()
    },
    LibellePrCN23 : {
        name:'CN23Label',
        default: '',
        operation: (value) => value?.trim()
    },

}

export const ProductDtoCCsv ={
    ...BaseCsv,
    PrdFini: {
        name:'finishedProduct',
        default: false,
        operation: (value) => value === "oui"
    },Remarque:{
        name:'Remarque',
        default: '',
        operation: (value) => value?.trim()
    },
    LienUrlVisuelArticle : {
        name:'imageUrl',
        default: '',
        operation: (value) => value?.trim()
    },
    CodeEAN13 : {
        name:'barCode',
        default: '',
        operation: (value) => value?.trim()
    },
    Boitage : {
        name:'numberOfItemsPerBox',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    NbrPage : {
        name:'numberOfPages',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    DescriptifPrdFini : {
        name:'finishedProductComposition',
        default: '',
        operation: (value) => value?.trim()
    },
    NbrCD : {
        name:'numberOfCD',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    NbrBD : {
        name:'numberOfBD',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    NbrDVD : {
        name:'numberOfDVD',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    NbrVinyle : {
        name:'numberOfVinyls',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    Hauteur : {
        name:'height',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    Largeur : {
        name:'width',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    Longueur : {
        name:'length',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    OccCodeEAN13Specifique: {
        name:'occBarCode',
        default: '',
        operation: (value) => value?.trim()
    },
    OCC : {
        name:'occ',
        default: false,
        operation: (value) => value?.trim() === "oui"
    },
    DtoC : {
        name:'DtoC',
        default: false,
        operation: (value) => value === "oui" ? true : value !== "non"
    },
    Taille: {
        name:'size',
        default: '',
        operation: (value) => value?.trim()
    },
    Genre: {
        name: 'gender',
        dafault: '',
        operation: (value) => value?.trim()
    },
    PrixPrestationSNA: {
        name: 'SNABilling',
        default: 0.0,
        operation: (value) => Number(value.replace(",", ".")) || 0.0
    },
    Couleur: {
        name: 'color',
        default: '',
        operation: (value) => value?.trim()
    },
}

export const ProductImpTextileCsv = {
    ...BaseCsv,
    Couleur : {
        name:'color',
        default: '',
        operation: (value) => value?.trim()
    },
    Taille : {
        name:'size',
        default: '',
        operation: (value) => value?.trim()
    },
    Genre :{
        name: 'gender',
        default: '',
        operation: (value) => value?.trim()
    },
    StockSupportVierge : {
        name:'blankMediaStock',
        default: '',
        operation: (value) => value?.trim()
    },
    RefSupportVierge : {
        name:'blankMediaRef',
        default: '',
        operation: (value) => value?.trim()
    },
    OperationVisuelA : {
        name:'visualOperationA',
        default: '',
        operation: (value) => value?.trim()
    },
    RefVisuelA : {
        name:'visualReferenceA',
        default: '',
        operation: (value) => value?.trim()
    },
    ImprFonceeVisuelA : {
        name:'visualDarkPrintA',
        default: false,
        operation: (value) => value?.trim() === "oui"
    },
    LienUrlPreviewVisuelA : {
        name:'visualPreviewUrlA',
        default: '',
        operation: (value) => value?.trim()
    },
    OperationVisuelB : {
        name:'visualOperationB',
        default: '',
        operation: (value) => value?.trim()
    },
    RefVisuelB : {
        name:'visualReferenceB',
        default: '',
        operation: (value) => value?.trim()
    },
    ImprFonceeVisuelB : {
        name:'visualDarkPrintB',
        default: false,
        operation: (value) => value?.trim() === "oui"
    },
    LienUrlPreviewVisuelB : {
        name:'visualPreviewUrlB',
        default: '',
        operation: (value) => value?.trim()
    },
    OperationVisuelC : {
        name:'visualOperationC',
        default: '',
        operation: (value) => value?.trim()
    },
    RefVisuelC : {
        name:'visualReferenceC',
        default: '',
        operation: (value) => value?.trim()
    },
    ImprFonceeVisuelC : {
        name:'visualDarkPrintC',
        default: false,
        operation: (value) => value?.trim() === "oui"
    },
    LienUrlPreviewVisuelC : {
        name:'visualPreviewUrlC',
        default: '',
        operation: (value) => value?.trim()
    },
    OperationVisuelD : {
        name:'visualOperationD',
        default: '',
        operation: (value) => value?.trim()
    },
    RefVisuelD : {
        name:'visualReferenceD',
        default: '',
        operation: (value) => value?.trim()
    },
    ImprFonceeVisuelD : {
        name:'visualDarkPrintD',
        default: false,
        operation: (value) => value?.trim() === "oui"
    },
    LienUrlPreviewVisuelD : {
        name:'visualPreviewUrlD',
        default: '',
        operation: (value) => value?.trim()
    }
}

export const ProductFullCsv = {
    ...ProductImpTextileCsv,
    Remarque:{
        name:'Remarque',
        default: '',
        operation: (value) => value?.trim()
    },
    LienUrlVisuelArticle : {
        name:'imageUrl',
        default: '',
        operation: (value) => value?.trim()
    },
    CodeEAN13 : {
        name:'barCode',
        default: '',
        operation: (value) => value?.trim()
    },
    Boitage : {
        name:'numberOfItemsPerBox',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    NbrPage : {
        name:'numberOfPages',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    PrdFini : {
        name:'finishedProduct',
        default: false,
        operation: (value) => value === "oui"
    },
    DescriptifPrdFini : {
        name:'finishedProductComposition',
        default: '',
        operation: (value) => value?.trim()
    },
    NbrCD : {
        name:'numberOfCD',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    NbrBD : {
        name:'numberOfBD',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    NbrDVD : {
        name:'numberOfDVD',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    NbrVinyle : {
        name:'numberOfVinyls',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    Hauteur : {
        name:'height',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    Largeur : {
        name:'width',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    Longueur : {
        name:'length',
        default: 0,
        operation: (value) => Number(value.replace(",", ".")) || 0
    },
    OccCodeEAN13Specifique: {
        name:'occBarCode',
        default: '',
        operation: (value) => value?.trim()
    },
    OCC : {
        name:'occ',
        default: false,
        operation: (value) => value?.trim() === "oui"
    },
    DtoC : {
        name:'DtoC',
        default: false,
        operation: (value) => value === "oui" ? true : value !== "non"
    }
}

