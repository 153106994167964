import {FaBook, FaCompactDisc, FaFileCsv, FaLock, FaRecordVinyl, FaRegAddressCard, FaShopify, FaUpload, FaUsers, FaUser,FaUserTie} from "react-icons/fa";
import {FaBoxesPacking, FaBoxesStacked, FaFileImport, FaPhone, FaPlugCirclePlus, FaTruckRampBox} from "react-icons/fa6";
import {logoMondialRelay} from "sna-style";
import {HiWrenchScrewdriver} from "react-icons/hi2";
import {SiZendesk} from "react-icons/si";
import {LuImport} from "react-icons/lu";
import { FaBoxes } from "react-icons/fa";

const style = {
    fontSize: 25,
    color: '#fff',
    marginRight: 10
}

export const iconMapping = {
    'Import': <FaFileImport style={{...style}}/>,
    'import/product-import': <FaFileCsv style={{...style}}/>,
    'import/import-kit': <FaFileCsv style={{...style}}/>,
    'import/import-orders': <FaFileCsv style={{...style}}/>,
    'logistic/commands': <FaBoxesPacking style={{...style}}/>,
    'ftp-cd': <FaCompactDisc style={{...style}}/>,
    'ftp-blu-ray': <FaCompactDisc style={{...style}}/>,
    'ftp-dvd': <FaCompactDisc style={{...style}}/>,
    'ftp-vinyle': <FaRecordVinyl style={{...style}}/>,
    'Upload': <FaUpload style={{...style}}/>,
    'administration/user': <FaUsers style={{...style}}/>,
    'logistic/stock': <FaBoxesStacked style={{...style}}/>,
    // 'logistic/mondial-relay': <BiWorld style={{...style}} />,
    'logistic/mondial-relay': <img width={25} src={logoMondialRelay} alt=""/>,
    'Mondial Relay': <img width={25} src={logoMondialRelay} alt=""/>,
    'gestionnaire-mdp': <FaLock style={{...style}}/>,
    'Logistique': <FaTruckRampBox style={{...style}}/>,
    'numero-employe': <FaPhone style={{...style}}/>,
    'Administration': <FaUserTie style={{...style}}/>,
    'administration/maintenance': <HiWrenchScrewdriver style={{...style}}/>,
    'shopify/connect-shop': <FaPlugCirclePlus />,
    'shopify/gestion-stock': <FaBoxesStacked style={{...style}}/>,
    'shopify/product-import': <LuImport />,
    'Shopify': <FaShopify style={{...style}}/>,
    'administration/zendesk': <SiZendesk style={{...style}}/>,
    'logistic/mondial-relay-errors': <img width={25} src={logoMondialRelay} alt=""/>,
    'documentation': <FaBook style={{...style}} />,
    'etiquette-badge': <FaRegAddressCard style={{...style}} />,
    'inventaire': <FaBoxes style={{...style}} />,
    'profil': <FaUser style={{...style}}/>
}
