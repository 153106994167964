import React from "react";
import { spinner } from "sna-style";

function SpinnerSNA({ width = 100 }) {
  return (
    <div
      className={"w-100 h-100 d-flex justify-content-center align-items-center"}
    >
      <img width={width} src={spinner} alt={"spinner"} />
    </div>
  );
}
export default SpinnerSNA;