import { useEffect, useState } from 'react';

const useFetchStocks = (data, baseUrl) => {
    const [stocks, setStocks] = useState([]);

    useEffect(() => {
        if (data && data.length > 0) {
            const fetchStocks = async () => {
                try {
                    const fetchedStocks = await Promise.all(
                        data.map(async (item) => {
                            const response = await fetch(
                                `${baseUrl}/article/stock-mouv?idProduit=${item.IdProduit}&NaturePrd=${item.NaturePrd}`,
                                {
                                    method: 'GET',
                                    credentials: 'include',
                                }
                            );
                            const stockData = await response.json();

                            // Check for error in stockData
                            if (stockData && stockData.stock && stockData.stock.statusCode === 500) {
                                throw new Error(stockData.stock.message);
                            }

                            return { ...item, stock: stockData };
                        })
                    );
                    setStocks(fetchedStocks);
                } catch (e) {
                    console.error(e);
                    setStocks([]);
                }
            };

            fetchStocks();
        } else {
            setStocks([]);
        }
    }, [data, baseUrl]);

    return [stocks];
};

export default useFetchStocks;
