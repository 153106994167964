import React, { memo, useCallback, useState, useEffect } from 'react';
import { Button, Form, Input, Select, SelectProps, DatePicker, AutoComplete } from "antd";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { useUserCookie } from "hooks-sna";
import moment from "moment";
import dayjs from "dayjs"

const { RangePicker } = DatePicker;

function SearchForm(props) {
    const [
        transporteurs,
        cities,
        countries,
        services,
    ] = useUserCookie([
        'transporteurs',
        'cities',
        'countries',
        'services',
    ]);

    const [showOptions, setShowOptions] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {

        form.setFieldsValue({
            service: props.filter.service,
            query: props.filter.query,
            country: props.filter.country,
            city: props.filter.city,
            transporteur: props.filter.transporteur,
            client: props.filter.client,
            subStatutCde: props.filter.subStatutCde,
            boutique: props.filter.boutique,
            rangetime: props.filter.orderDateBegin && props.filter.orderDateEnd ? [dayjs(props.filter.orderDateBegin), dayjs(props.filter.orderDateEnd)] : null,
        });
    }, []);

    // Utilisation de debounce pour retarder l'exécution des fonctions de mise à jour du filtre et de l'URL
    const debouncedUpdateFilter = debounce((query) => {
        props.updateFilter({ query });
        // updateURL({ query });
    }, 500);

    // Utilisation de useCallback pour créer une version mémorisée de la fonction de mise à jour de la city avec debounce
    const debouncedCityUpdate = useCallback(
        debounce((e) => {
            props.updateFilter({ city: e || null });
            // updateURL({ city: e || null });
        }, 500),
        []
    );



    /**
* Gestion de la recherche
* @param event
*/
    const handleQueryChange = (event) => {
        const query = event.target.value;
        debouncedUpdateFilter(query);
    };

    const updateQuery = (query) => {
        props.updateFilter({ query });
    };

    const updateCity = (city) => {
        props.updateFilter({ city });
    };

    const updateCountry = (country) => {
        props.updateFilter({ country });
    };

    const updateTransporteur = (transporteur) => {
        props.updateFilter({ transporteur });
    };

    const updateService = (service) => {
        props.updateFilter({ service });
    };

    const rangeConfig = {
        rules: [
            {
                type: 'array',
                required: true,
            },
        ],
    };

    return (
        <Form className="bg-transparent" form={form}  >
            <div style={{ display: 'flex' }}>
                <Form.Item name="query" style={{ marginRight: '8px', flex: '1' }}>
                    <Input
                        bordered={true}
                        allowClear={true}
                        size={'large'}
                        prefix={<SearchOutlined className="site-form-item-icon" />}
                        placeholder="Id SNA, référence commande, référence produit..."
                        onChange={(event) => handleQueryChange(event, updateQuery)}
                    />
                </Form.Item>
                <Button type="text" style={{ marginTop: 5 }} onClick={() => setShowOptions(!showOptions)}>
                    Plus d'options <CaretDownOutlined />
                </Button>
            </div>
            <div className={!showOptions ? "container-fluid hidden z-1" : "container-fluid z-1"}>
                <div className="row gap-2 justify-content-center ">
                    <Form.Item className="col" name={"country"} >
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder="Pays de livraison"
                            onChange={(e) => updateCountry(e)}
                            options={countries}
                        />
                    </Form.Item>
                    <Form.Item className=" col flex-grow-1" name={"city"}>
                        <AutoComplete
                            allowClear
                            options={cities}
                            onChange={(e) => debouncedCityUpdate(e, updateCity)}
                            placeholder="Ville de livraison"
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    </Form.Item>
                    <Form.Item className="col" name={"transporteur"} >
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder="Transporteur"
                            onChange={(e) => updateTransporteur(e)}
                            options={transporteurs}
                        />
                    </Form.Item>
                    <Form.Item className="col" name={"service"}>
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder="Services"
                            onChange={(e) => updateService(e)}
                            options={services}
                        />
                    </Form.Item>
                </div>

                <div className="row justify-content-center">
                    <Form.Item label="Date de commande" className="col-5 align-self-center" name="rangetime"  {...rangeConfig}   >
                        <RangePicker

                            format="YYYY-MM-DD"
                            onChange={(e) => {
                                console.log("date:", e);
                                if (e === null) {
                                    props.updateFilter({
                                        orderDateBegin: null,
                                        orderDateEnd: null,
                                    })
                                    return;
                                }
                                props.updateFilter({
                                    orderDateBegin: dayjs(e[0]).format('YYYY-MM-DD'),
                                    orderDateEnd: dayjs(e[1]).format('YYYY-MM-DD'),
                                })
                            }}
                            //block selection of dates after today
                            disabledDate={(current) => {
                                return current && current > moment().endOf('day');
                            }}

                        />
                    </Form.Item>
                </div>

            </div>
        </Form>
    );
}

export default memo(SearchForm);