import {Autocomplete, GoogleMap, InfoWindow, Marker, useJsApiLoader} from "@react-google-maps/api";
import useSWR from "swr";
import {CredentialFetcher} from "hooks-sna";
import React, {useCallback, useContext, useEffect, useReducer, useState} from "react";
import {Button, Card, Descriptions, Form, Input, Select, Typography} from "antd";
import greyDot from './img/grey-dot.png'
import blueDot from './img/blue-dot.png'
import redDotChiliPepper from './img/redDotChiliPepper.png'
import {ToastContext} from "toast-provider";
import {ClientSearch, SpinnerSNA, useBasicModal} from "sna-components";
import TextArea from "antd/es/input/TextArea";
import {renderDateTag} from "./FindRelay";
import {debounce} from "lodash";
import Clipboard from "clipboard";

const { Meta } = Card;
const { Text, Title } = Typography;

const defaultIcon = { url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png' };
const highlightedIcon = { url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png' };

const libraries = ["places"];

const INTRANET_URL = process.env.REACT_APP_API_INTRANET
const API_V2_URL = process.env.REACT_APP_API_V2
const centerParis = { lat: 48.856614, lng: 2.3522219 };

export async function getMRData(dataSelected){

    if(!dataSelected) return

   //we check if the dataSelected.pointRelais is empty
    if(dataSelected.hasOwnProperty('pointsRelais') && dataSelected.pointsRelais.length > 0){
        return null
    }



    const payload = dataSelected.OrigineLatitude === '' && dataSelected.OrigineLongitude === ''
        ? { pays: dataSelected.OrigineCodePays, cp: dataSelected.OrigineCP}
        : { latitude: dataSelected.OrigineLatitude, longitude: dataSelected.OrigineLongitude,pays: dataSelected.OrigineCodePays, rayonDeRecherche: 25};

    const result = await fetch(`${API_V2_URL}/mondial-relay/get-relay?clean=true&idCdeLogisLiv=${dataSelected.IdCdeLogisLiv}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    })

    if (!result.ok) {
        console.error(`Error: ${result.status} ${result.statusText}`);
        throw new Error('Network response was not ok');
    }

    if(result.status === 204){
        console.log('No relay found')
        return null
    }

    const data = await result.json();

    return data

}
export const calculDistance = (latA,lngA, latB, lngB) =>{

    latA = Number(latA)
    lngA = Number(lngA)
    latB = Number(latB)
    lngB = Number(lngB)

    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(latB-latA);  // deg2rad below
    const dLon = deg2rad(lngB-lngA);
    const a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(latA)) * Math.cos(deg2rad(latB)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c; // Distance in km
}
export const deg2rad = (deg) => {
    return deg * (Math.PI / 180)
}
export default function RelayError() {
    //récupération du paramètre d'url
    const urlParams = new URLSearchParams(window.location.search);

    const cdelogisliv = urlParams.get('cdelogisliv');
    const idCdeLogisLot = urlParams.get('idcdelogislot');

    const initialFilter = {
        cdelogisliv: null,
        idcdelogislot: null,
        transporter: null,
        service: null,
        cdelogis: null,
        cptClient: null,
        valider:null,
        query: null
    }

    const [filter, updateFilter] = useReducer(
        (filter, updates) => ({ ...filter, ...updates }),
        initialFilter
    );


    useEffect(()=>{
        updateFilter({cdelogisliv: cdelogisliv, idcdelogislot: idCdeLogisLot})
    },[])

    const [dataCdeLogisLiv, setDataCde] = useState(null)
    const [dataSelected, setDataSelected] = useState(null)
    const [center, setCenter] = useState(centerParis)

    const toast = useContext(ToastContext)
    const getURl = () => {
        let baseUrl = `${INTRANET_URL}/mondial-relay-modif`
        let params =[]

        Object.entries(filter).map(([key, value], index) => {
            // if(value) baseUrl += `${index === 0 ? '?' : '&'}${key}=${value}`
            if(value) params.push(`${key}=${value}`)
        })

        const url = params.map((item, index) => `${index === 0 ? '?' : '&'}${item}`).join('')

        return baseUrl+url
    }


    const {data: dataCde, error: errorCde, isLoading: loadingCde} = useSWR(getURl(), CredentialFetcher)



    useEffect(() => {
        if (dataCde &&  dataCde.length > 0) {
            setDataCde(dataCde)
            return
            // setCenter({lat: Number(dataCde[0]?.OrigineLatitud)||centerParis.lat, lng: Number(dataCde[0]?.OrigineLongitude)||centerParis.lng})
        }
        if(dataCde && dataCde.length === 0){
            setDataCde([])
        }
    }, [dataCde]);

    let mrPoint = []


    const [initialAddress, setInitialAddress] = useState(null)
    const [GoogleAutocomplete, response, inputRef] = useGoogleSearch(initialAddress)

    const onSelect = async (data) => {
        if(data.ProposeIdPointRelais !== ''){
            if(data.pointsRelais && data.pointsRelais.length > 0){
                setCenter({lat: Number(data.OrigineLatitude), lng: Number(data.OrigineLongitude)})
                setDataSelected(data)
                return
            }
            mrPoint = await getMRData(data)
            const newSelectedData = {...data, pointsRelais: mrPoint||[]}
            setDataSelected(newSelectedData)
            setCenter({lat: Number(data.OrigineLatitude), lng: Number(data.OrigineLongitude)})

            return mrPoint
        }else{
            setCenter({lat: centerParis.lat, lng: centerParis.lng})

            setDataSelected({...data, pointsRelais: []})
            setDataCde(prev => prev.map((item) => {
                if (item.IdCdeLogisLiv === data.IdCdeLogisLiv) {
                    return {...item, pointsRelais: mrPoint}
                }
                return item
            }))
        }
    }

    useEffect(() => {
        if (dataCdeLogisLiv && dataCdeLogisLiv.length > 0 && !dataSelected) {
            onSelect(dataCdeLogisLiv[0])
        }
    }, [dataCdeLogisLiv]);
    const updateDataWithNewRelay = (newRelay) => {

        const distance = calculDistance(dataSelected.OrigineLatitude, dataSelected.OrigineLongitude, newRelay.Latitude.replace(',','.'), newRelay.Longitude.replace(',','.'))

        const newRelayPoint = {...dataSelected, ProposeIdPointRelais: `${newRelay.Pays}:${newRelay.Num}`, ProposeNom: newRelay.LgAdr1, ProposeAdresse: `${newRelay.LgAdr3}`, ProposeCP: newRelay.CP, ProposeVille: newRelay.Ville, ProposeCodePays: newRelay.Pays, ProposeLatitude: newRelay.Latitude.replace(',','.'), ProposeLongitude: newRelay.Longitude.replace(',','.'), ProposeDistance: Math.ceil(distance*1000), ValideIdUser:'user'}

        setDataSelected(newRelayPoint)
        //we update dataCdeLogisLiv with the new data of result
        setDataCde(prev => prev.map((item) => {
            if (item.IdCdeLogisLiv === dataSelected.IdCdeLogisLiv) {
                return newRelayPoint
            }
            return item
        }))

    }
    const sendValidation = async (data, cb) =>{

            delete data.ref
            delete data.pointsRelais

        try{
            const result = await fetch(`${INTRANET_URL}/mondial-relay-modif`, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })

            if (!result.ok) {
                console.error(`Error: ${result.status} ${result.statusText}`);
                throw new Error('Network response was not ok');
            }

            if(result.status === 204){
                console.log('No relay found')
                return null
            }

            const resultData = await result.json();

            setDataCde(prev => prev.map((item) => {
                if (item.IdCdeLogisLiv === data.IdCdeLogisLiv) {
                    return {...item, ValideIdPointRelais: data.ProposeIdPointRelais, ValideRemarque: data.ValideRemarque}
                }
                return item
            }))

            cb()
            toast.success('Commande modifiée',15)

            return resultData
        }catch (e) {
            console.error(e)
            toast.error('Erreur lors de la modification de la commande',15)
            cb()
        }
    }

    //GOOGLE MAPS
    const {isLoaded, google, loadError} = useJsApiLoader({googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, libraries: libraries});

    const containerStyle = {width: '100%', height: '90vh',position: 'sticky',zIndex: 500};

    const initialParameter = {
        CP: null,
        CodePays: null,
        Latitude: null,
        Longitude: null
    }

    const [parameter, updateParameter] = useReducer(
        (parameter, updates) => ({ ...parameter, ...updates }),
        initialParameter
    );



    useEffect(() => {
        const getmr = async (lat, lng) => {
            if(dataSelected){
                const mrPoint = await getMRData({...dataSelected,pointsRelais:[], OrigineLatitude: lat, OrigineLongitude: lng})
                const newSelectedData = {...dataSelected, pointsRelais: mrPoint||[],OrigineLatitude: lat, OrigineLongitude: lng}
                setDataSelected(prev=>newSelectedData)
                setDataCde(prev => prev.map((item) => {
                    if (item.IdCdeLogisLiv === dataSelected.IdCdeLogisLiv) {
                        return newSelectedData
                    }
                    return item
                })
            )
            }
        }

        if(response){
            setCenter({lat: response.lat, lng: response.lng})
            getmr(response.lat, response.lng)
            updateParameter({
                                CP: response.postalCode,
                                CodePays: response.countryIso,
                                Latitude: response.lat,
                                Longitude: response.lng
                            })
        }
    }, [response]);




    return (<>
            {isLoaded && <div>
                <SearchPanel filter={filter} updateFilter={updateFilter} setCenter={setCenter} initialFilter={initialFilter}/>
                <div className="d-flex flex-row justify-content-center align-items-start mt-3">
                    <div className="w-50 d-flex flex-column z-1 ">
                        <Text className='w-100 text-start' level={1}>A valider ({
                            (dataCdeLogisLiv && dataCdeLogisLiv.length > 0) && dataCdeLogisLiv.reduce((acc, item) => {
                                if(item.ValideIdPointRelais === "") acc++
                                return acc
                            }, 0) || 0}) :</Text>

                        {loadingCde && <><SpinnerSNA /></>}


                        {(dataCdeLogisLiv && dataCdeLogisLiv.length > 0) && dataCdeLogisLiv.map((data, index) => {
                            if(data.ValideIdPointRelais === "") {
                                return (<RenderCard
                                    key={index}
                                    data={data}
                                    onSelect={onSelect}
                                    selectedData={dataSelected}
                                    setInitialAddress={setInitialAddress}
                                    sendData={sendValidation}
                                    index={index}
                                />)
                            }
                        }
                        )}

                        <Text className='w-100 text-start' level={2}> Commande traitée ({
                            (dataCdeLogisLiv && dataCdeLogisLiv.length > 0) && dataCdeLogisLiv.reduce((acc, item) => {
                                if(item.ValideIdPointRelais !== "") acc++
                                return acc
                            }, 0) || 0}) : </Text>
                        {(dataCdeLogisLiv && dataCdeLogisLiv.length > 0) && dataCdeLogisLiv.map((data, index) => {
                            if(data.ValideIdPointRelais !== ""){
                            return (<RenderCard
                                key={index}
                                data={data}
                                onSelect={onSelect}
                                setInitialAddress={setInitialAddress}
                                selectedData={dataSelected}
                                sendData={sendValidation}
                                index={index}
                            />)
                            }
                        }

                        )}


                        {(!dataCdeLogisLiv || dataCdeLogisLiv.length === 0) && <div>
                            <Title level={3}>Aucune commande trouvée</Title>
                        </div>}
                    </div>

                    <div className="w-50 d-flex flex-column h-100 sticky-top z-0">
                        {GoogleAutocomplete(inputRef)}
                        <GoogleMap id={"gmap"} mapContainerStyle={containerStyle} center={center} zoom={10}>
                            {dataSelected &&
                                <RenderMarkers data={dataSelected} updateRelais={updateDataWithNewRelay} parameter={parameter}  />}
                        </GoogleMap>

                    </div>
                </div>
            </div>
            }
    </>)
}


 function SearchPanel({filter, updateFilter, setCenter, initialFilter}){

    const [form] = Form.useForm()
    const {data, error, isLoading} = useSWR(`${INTRANET_URL}/mondial-relay-modif/param`, CredentialFetcher)

    return (<>
        <Form
            form={form}
            name="search"
            onValuesChange={debounce((changedValues, allValues) => {
                updateFilter(allValues)
            }, 500)}
            initialValues={filter}
        >
            <div className="d-flex flex-row w-100 justify-content-center">
                <ClientSearch
                    name={'cptClient'}
                    form={form}
                    defaultValue={filter.cptClient}
                    setClient={(client)=>updateFilter({cptClient: client})}
                    onReset={() => updateFilter({cptClient:null})}
                />
                <Form.Item
                    name="query"
                    className={'w-50 ms-2 me-2'}
                    allowClear
                    initialValue={filter.cdelogisliv ? filter.cdelogisliv : null}
                    onReset={() => {
                        updateFilter({query:null, cdelogisliv:null, idcdelogislot:null})
                    }}

                >
                    <Input

                        allowClear
                        placeholder="idCdeLogis, reférence Cde, libéllé commande, ..."
                    />
                </Form.Item>

                <Form.Item
                    name="idcdelogislot"
                    label="Lot"
                >
                    <Select
                        style={{ minWidth: 200 }}
                        showSearch
                        allowClear
                        onClear={() => updateFilter({idcdelogislot:null})}
                        placeholder="Sélectionner un lot"
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={data?.lot?.map((item) => ({label:`${item.IdCdeLogisLot}`, value: item.IdCdeLogisLot}))}
                    />
                </Form.Item>
            </div>

        </Form>
    </> )
}


const CopyButton = ({ address, index, toast }) => {
    useEffect(() => {
        const clipboard = new Clipboard(`.copy-btn-${index}`, {
            text: function() {
                return address;
            }
        });

        clipboard.on('success', function() {
            alert('Adresse copiée dans le presse-papier')
        });

        clipboard.on('error', function() {
           alert('Erreur lors de la copie')
        });

        return () => {
            clipboard.destroy();
        };
    }, [address, index]); // Dépendances pour assurer la réinitialisation correcte si nécessaire

    return <Button className={`copy-btn copy-btn-${index}`}>Recopier l'adresse</Button>;
};


export function RenderCard({data, onSelect, setInitialAddress, selectedData, sendData, index}) {
    const [loading, setLoading] = useState(false)

    const [open, close, modal] = useBasicModal(false, null,null,null, <></>)
    let distance = 0

    const toast = useContext(ToastContext)

    data.ref = React.createRef()
    if(data.ProposeLatitude !== " " && data.ProposeLongitude !== " " && (data.ProposeDistance === 0 || data.ProposeDistance === null)){
        data.ProposeDistance = calculDistance(data.OrigineLatitude, data.OrigineLongitude, data.ProposeLatitude, data.ProposeLongitude)
    }

    const [form] = Form.useForm()

    const onSubmit = (input) =>{
        setLoading(true)
        sendData({...data, ValideRemarque: input.remarque}, ()=>{
            setLoading(false)
            form.resetFields()
            close()
        })
    }

    const modalContent = (
        <div>
            <Text level={3}>Validation de la commande</Text>
            <p>La distance entre le point relais d'origine et le point relais : {Math.ceil(data.ProposeDistance)} km</p>
            <Form
                form={form}
                name="validate"
                onFinish={onSubmit}
            >
                    <Form.Item
                        name="remarque"
                        label="remarque"
                        rules={[{ required: true, message: 'Merci de justifier votre validation' },
                            {max: 100, message: 'La remarque ne doit pas dépasser 100 caractères'}
                        ]}
                    >
                        <TextArea rows={2} />
                    </Form.Item>
                <Button onClick={close}>Annuler</Button>
                <Button disabled={loading} loading={loading} type="primary" htmlType="submit">Valider</Button>
            </Form>
        </div>
    )


    const renderSelection = () => {
        if(data.ValideIdUser === 'Automatique') {
            return <Text>Validé automatiquement</Text>
        }else if(data.ValideIdUser !== "" && data.ValideIdUser !== 'user'){
            return <Text>Validé par {data.ValideIdUser}</Text>
        }
    }


    return (
        <Card
            hoverable
            ref={data.ref}
            key={data.IdCdeLogisLiv}
            className={`mb-2 p-1 ${data.IdCdeLogisLiv === selectedData?.IdCdeLogisLiv ? 'highlighted' : 'opacity-50'}`}
            onClick={() => {
                onSelect(data)
            }}
            //if data.validate is true, we add a green border
            style={data.ValideIdPointRelais !== "" ? {border: '2px solid green', opacity:0.4} : {}}
        >
            {modal(modalContent)}
            <div
                style={{textAlign: "left"}}
                className="d-flex flex-row justify-content-between align-items-center"
            >
                <Meta
                    className="align-right"
                    description={<>
                        <div className="d-flex flex-row justify-content-between align-items-center">

                            <h4 style={{display: 'inline-block'}}>{data.OrigineNom + '-' + data.OrigineCP + ' ' + data.OrigineVille}</h4>
                            <h1 style={
                                {display: 'inline-block',
                                marginRight: '10px',
                                fontSize:'50px',
                                opacity: data.IdCdeLogisLiv === selectedData?.IdCdeLogisLiv ? 1 : 0.2
                                }}>{(index + 1)}</h1>
                        </div>


                        <Descriptions column={1}>
                        <Descriptions.Item label="CptClient">{data.nomClient + '-' + (data.CptClient)}</Descriptions.Item>
                            <Descriptions.Item label="IdCdeLogis">{data.IdCdeLogis}</Descriptions.Item>
                            <Descriptions.Item label="Libéllé cde">{data.RefCde}</Descriptions.Item>
                            <Descriptions.Item label="Transporteur/service">{data.Transporteur+'-'+data.Service}</Descriptions.Item>
                        </Descriptions>
                        <div className="d-flex flex-row">
                            <div>
                                <Text>Origine : </Text>
                                <Descriptions
                                    column={1}
                                >
                                    <Descriptions.Item label="Id Point Relais"><Text copyable strong>{data.OrigineIdPointRelais}</Text></Descriptions.Item>
                                    <Descriptions.Item label="Nom">{data.OrigineNom}</Descriptions.Item>

                                    <Descriptions.Item label="Adresse">{data.OrigineAdresse}</Descriptions.Item>
                                    <Descriptions.Item label="Code Postal">{data.OrigineCP}</Descriptions.Item>
                                    <Descriptions.Item label="Ville">{data.OrigineVille}</Descriptions.Item>
                                    <Descriptions.Item label="Pays">{data.OrigineCodePays}</Descriptions.Item>
                                </Descriptions>
                                <CopyButton key={index} address={`${data.OrigineNom} ${data.OrigineAdresse} ${data.OrigineCP} ${data.OrigineVille} ${data.OrigineCodePays}`} index={index} toast={toast} />
                            </div>
                            {(data.ProposeIdPointRelais  && data.ProposeIdPointRelais.trim() !== "") ?
                                <div>
                                    <Text>

                                        {data.ProposeIdPointRelais!== ' ' && data.ValideIdUser !== "" ?
                                             renderSelection()
                                            :
                                            <Text color={'red'}>Suggéré :</Text>
                                        }
                                    </Text>
                                    <Descriptions
                                        column={1}
                                    >
                                        <Descriptions.Item label="Id Point Relais"><Text copyable strong>{data.ProposeIdPointRelais}</Text></Descriptions.Item>
                                        <Descriptions.Item label="Nom">{data.ProposeNom}</Descriptions.Item>
                                        <Descriptions.Item label="Adresse">{data.ProposeAdresse}</Descriptions.Item>
                                        <Descriptions.Item label="Code Postal">{data.ProposeCP}</Descriptions.Item>
                                        <Descriptions.Item label="Ville">{data.ProposeVille}</Descriptions.Item>
                                        <Descriptions.Item label="Pays">{data.ProposeCodePays}</Descriptions.Item>
                                        <Descriptions.Item  label="Distance">
                                            <Text>
                                                {data.ProposeDistance > data.RayonRecherche*1000 ?
                                                    <p style={{color: 'red'}}> {Math.ceil(data.ProposeDistance/1000)} km</p>
                                                :
                                                    <p style={{color: 'green'}}> {Math.ceil(data.ProposeDistance/1000)} km</p>
                                                }
                                            </Text>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </div>
                                :
                                <div>
                                    <Text color={'red'}>Suggestion: non trouvée</Text>
                                    <Descriptions column={1}></Descriptions>
                                </div>
                            }
                        </div>
                    </>}
                />

            </div>
            <Button disabled={(data.ProposeIdPointRelais?.trim() === '' || data.ValideIdPointRelais?.trim() !== "")} onClick={() => {
                //si la distance est supérieure à 10km, on ouvre une modal
                if(data.ProposeDistance > data.RayonRecherche*1000){
                    open()
                }else{
                    sendData({...data}, ()=>{
                        setLoading(false)
                        form.resetFields()
                        close()
                    })
                }
            }}>Valider</Button>
        </Card>
)
}
function useGoogleSearch(initialAddress){
    const [response, setResponse] = useState(null)
    const [autocomplete, setAutocomplete] = useState(null);
    const onLoad = useCallback((autocomplete) => { setAutocomplete(autocomplete); }, []);

    const onPlacesChanged = () => {
        if (autocomplete) {
            let place = autocomplete.getPlace();
            let addressComponents = place.address_components;
            let postalCode, countryIso;

            //s'il n'y a pas de composant d'adresse, on ne fait rien
            if (!addressComponents) return

            //on récupère le code postal et le pays
            for (let i = 0; i < addressComponents.length; i++) {
                if (addressComponents[i].types.includes('postal_code')) { postalCode = addressComponents[i].short_name; }
                if (addressComponents[i].types.includes('country')) { countryIso = addressComponents[i].short_name; }
            }

            setResponse({
                postalCode: postalCode,
                countryIso: countryIso,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            });
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };


    const GoogleAutocomplete = (ref) => {
        return (<div className="mt-2 mb-2">
                <Autocomplete  onLoad={onLoad} onPlaceChanged={onPlacesChanged}>
                    <Form.Item
                        initialValue={initialAddress}
                    >
                        <Input allowClear />
                    </Form.Item>

                </Autocomplete>
        </div>

        );
    };



    return [GoogleAutocomplete, response]

}
const RenderMarkers = ({ data, updateRelais, parameter }) => {
    let dataReady = data && data.pointsRelais && data.pointsRelais.length !== 0 ;
    const [hoveredItem, setHoveredItem] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    return dataReady ? (
        <>
          {Object.entries(data.pointsRelais).map(([key, item], index) => (
                <Marker
                    key={index}
                    animation={google.maps.Animation.DROP}
                    position={{
                        lat: sanitizeCoordinates(item.Latitude),
                        lng: sanitizeCoordinates(item.Longitude)
                    }}
                    icon={hoveredItem === item.Num ? blueDot :  greyDot}
                        onMouseOver={() => setHoveredItem(item.Num)}
                    onMouseOut={() => setHoveredItem(null)}
                    onClick={() => { // Add this block
                        selectedItem === item ? setSelectedItem(null) : setSelectedItem(item);
                    }}
                    zIndex={500}
                >
                    {selectedItem === item && ( // Add this block
                        <InfoWindow onCloseClick={() => setSelectedItem(null)}>
                            <div>
                                <Meta
                                    className="align-right"
                                    title={<> <Text className="p-1" >{item.LgAdr1}</Text>
                                        <Text strong>
                                            ID Point Relai :
                                            <Text copyable strong>{(item.Pays + ":" + item.Num)}</Text>
                                        </Text>
                                    </>}
                                    description={item.LgAdr3+" " + item.CP + " " + item.Ville}
                                />
                               {item.Informations_Dispo.Periode && renderDateTag(item)}
                                <Button hidden={data.ValideIdUser !== '' && data.ValideIdUser!=='user'} className="mt-2" onClick={() => updateRelais(item)}>Sélectionner ce point relais</Button>
                            </div>
                        </InfoWindow>
                    )}
                </Marker>
            ))}
            {/*//red marker for origine point relais*/}

            {data.OrigineLatitude && data.OrigineLongitude &&
                <Marker
                    animation={google.maps.Animation.DROP}
                    position={{
                        lat: sanitizeCoordinates(data.OrigineLatitude),
                        lng: sanitizeCoordinates(data.OrigineLongitude)
                    }}
                    icon={redDotChiliPepper}
                    onMouseOver={() => setHoveredItem(data.OrigineIdPointRelais)}
                    onMouseOut={() => setHoveredItem(null)}
                    zIndex={1000}
                >
                </Marker>
            }


            {/*//green marker for origine point relais*/}
            {data.ProposeIdPointRelais && data.ProposeIdPointRelais.trim() !== "" &&
            <Marker
                animation={google.maps.Animation.DROP}
                position={{
                    lat: sanitizeCoordinates(data.ProposeLatitude),
                    lng: sanitizeCoordinates(data.ProposeLongitude)
                }}
                icon={highlightedIcon}
                onMouseOver={() => setHoveredItem(data.ProposeIdPointRelais)}
                onMouseOut={() => setHoveredItem(null)}
                zIndex={1000}
            >
            </Marker>
            }

            {/*//center marker when google search is apply*/}
            {parameter.Latitude && parameter.Longitude &&
            <Marker
                animation={google.maps.Animation.DROP}
                position={{
                    lat: sanitizeCoordinates(parameter.Latitude),
                    lng: sanitizeCoordinates(parameter.Longitude)
                }}
                icon={blueDot}
                zIndex={1000}
            >
            </Marker>
            }

        </>
    ) : null;
}

export function sanitizeCoordinates(coord) {
   return typeof coord === 'string' ? parseFloat(coord.replace(',', '.')) : coord
}