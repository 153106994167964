// import {CloseOutlined, FileZipOutlined} from "@ant-design/icons";
// import {Button, Typography} from "antd";
// import React from "react";

// const {Text} = Typography;

// export default function FileListItemRender() {
//     return (originNode, file, currFileList, setFileList, setUploaded) => {
//         const size = file.size < 1000000000 ? (file.size / 1000000).toFixed(2) + ' Mb' : (file.size / 1000000000).toFixed(2) + ' Gb';
//         return (
//             <div className="d-flex justify-content-center align-items-center">
//                 <div className="d-flex align-items-center">
//                     <FileZipOutlined className="mr-2"/>
//                     <Text className="text-truncate">{file.name} - {size}</Text>
//                 </div>
//                 <div>
//                     <Button
//                         type="link"
//                         onClick={() => {
//                             setFileList(prev => prev.filter(f => f.uid !== file.uid));
//                             setUploaded(false);
//                         }}
//                     >
//                         <CloseOutlined style={{color: 'red'}}/>
//                     </Button>
//                 </div>
//             </div>
//         );
//     };
// }

import { CloseOutlined, FileZipOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import React from "react";

const { Text } = Typography;

export default function FileListItemRender(setFileList, setUploaded, setCsvData) {
    return (originNode, file, currFileList) => {
        const size = file.size < 1000000000 ? (file.size / 1000000).toFixed(2) + ' Mb' : (file.size / 1000000000).toFixed(2) + ' Gb';
        return (
            <div className="d-flex justify-content-center align-items-center">
                <div className="d-flex align-items-center">
                    <FileZipOutlined className="mr-2" />
                    <Text className="text-truncate">{file.name} - {size}</Text>
                </div>
                <div>
                    <Button
                        type="link"
                        onClick={() => {
                            setFileList(prev => prev.filter(f => f.uid !== file.uid));
                            setUploaded(false);
                            if (setCsvData) { // Ensure setCsvData is defined
                                setCsvData([]); // Reset CSV data
                            } else {
                                console.error('setCsvData is not defined');
                            }
                        }}
                    >
                        <CloseOutlined style={{ color: 'red' }} />
                    </Button>
                </div>
            </div>
        );
    };
}
