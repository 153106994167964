import {useState} from "react";
import axios from "axios";


export interface exportParamInterface {
    urlRequest: string,
    outputName: string,
    filter: any,
    errorCallback: () => void
}
export default function useDataExport({urlRequest,outputName, filter, errorCallback}:exportParamInterface){

    /**
     * send request to API to export data with filter
     * API will return a file
     * res.setHeader('Content-Disposition', 'attachment; filename=data.xlsx');
     * res.setHeader('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
     **/
    const [loadingExport, setLoadingExport] = useState(false);
    const handleDataExport = async (customFilter) => {
        try {
            setLoadingExport(true);
            const response = await axios.get(`${urlRequest}`, {
            // const response = await axios.get(`${BASE_URL}/data-export/request-spreadsheet${isAdmin ?'-admin':''}`, {
                withCredentials: true,
                params: filter ? filter: customFilter ,
                responseType: 'blob' // Change this to 'blob'
            });

            // Create a link element to trigger the download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            //name of file data_date.xlsx
            const date = new Date();
            const dateString = date.toISOString().split('T')[0];
            const fileName = `${outputName}_${dateString}.xlsx`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            // Clean up after download
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            setLoadingExport(false);
        } catch (error) {
            setLoadingExport(false);
            errorCallback(error);

        }
    }


    return [loadingExport,handleDataExport]

}