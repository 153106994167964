import {useState} from 'react';
import axios from 'axios';
import useAuth from "auth-sna";

export default function useUploadCsv(toast, t, setError) {
    const BASE_URL_API2 = process.env.REACT_APP_API_V2;
    const BASE_URL_API = process.env.REACT_APP_API_V1;
    const [uploadProgress, setUploadProgress] = useState(0);
    const [cancelUpload, setCancelUpload] = useState(null);
    const [loading, setLoading] = useState(false);
    const auth = useAuth();
    const [result, setResult] = useState(null);

    const [isAdmin, setIsAdmin] = useState(['ROLE_ADMIN', 'ROLE_STAFF'].includes(auth.role));

    const upload = async (data, supData, url) => {
        setUploadProgress(0)
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const user = auth.getUser();
        const uuid = `${supData.customer}${Date.now()}`

        let password = null;
        setCancelUpload(() => source.cancel);

        //get the credentials from api2
        password = await getApiPassword(password, supData.customer, isAdmin);

        // const chunkSize = 100;
        // const dataChunks = [];
        // for (let i = 0; i < data.length; i += chunkSize) {
        //     dataChunks.push(data.slice(i, i + chunkSize));
        // }

        let errorOccurred = false;

        try {
            setError(null);
            setLoading(true);
            let params = Object.assign({
                method: 'POST',
                params: {
                    CustomerAccount: supData.customer,
                    Password: password.ApiSnaPassword,
                    User: password.ApiSnaUser,
                    Login: user.Login,
                },
                data: data,
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            await axios(params, {
                cancelToken: source.token,
                onUploadProgress: function (progressEvent) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                }
            })
                .then(response => {
                    console.log("response", response);
                    setUploadProgress(100);
                    setLoading(false);
                    setResult({ message: response.data.message, status: response.status });
                })
                .catch(err => {
                    const message = err.response?.data?.message;
                    const error = err.response?.data;

                    if (axios.isCancel(err)) {
                        toast.error(t('uploadCancel'), 15);
                    } else {
                        toast.error(message, 15);
                    }
                    setUploadProgress(0);
                    setError(error);
                    setLoading(false);
                    console.error(err);
                    setResult(null);

                    errorOccurred = true; // Set the error flag
                });
        } catch (err) {
            setLoading(false);
            console.log(err);
            setError(err);
            setResult(null);

            errorOccurred = true; // Set the error flag
        }

        // for (let i = 0; i < dataChunks.length; i++) {
        //     if (errorOccurred) break; // Stop the loop if an error occurred
        //
        //     const chunk = dataChunks[i];
        //     console.log("chunk", chunk.length);
        //
        //     try {
        //         setError(null);
        //         setLoading(true);
        //         let params = Object.assign({
        //             method: 'POST',
        //             params: {
        //                 CustomerAccount: supData.customer,
        //                 Password: password.ApiSnaPassword,
        //                 User: password.ApiSnaUser,
        //                 Login: user.Login,
        //             },
        //             data: chunk,
        //             url: url,
        //
        //             headers: {
        //                 'Content-Type': 'application/json',
        //                 'Accept': 'application/json',
        //             }
        //         });
        //
        //         await axios(params, {
        //             cancelToken: source.token,
        //             onUploadProgress: function (progressEvent) {
        //                 const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        //                 setUploadProgress(percentCompleted);
        //             }
        //         })
        //             .then(response => {
        //                 console.log("response", response);
        //                 setUploadProgress(100);
        //                 setLoading(false);
        //                 setResult({ message: response.data.message, status: response.status });
        //             })
        //             .catch(err => {
        //                 const message = err.response?.data?.message;
        //                 const error = err.response?.data;
        //
        //                 if (axios.isCancel(err)) {
        //                     toast.error(t('uploadCancel'), 15);
        //                 } else {
        //                     toast.error(message, 15);
        //                 }
        //                 setUploadProgress(0);
        //                 setError(error);
        //                 setLoading(false);
        //                 console.error(err);
        //                 setResult(null);
        //
        //                 errorOccurred = true; // Set the error flag
        //             });
        //     } catch (err) {
        //         setLoading(false);
        //         console.log(err);
        //         setError("une erreur est survenue");
        //         setResult(null);
        //
        //         errorOccurred = true; // Set the error flag
        //     }
        // }


    }

    /**
     * get the password from api2
     * @param password
     * @param customer
     * @param isAdmin
     */
    async function getApiPassword(password, customer, isAdmin) {
        try {
            let params = Object.assign({
                withCredentials: true,
                method: 'GET',
                url: `${BASE_URL_API2}/user/api-password`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            await axios(params).then(response => {

                password = response.data;
            }).catch(err => {
                console.log(err)
            });

        } catch (err) {
            console.log(err)
            setError(err.response?.data?.message);
            setResult(null)
        }
        return password;
    }


    /**
     * cancel the upload
     * @param callback
     */
    const cancelUploadHandler = (callback) => {
        if (cancelUpload) {
            cancelUpload();
            setUploadProgress(0);
            callback();
        }
    }

    return [ upload, cancelUploadHandler, uploadProgress, cancelUpload, loading, result];
}