import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import useAuth from './useAuth';
import { ToastContext } from 'toast-provider';
import { Spin } from 'antd';

export default function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();
    let navigate = useNavigate();
    let toast = useContext(ToastContext);
    const [isLoading, setIsLoading] = useState(true); // Add a state to track loading

    const toastShown = useRef(false); // Utilisez un ref pour suivre si le toast a été affiché
    //check if web site is https

    useEffect(() => {
        // Check the cookie expiration date format timestamp
        if(location.protocol === 'https:'){
            if (auth && (auth.user === null || auth.user.exp * 1000 < Date.now())) {

                if (!toastShown.current){
                    auth.signOut();
                }
            }
        }
        setIsLoading(false); // Set isLoading to false after the checks are complete
    }, [auth.user]);

    if (isLoading) {
        return <Spin />; // Display a loading spinner while checking authentication
    }

    if (auth.user) {
        return children;
    } else {
        return <Navigate to="/signin" />; // Redirect to the login page if not authenticated
    }
}
