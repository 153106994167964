import React, {useContext, useEffect, useReducer} from "react";
import {Form} from "antd/lib";
import {debounce} from "lodash";
import {AutoComplete, Typography} from "antd";
import {useFetch} from "hooks-sna";
import Cookies from "js-cookie";
import {TranslateContext} from "translate-provider";

const { Text } = Typography;

const BASE_URL = process.env.REACT_APP_API_V2;

function AdminPanel({currentClient , updateFilterDasboard}) {

    const {t} = useContext(TranslateContext)
    const initialFilter= {
        query : null,
    }

    const [filter, updateFilter] = useReducer(
        (filter, updates) => ({ ...filter, ...updates }),
        initialFilter
    );

    let useFetchParams = {
        url: `${BASE_URL}/user/client`,
        filter: filter,
    };

    const [loading, error, data, hasMore, metadata, progress, fetchData]= useFetch(useFetchParams)


    const debouncedUpdateFilter = debounce((query) => {
        updateFilter({ query: query === '' ? null : query });
    }, 500);

    useEffect(() => {
        const localStorageClient = Cookies.get('clientQuery');
        if(localStorageClient){
            updateFilterDasboard({client: localStorageClient})
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [filter]);

    const onSubmit = (values) => {

    }

    const createOptions = (data) => {
        let options = [];
        data.map((item) => {
            options.push({
                value: item.CLICOD,
                label: item.CLICOD+ ' '+ item.CLINOM,
            });
        });
        return options;
    };


    const onSearch = (e) => {
        debouncedUpdateFilter(e)

    }


    const onClear = (e) => {
        updateFilterDasboard({client: null})
        Cookies.remove('clientQuery');
    }

    return (
        <div style={{color:'white'}} >
            <p>Admin Panel</p>
            <Form 
            ><Form.Item  className="col">
                <Text color="white"> {t("SélectionCompteclient")}  </Text>
                <AutoComplete
                    style={{ width: 300 }}
                    allowClear
                    //set default value
                    defaultValue={currentClient ? currentClient : null}

                    bordered={false}
                    onSearch={onSearch}
                    onClear={onClear}
                    onChange={(e)=>{
                        if (e === "") {
                            onClear()
                        } 
                    }}
                    onSelect={(e)=>{
                        if (e === "") {
                           onClear()
                        } else {


                            updateFilterDasboard({ client: e ,boutique: null });

                            Cookies.set('clientQuery', e);
                        }
                    }}
                    options={createOptions(data)}
                    placeholder="ID compte Client"
                    // filterOption={(inputValue, option) =>
                    //     option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    // }
                />
            </Form.Item>
            </Form>
        </div>
    )
}

export default AdminPanel