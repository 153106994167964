import React, {createContext, ReactNode, useEffect, useState} from 'react';
import i18n from './src/i18n'; // <-- This should point to your configured i18n instance.
import { useTranslation } from "react-i18next";
import {Dropdown, Space, Typography} from "antd";
import {MenuProps} from "antd";
import {DownOutlined} from "@ant-design/icons";

export let TranslateContext = createContext();
export default function TranslateProvider({ children }: { children: ReactNode }){
    const { t } = useTranslation(); // <-- Remove 'i18n' from here
    const [language, setLanguage] = useState(i18n.language);


    //get Language from local storage



    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng).then(r => console.log(r));
        setLanguage(lng);

    };


    const items: MenuProps['items'] = [
        {
            key: 'en',
            label: 'En',
        },
        {
            key: 'fr',
            label: 'Fr',
        },
        {
            key: 'ita',
            label: 'Ita',
        },
        {
            key: 'de',
            label: 'De',
        },
        {
            key: 'es',
            label: 'Es',
        }
    ];

    const onClick = ({ key }) => {
        changeLanguage(key);
    };
    // Function that returns a dropdown menu
    function getLanguageMenu(color: string) {

        // Return dropdown menu with current language selected
        return (

                <Dropdown

                    menu={{
                        items,
                        selectable: true,
                        defaultSelectedKeys: [language],
                        onClick,
                    }}
                >
                    <Typography.Link  style={{color:color}}>
                        <Space>
                            {t('language')}
                            <DownOutlined />
                        </Space>
                    </Typography.Link>
                </Dropdown>
        );
    }

    return (
        <TranslateContext.Provider value={{ language, changeLanguage, t, getLanguageMenu}}>
            {children}
        </TranslateContext.Provider>
    );
};