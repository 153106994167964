import React, { useState, useContext } from 'react';
import { Button, Typography, Input, Row, Col, Form } from 'antd';
import { ToastContext } from "toast-provider";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const BASE_URL = process.env.REACT_APP_API_V2;

export default function AskResetPassword() {
    const [identifiant, setIdentifiant] = useState('');
    const toast = useContext(ToastContext);
    const [form] = Form.useForm();
    const isButtonDisabled = () => !identifiant;
    let navigate = useNavigate();

    const handleResetPassword = async () => {
        try {
            const resetPasswordUrl = `${BASE_URL}/auth/reset-password`;
            const response = await fetch(resetPasswordUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: identifiant,
                }),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la réinitialisation du mot de passe');
            }

            const data = await response.json();
            toast.success(`Un mail a été envoyé pour réinitialiser le mot de passe`, 20);
            navigate("/")
        } catch (error) {
            console.error('Erreur lors de la réinitialisation du mot de passe :', error);
            toast.error('Erreur lors de la réinitialisation du mot de passe', 20);
        }
    };

    return (
        <div style={{ width: '100%' }}>
            <Title level={1} style={{ fontWeight: 400 }}>Mot de passe oublié</Title>
            <Row justify="center">
                <Col span={12} xs={24} sm={18} md={12} lg={8}>
                    <Form
                        form={form}
                        layout="vertical"
                        className="divResetPassword"
                        style={{ padding: 20, paddingBottom: 2, borderRadius: 15, background: "rgb(38, 44, 58)" }}
                        onFinish={handleResetPassword}
                    >
                        <Form.Item
                            label={<span className="divResetPassword">Identifiant</span>}
                            name="identifiant"
                            rules={[
                                { required: true, message: 'Veuillez entrer votre identifiant' },
                            ]}
                        >
                            <Input
                                className="textResetPassword"
                                placeholder="Identifiant"
                                value={identifiant}
                                onChange={(e) => setIdentifiant(e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                className="divResetPassword"
                                style={{ width: '50%' }}
                                htmlType="submit"
                                disabled={isButtonDisabled()}
                            >
                                Valider
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}
