import React, { useEffect, useReducer, useState } from 'react';
import { useFetch } from 'hooks-sna';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import TagOrder from 'logistic/src/component/TagOrder';
import { exportParamInterface, useDataExport } from 'sna-export';

// Base URL de l'API
const BASE_URL = process.env.REACT_APP_API_V2;

function ItemOrders({ id }) {
  const initialFilter = {};

  const [filter, updateFilter] = useReducer(
    (filter, updates) => ({ ...filter, ...updates }),
    initialFilter
  );

  const [statusFilters, setStatusFilters] = useState([]);
  const [loadingOrders, errorOrders, dataOrders, , , , fetchData] = useFetch({
    url: `${BASE_URL}/logistic/article-orders/${id}`,
  });

  const exportParams: exportParamInterface = {
    urlRequest: `${BASE_URL}/data-export/request-spreadsheet`,
    outputName: 'SNA-export-logistic',
    filter: filter,
    errorCallback: (error) => {
      toast.error('Error while exporting data');
    },
  };

  const [loadingExport, handleDataExport] = useDataExport(exportParams);

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    if (dataOrders && dataOrders.length > 0) {
      // Extraire les valeurs uniques pour le filtre
      const uniqueStatuses = [...new Set(
        dataOrders.map(item => item.CdeLogis.API_syntheseCde.syntheseCdeCommande.libelleStatut)
      )];

      // Transformer les valeurs pour les filtres
      const transformedStatuses = uniqueStatuses.map(status => {
        if (status === 'Expédiée') {
          return { text: 'Livrée', value: 'Expédiée' };
        }
        return { text: status || '-', value: status || '-' };
      });

      setStatusFilters(transformedStatuses);
    }
  }, [dataOrders]);



  const columns: ColumnsType = [
    {
      title: 'id Commande',
      dataIndex: ['CdeLogis', 'IdCdeLogis'],
      key: 'idCdeLogis',
    },
    {
      title: 'Date commande',
      dataIndex: ['CdeLogis', 'DateCdeClient'],
      key: 'date',
      render: (date) =>
        date !== ''
          ? new Date(date).toLocaleDateString('fr-FR', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          : '-',
    },
    {
      title: 'Libellé Cde',
      dataIndex: ['CdeLogis', 'Libell_Cde'],
      key: 'Libell_Cde',
      render: (qte) => <>{qte || '-'}</>,
    },
    {
      title: 'Référence Cde',
      dataIndex: ['CdeLogis', 'RefCde'],
      key: 'RefCde',
      render: (qte) => <>{qte || '-'}</>,
    },
    {
      title: 'Statut',
      dataIndex: ['CdeLogis', 'API_syntheseCde'],
      width: 100,
      key: 'StatutCde',
      filters: statusFilters,
      filteredValue: filter.statuts || null,
      onFilter: (value, record) =>
        record['CdeLogis']['API_syntheseCde']['syntheseCdeCommande']['libelleStatut'] === value,
      render: (data) => <TagOrder data={data} />,
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    if (filters.StatutCde) {
      updateFilter({
        statuts: filters.StatutCde,
      });
    } else {
      updateFilter({
        statuts: null,
      });
    }

    if (!['action'].includes(sorter.columnKey)) {
      updateFilter({
        sortField: sorter.order ? sorter.field : null,
        sortOrder: sorter.order ? sorter.order : null,
      });
    }
  };

  return (
    <Table
      pagination={{ pageSize: 8 }}
      size={'small'}
      columns={columns}
      dataSource={dataOrders}
      loading={loadingOrders}
      onChange={handleTableChange}
    />
  );
}

export default ItemOrders;
