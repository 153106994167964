import React, {memo, useContext, useEffect, useState} from 'react';
import {Menu, Typography} from "antd";
import {AppstoreOutlined, AuditOutlined, DropboxOutlined, ImportOutlined, LeftSquareOutlined, MailOutlined, QuestionCircleOutlined, RightSquareOutlined} from "@ant-design/icons";

import {Link, useNavigate} from "react-router-dom";
import {iconMapping} from "./IconMapping";
import useAuth from "auth-sna";
import {COLOR} from "theme-provider";
import {TranslateContext} from "translate-provider";

const BASE_URL = process.env.REACT_APP_API_V2;

//react collapse btn style
const collapseStyle:React.CSSProperties = {
    color: "white",
    fontSize: 25,
    textAlign: 'center',
    lineHeight: '50px',
    cursor: 'pointer',
    transition: 'all 0.3s',
    outline: 'none',
    opacity: 0.5,
};

const footerTextStyle:React.CSSProperties = {
    color: "white",
    fontSize: 12,
    fontWeight: 300,
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s',
    outline: 'none',
    opacity: 0.5,
};

const footerLinkStyle:React.CSSProperties = {
    color: "white",
    fontSize: 12,
    fontWeight: 300,
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s',
    outline: 'none',
    opacity: 0.5,
    textDecoration: 'none'
};






const navItems = [
    {
        key: 'Commande',
        icon: <AuditOutlined style={{fontSize:25}} />,
        label: 'Commande',
        disabled: true,
    },
    {
        key: 'Colis',
        icon:<DropboxOutlined style={{fontSize:25}} />,
        label: 'Colis',
        disabled: true,
    },
    {
        //GROUP
        label: 'Import',
        key: 'Import',
        icon: <ImportOutlined style={{fontSize:25}} />,
        children: [
            {
                label: <Link to={'product-import'}>Importer des produits</Link>,
                key: 'mail',
                icon: <MailOutlined />,
            },
            {
                label: ' voir les commandes importé',
                key: 'app',
                icon: <AppstoreOutlined />,
                disabled: true,
            }
        ],
    },
]

function AsideMenu({excludeNavItems = [] , logo , logoCollapsed}){
    const auth = useAuth();
    const [navItems, setNavItems] = useState([]);
    const navigate = useNavigate();

    const {t, getLanguageMenu} = useContext(TranslateContext);

    useEffect(()=>{

        async function fetchNavigation(){
            if(auth.user){
                const navigation = await auth.getNavigation({exclude:excludeNavItems});
                setNavItems(createMenu(navigation))
            }
        }

        if(auth.user){
            fetchNavigation()
        }
    },[auth])

    const { Text, Link } = Typography;
    const MenuStyle:React.CSSProperties ={
        backgroundColor: COLOR['MAIN_COLOR'],
    }

    function CollapsableLogo(collapse) {
        return <Link onClick={(e)=>{
            e.preventDefault()
            //navigate to default page http://localhost:3002/dashboard/
           navigate('/dashboard')
        }} className="image-container p-2 my-5 d-flex align-items-center justify-content-center">
            <img
                className={`image ${collapse ? 'active' : ''}`}
                width={50}
                src={logoCollapsed ? logoCollapsed : logo}
                alt=""
            />
            <img
                className={`image ${collapse ? '' : 'active'}`}
                width={170}
                src={logo}
                alt=""
            />
        </Link>;
    }

    const[collapse, setCollapse] = useState(false);

   const CollapseButton = () => {
        if(collapse){
            return <RightSquareOutlined
                className="trigger"
                onClick={() => setCollapse(!collapse)}
                style={collapseStyle} />
        }
        else{
            return <LeftSquareOutlined
                className="trigger"
                onClick={() => setCollapse(!collapse)}
                style={collapseStyle} />
        }
    }


    const createMenu = (navigation) => {
        let menu = [];
        let groups = {};

        navigation.map((item) => {
            const navItem = item.API_navigation;
            let iconName = navItem.link; // Fetch the iconName from database
            let IconComponent = iconMapping[iconName]; // Get the corresponding component from the mapping

            const nom = navItem.nameEn;

            if (navItem.ApiNavigationGroup === null) {
                menu.push({
                    key: navItem.nameEn,
                    icon: IconComponent,
                    label: <Link onClick={()=>{
                        if(!navItem.disable){
                            navigate(navItem.link)
                        }
                    }}>{t(nom)}</Link>,
                    disabled: navItem.disable,
                });
            } else {
                if (groups.hasOwnProperty(navItem.ApiNavigationGroup.id)) {
                    groups[navItem.ApiNavigationGroup.id].children.push({
                        key: navItem.nameEn,
                        icon: IconComponent,
                        label: <Link onClick={()=>{
                            if(!navItem.disable){
                                navigate(navItem.link)
                            }
                        }}>{t(nom)}</Link>,
                        disabled: navItem.disable
                    });
                } else {
                    groups[navItem.ApiNavigationGroup.id] = {
                        key: navItem.ApiNavigationGroup.nameEn,
                        icon: iconMapping[navItem.ApiNavigationGroup.nameEn], // Assuming the group has the same icon
                        label: navItem.ApiNavigationGroup.nameEn,
                        children: [{
                            key: navItem.nameEn,
                            icon: IconComponent,
                            label: <Link onClick={()=>{
                                if(!navItem.disable){
                                    navigate(navItem.link)
                                }
                            }}>{t(nom)}</Link>,
                            disabled: navItem.disable
                        }]
                    };
                }
            }
        });

        // Push groups into menu array
        for (let group in groups) {
            // If a group contains only one item, add it as an individual item
            if (groups[group].children.length === 1) {
                menu.push(groups[group].children[0]);
            } else {
                menu.push(groups[group]);
            }
        }

        return menu;
    }

    return(
        <aside className={`aside bg-corail ${collapse ? 'col-1' : 'col-2'} h-100 flex-column justify-content-between align-items-center d-none d-xl-flex`}>
           <header>
               <div style={{textAlign:"right", marginTop:10}}>
                   {CollapseButton(collapse, setCollapse)}
               </div>


               {/*<img className="mt-5 mb-5" width={150}*/}
               {/*     src={SigleSNA}*/}
               {/*     alt=""*/}
               {/*/>*/}

               {CollapsableLogo(collapse)}
               <Menu
                   theme="dark"
                   style={MenuStyle}
                   mode="inline"
                   defaultSelectedKeys={['1']}
                   items={navItems}
                   inlineCollapsed={collapse}
               />
           </header>

            <footer className="d-flex flex-column has-text-align-center mb-2">
                <Link className="mb-3" style={footerLinkStyle} type="secondary" href="/cgu"> <QuestionCircleOutlined style={{ fontSize: '30px', color: 'white' }} /></Link>

                {collapse ? "" : <React.Fragment>
                    <Link style={footerLinkStyle} type="secondary" href="/cgu">CGU</Link>
                    <Link style={footerLinkStyle} type="secondary" href="/politique-de-confidentialite">Politique de confidentialité</Link>
                </React.Fragment>  }

                <Text style={footerTextStyle} type="secondary">{collapse ? "" : "Copyright"}© SNA-GZ {new Date().getFullYear()} {process.env.REACT_APP_VERSION || '1.0.0'}</Text>
            </footer>

            {/*Copyright © SNA-GZ {new Date().getFullYear()} - {process.env.REACT_APP_VERSION || '1.0.0'}*/}

        </aside>
    )
}
export default memo(AsideMenu);