import React from 'react'
import { IoShirtSharp } from 'react-icons/io5'
import { BsFillVinylFill,BsFillTagFill } from 'react-icons/bs'
import { BiSolidSticker,BiSolidBookAlt , BiSolidShoppingBag} from 'react-icons/bi'
import { PiFilmSlateFill, PiHoodieBold,PiPantsFill } from 'react-icons/pi'
import { ImMug } from 'react-icons/im'
import { GiBilledCap } from 'react-icons/gi'




const ProductIconMapping = {
    "Sweat-shirt Client": PiHoodieBold,
    "CD Client": BsFillVinylFill,
    "Vinyle Client": BsFillVinylFill,
    "Casquette Client": GiBilledCap,
    "Sticker Client": BiSolidSticker,
    "Jaquette DVD Client": PiFilmSlateFill,
    "Livret Client": BiSolidBookAlt,
    "Vêtement Client": IoShirtSharp,
    "T-shirt Client": IoShirtSharp,
    "Sac Client": BiSolidShoppingBag,
    "Pantalon Client": PiPantsFill,
    "Mug Client": ImMug,
};

const DefaultIcon = BsFillTagFill;


export function DisplayIconProduct({ iconProps, product }) {
    const IconComponent = ProductIconMapping[product] || DefaultIcon;

    return (
        <div>
            {IconComponent && <IconComponent {...iconProps} />}
        </div>
    );
}