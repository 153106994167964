import React, {Fragment, memo, useCallback, useContext, useEffect, useReducer, useRef, useState} from "react";
import type {TabsProps} from 'antd';
import {Badge, Button, Space, Tabs, Typography} from "antd";
import {DownloadOutlined, ReloadOutlined} from "@ant-design/icons";
import useAuth from "auth-sna";
import {ToastContext} from "toast-provider";
import SearchForm from "./src/component/SearchForm";
import ModalOrder from "./src/component/ModalOrder";
import OrderTable from "./src/component/table/OrderTable";
import {FilterContext, useFetch} from "hooks-sna";
import {isEqual} from 'lodash';

import ProgressBarLoader from "sna-components/src/ProgressBarLoader";
import {exportParamInterface, useDataExport} from 'sna-export';
import {TranslateContext} from "translate-provider";
import {webSocketContext} from "websocket-provider";
import {AdminPanel} from "sna-components";
import Cookies from "js-cookie";

const { Text } = Typography;

const BASE_URL = process.env.REACT_APP_API_V2;


//interface for logistic page "awaiting, shipped, received, cancelled, under_modification
export type OrderMode = "AWAITING" | "SHIPPED" | "RECEIVED" | "CANCELLED";

export interface OrderTableProps {
    data: any;  // Define the exact type if you can
    filter: any;  // Define the exact type if you can
    updateFilter: any;  // Define the exact type if you can
    openModal: any;  // Define the exact type if you can
    onPageChange: any;  // Define the exact type if you can
    pageSize: number;
    total: number;
    loading: boolean;
    page: number;
    activeTab: OrderMode;
}

function Logistic() {

    const auth = useAuth();
    const Context = useContext(FilterContext);
    const toast = useContext(ToastContext);
    const { t } = useContext(TranslateContext);
    const { updateURL } = useContext(FilterContext);


    /**
     * State for logistic page
     */
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(17);
    const [id, setId] = useState(null); // id of order to show in modal
    const [showModal, setShowModal] = useState(false);
    const [activeTab, setActiveTab] = useState(
        localStorage.getItem("defaultTab") || "AWAITING"
    );
    const tabkeys: OrderMode[] = ["AWAITING", "SHIPPED", "RECEIVED", "CANCELLED"]
    const [isAdmin, setIsAdmin] = useState(['ROLE_ADMIN', 'ROLE_STAFF'].includes(auth.role));
    const createLogisticURL = logisticURL();
    const [socket, setSocket] = useState(null);

    const ws = useContext(webSocketContext);



    useEffect(() => {
        if (ws && ws.socket) {
            setSocket(ws.socket);
        }
    }, []);


    const [url, setUrl] = useState(
        createLogisticURL("client-awaiting-orders", isAdmin)
    );


    const initialFilter = {
        query: Context.getQueryParam('query', null),
        transporteur: Context.getArrayQueryParam('transporteur').filter(Boolean),
        service: Context.getArrayQueryParam('service').filter(Boolean),
        city: Context.getQueryParam('city', null),
        country: Context.getArrayQueryParam('country').filter(Boolean),
        client: Cookies.get('clientQuery') || Context.getQueryParam('client', null),
        orderDateBegin: Context.getQueryParam('orderDateBegin', null),
        orderDateEnd: Context.getQueryParam('orderDateEnd', null),
        sortField: Context.getQueryParam('sortField', null),
        sortOrder: Context.getQueryParam('sortOrder', null),
        subStatutCde: Context.getArrayQueryParam('subStatutCde').filter(Boolean),
        boutique: Context.getArrayQueryParam('boutique').filter(Boolean),
    };


    // const initialFilter = {
    //     query: null,
    //     transporteur: [],
    //     service: [],
    //     city: null,
    //     country: [],
    //     client: Cookies.get('clientQuery') || null,
    //     orderDateBegin: null,
    //     orderDateEnd: null,
    //     sortField: null,
    //     sortOrder: null,
    //     subStatutCde: null,
    //     boutique: getQueryParam('boutique',[]).split(','),
    // };

    const tabMapping = {
        AWAITING: { color: "gray", text: t('currentOrder') },
        SHIPPED: { color: "#CBA730", text: t('shipped') },
        RECEIVED: { color: "green", text: t('delivered') },
        CANCELLED: { color: "#EE7F59", text: t('canceled') },
    }

    /**
     * Search filter
     */

    const [filter, updateFilter] = useReducer(
        (filter, updates) => ({ ...filter, ...updates }),
        initialFilter
    );


    const prevFilterRef = useRef();

    const [countLoading, countError, countData, , , , fetchDataCount] = useFetch({ url: createLogisticURL("count", isAdmin), filter: filter });
    const [loading, error, data, , , progress, fetchData] = useFetch({ url, filter, page, pageSize });


    const [loadingFilterBoutique, errorFilterBoutique, dataFilterBoutique,,,progressFilterBoutique, fetchFilterBoutique] = useFetch({ url: `${BASE_URL}/logistic/shop-list`, filter:filter });

    useEffect(() => {
        fetchData();
        fetchDataCount();
        fetchFilterBoutique();
        if (!isEqual(prevFilterRef.current, filter)) {
            setPage(0);
        }
        prevFilterRef.current = filter;
    }, [url, filter, page, pageSize]);


    useEffect(() => {
        // Mise à jour de l'URL avec les valeurs du filtre
        updateURL(filter);



    }, [filter, updateURL]);


    useEffect(() => {
        if (ws && ws.socket) {
            ws.socket.on("reload", async (response) => {
                if (response.success) {

                    fetchData();
                    fetchDataCount();
                }
            });
        }
    }, []);


    const handlePageChange = (newPage, newPageSize) => {
        setPage(newPage);
        setPageSize(newPageSize);
    };

    const onChangeTab = (key: OrderMode) => {
        setActiveTab(key);
        //reinit all filter
        // updateFilter(initialFilter);
        setPage(0);
        setUrl(createLogisticURL(`client-${key.toLowerCase()}-orders`, isAdmin));
    }

    const showModalOrder = useCallback((idModal) => {
        setId(idModal);
        setShowModal(true);
    }, []);


    const items: TabsProps['items'] =
        Object.keys(tabMapping).map((tabKey: OrderMode) => ({
            key: tabKey,
            label: (
                <Badge
                    count={countData[tabKey.toLowerCase()]}
                    offset={[10, -10]}
                    overflowCount={99999}
                    color={tabMapping[tabKey].color}
                >
                    <Text type={"secondary"}>{tabMapping[tabKey].text}</Text>
                </Badge>
            ),
            children: (
                <>
                    <ProgressBarLoader loading={loading} progress={progress} />
                    <OrderTable
                        filter={filter}
                        updateFilter={updateFilter}
                        data={data}
                        error={error}
                        loading={loading}
                        openModal={showModalOrder}
                        onPageChange={handlePageChange}
                        pageSize={pageSize}
                        total={countData[tabKey.toLowerCase()]}
                        page={page}
                        activeTab={activeTab}
                    />
                </>

            )
        }))
    /**
     * Generate Url for logistic
     * @return {function(*, *): string}
     */
    function logisticURL() {
        return (endpoint, isAdmin) => {
            const adminSuffix = isAdmin ? "-admin" : "";
            return `${BASE_URL}/logistic/${endpoint}${adminSuffix}/`;
        };
    }

    let exportParams: exportParamInterface = {

        urlRequest: `${BASE_URL}/data-export/request-spreadsheet${isAdmin ? '-admin' : ''}`,
        outputName: 'SNA-export-logistic',
        filter: filter,
        errorCallback: (error) => {
            toast.error("Error while exporting data");
        },
    }

    const [loadingExport, handleDataExport] = useDataExport(exportParams);



    // In the return part of the Logistic component, replace Bootstrap's Tabs with antd's Tabs
    return (
        <div>
            <div className="d-xl-none">
                <Text>Only available on desktop screen</Text>
            </div>
            <div className='d-none d-xl-block'>


                {isAdmin ? <AdminPanel currentClient={filter.client} updateFilterDasboard={updateFilter} /> : null}

                <SearchForm filter={filter} updateFilter={updateFilter} />

                {showModal && <ModalOrder show={showModal} setShow={setShowModal} id={id} socket={socket} onHide={() => setShowModal(false)} />}

                <Tabs
                    tabBarExtraContent={<Space>
                        <Button onClick={() => {
                            fetchData()
                            fetchDataCount()
                        }} > <> <ReloadOutlined /> Refresh </> </Button>
                        <Button loading={loadingExport} onClick={handleDataExport} > {loadingExport ? "Processing Data...please wait" : <><DownloadOutlined /> Export Data</>}</Button>

                    </Space>

                    }
                    activeKey={activeTab}
                    items={items}
                    onTabClick={onChangeTab}
                    tabPosition="top"
                    animated />

            </div>
        </div>
    );
}

export default memo(Logistic);