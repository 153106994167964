import React, {memo, useContext, useEffect, useState} from 'react';
import {Button, Col, Form, InputNumber, Modal, Row, Slider, Space} from "antd";
import {useForm} from "antd/lib/form/Form";
import {TranslateContext} from "translate-provider";
import axios from "axios";
import {ToastContext} from "toast-provider";

const BASE_URL = process.env.REACT_APP_API_V2;
function ModalLimitStock({ show, setShow, data }) {
    const [form] = useForm();
    const toast = useContext(ToastContext)

    const [seuil, setSeuil] = useState(() => {
        if (data && data.seuil !== undefined) {
            return data.seuil;
        } else {
            return 0;
        }
    });

    const onChange = (newValue) => {
        setSeuil(newValue);
    };

    useEffect(() => {
        form.setFieldsValue({ seuil: seuil });
    }, [seuil, form]);

    const url = `${BASE_URL}/alerte-mail/create`;
    const { t } = useContext(TranslateContext);


    console.log("data", data)

    const CustomFooter = () => (
        <div>
            <Button onClick={() => { setShow(false) }}>Close</Button>
        </div>
    );




    const modalTitle = () => {
        return <Space>{t("Configuration de la limite de stock")}</Space>;
    };

    const updateSeuil = (idEltClient, seuil) => {
        let params = {
            withCredentials: true,
            method: 'POST',
            url: url,
            data: {
                idEltClient: `${data.idEltClient}`,
                seuil: `${seuil}`,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        };

        axios(params)
            .then((response) => {
                toast.success("Le seuil a été mis à jour avec succès")
                setShow(false);
                // fetchData()
            })
            .catch((error) => {
                console.log('Erreur lors de la mise à jour du seuil', error);
            });
    };

    const handleOk = () => {
        const values = form.getFieldsValue();
        console.log("Valeurs du formulaire:", values);
        form.setFieldsValue({ seuil: values.seuil });
        updateSeuil(data.idEltClient, values.seuil);
        setShow(false);
    };


    const disableAlertButton = () => {
        const fetchDisableAlert = async () => {
            let params = {
                withCredentials: true,
                method: 'POST',
                url: `${BASE_URL}/alerte-mail/disable`,
                params: {
                    id: data.id
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            };
            await axios(params)
                .then((response) => {
                    setShow(false);
                    toast.success("L'alerte a été désactivée avec succès")
                    // fetchData()
                })
                .catch((error) => {
                    console.log('Erreur lors de la mise à jour du seuil', error);
                });
        };
        return (
            <div>
                <Button onClick={() => { fetchDisableAlert() }}>Désactiver l'alerte</Button>
            </div>
        )
    }

    return (
        <Modal
            mask={false}
            destroyOnClose={true}
            maskClosable={false}
            footer={<CustomFooter />}
            title={modalTitle()}
            centered
            onCancel={() => setShow(false)}
            open={show}
            width={400}
        >
            <Form form={form} className='modalLimite'>
                <Space>
                    <Form.Item name={"seuil"} initialValue={data.seuil}>
                        <Row>
                            <Col span={16}>
                                <Slider
                                    min={0}
                                    max={1000}
                                    onChange={onChange}
                                    value={typeof seuil === 'number' ? seuil : 0}
                                />
                            </Col>
                            <Col span={4}>
                                <InputNumber
                                    min={0}
                                    max={1000}
                                    style={{ margin: '0 16px' }}
                                    value={seuil}
                                    onChange={onChange}
                                />
                            </Col>
                            <Col span={4}>
                                <Button style={{ marginLeft: 80 }} onClick={handleOk}>
                                    Ok
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Space>
            </Form>
            <div className="w-100 d-flex justify-content-center">
                {data.id && disableAlertButton()}
            </div>

        </Modal>
    );
}

export default memo(ModalLimitStock);
