import React, {memo, useContext, useEffect, useImperativeHandle, useReducer, useRef, useState} from 'react';
import {ColumnsType} from "antd/es/table";
import {Button, Input, Space, Table, Tag, Typography} from "antd";
import {SearchOutlined} from '@ant-design/icons';
import useAuth from "auth-sna";
import useFetch from "hooks-sna/src/useFetch";
import axios from "axios";
import {ImageCustom} from "sna-components";
import {TranslateContext} from "translate-provider";

const { Title } = Typography;

const BASE_URL_API = process.env.REACT_APP_API_V1;
const API2 = process.env.REACT_APP_API_V2;
function ItemTable({ refFetchData }) {
    const user = useAuth();
    const userData = user.getUser();
    const { t } = useContext(TranslateContext);
    const [loadingPws, errorpwd, dataPwd, , , , fetchDataPwd] = useFetch({ url: `${API2}/user/api-password`, method: 'GET' });

    useEffect(() => {
        if (dataPwd.length === 0) {
            fetchDataPwd();
        }
    }, []);

    const initialFilter = {
        Login: userData.Login,
        CustomerAccount: userData?.Acc_sWebClient.CptClient,
        User: null,
        Password: null,
        Admin: user.getRole() === 'ROLE_ADMIN',
        currentPage: 1,
        pageSize: 10,
        search: "",
    };

    const [filter, updateFilter] = useReducer(
        (filter, updates) => ({ ...filter, ...updates }),
        initialFilter
    );

    const [loadingItem, errorItem, dataItem, , , , fetchData] =useFetch({
        url: BASE_URL_API + '/item',
        method: 'GET',
        filter: { ...filter }
    });



    useImperativeHandle(refFetchData, () => ({
        fetchData,
    }));

    useEffect(() => {
        if (dataPwd !== []) updateFilter({ User: dataPwd?.ApiSnaUser, Password: dataPwd?.ApiSnaPassword });
    }, [dataPwd]);

    //fetchData when filter change
    useEffect(() => {
        if (filter.User && filter.Password && dataItem !== []) fetchData();
    }, [filter]);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        filter.currentPage = 1
        setSearchedColumn(dataIndex);
        updateFilter({
            search: searchText,
        });
    };



    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("")
        updateFilter({
            search: "",
        });
        setSearchedColumn('');
    };

    // Fonction pour obtenir les propriétés de recherche pour la colonne Compte client (CptClient et CLINOM)
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder='Recherche'
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{ width: 90 }}
                        disabled={!searchText}
                    >
                        Valider
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => {
            const clientInfo = `${record.CptClient} - ${record.CLINOM}`;
            const lowerClientInfo = clientInfo.toLowerCase();
            const lowerValue = value.toLowerCase();

            // Vérifier si la valeur de recherche n'est pas vide avant d'utiliser toLowerCase()
            return lowerClientInfo.includes(lowerValue);
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible && searchInput.current) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) => {
            const clientInfo = `${record.CptClient} - ${record.CLINOM}`;
            const searchTextLower = (filter.search || '').toLowerCase();
            return searchedColumn === dataIndex || !searchedColumn ? (clientInfo.toLowerCase().includes(searchTextLower) ? clientInfo : '-') : text;
        },
    });

    const columns: ColumnsType = [
        {
            title: t('clientAccount'),
            key: 'CompteClient',
            render: (text) => <>{text.CptClient + ' - ' + text.CLINOM || '-'}</>,
            ...getColumnSearchProps('CompteClient'),
        },
        {
            title: t('importDate'),
            dataIndex: 'DateImport',
            key: 'DateImport',
            sorter: (a, b) => new Date(a.DateImport) - new Date(b.DateImport),
            sortDirections: ['descend', 'ascend'],
            render: (date) => <>
                {
                    date !== '1900-01-01T00:00:00.000Z'
                        ? new Date(date).toLocaleDateString('fr-FR', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                        })
                        : '-'
                }
            </>,
            onFilter: (value, record) => {
                const recordDate = new Date(record.DateImport).setHours(0, 0, 0, 0);
                const filterDate = new Date(value).setHours(0, 0, 0, 0);

                return recordDate === filterDate;
            },
        },
        {
            title: t('importBy'),
            key: 'imported',
            render: (text) => <>{text.user || '-'}</>,
        },
        {
            title: t('itemNumber'),
            dataIndex: 'nbArticle',
            key: 'nbArticle',
            render: (text) => <>{text || '-'}</>,
        },
        {
            title: t('status'),
            dataIndex: 'process',
            key: 'procress',
            render: (data) => {



                if (data?.error) {
                    const message = `erreur sur ${data.nbError} article(s)`;
                    //retour tag with nbError
                    return <Tag color="red">{message}</Tag>
                } else if (data?.success) {
                    return <Tag color="green">{t('completed')}</Tag>
                } else {
                    return <Tag color="blue">{t('inProcess')}</Tag>
                }
            }
        },
    ];

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [detailData, setDetailData] = useState({});
    const [isLoadingData, setLoadingData] = useState(false);

    const handleExpand = async (expanded, record) => {
        const newExpandedRowKeys = [...expandedRowKeys];
        const rowIndex = newExpandedRowKeys.indexOf(record.uuid);
        if (expanded) {
            try {
                if (!detailData[record.uuid] && !isLoadingData) {
                    setLoadingData(true);
                    //fetch with uuid and user and password from api-password
                    const response = await axios.get(`${BASE_URL_API}/item/${record.uuid}`, {
                        params: {
                            CustomerAccount: filter.CustomerAccount,
                            User: filter.User,
                            Password: filter.Password,
                            Login: filter.Login,
                        },
                    });


                    setDetailData(prevState => ({
                        ...prevState,
                        [record.uuid]: response.data,
                    }));
                    setLoadingData(false);
                }
            } catch (e) {

                setLoadingData(false);
            }
            if (rowIndex === -1) {
                newExpandedRowKeys.push(record.uuid);
            }
        } else {
            if (rowIndex !== -1) {
                newExpandedRowKeys.splice(rowIndex, 1);
            }
        }
        setExpandedRowKeys(newExpandedRowKeys);
    };

    return (
        <>
            <Title level={4} underline={true} className="text-start">{t('lastImport')}</Title>
            <Table
                rowKey={record => record.uuid}
                loading={loadingItem}
                pagination={{
                    total: dataItem.totalPages * dataItem.pageSize,
                    pageSize: dataItem.pageSize,
                    current: filter.currentPage,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => {
                        updateFilter({ currentPage: page, pageSize: pageSize });
                    },
                }}
                size={'small'}
                expandable={{
                    expandedRowRender: (record) => {
                        return (
                            <>
                                <Table
                                    rowKey={record => record.IdLigneImport}
                                    size={'small'}
                                    pagination={false}
                                    dataSource={detailData[record.uuid] || []}
                                    columns={[
                                        {
                                            title: 'image produit',
                                            dataIndex: 'LienURLImage',
                                            key: 'LienURLImage',
                                            render: (src) => <ImageCustom src={src} width={'100px'} />,
                                        }, {
                                            title: 'Type produit',
                                            dataIndex: 'TypeProduit',
                                            key: 'Type produit',
                                            render: (text) => <>{text || '-'}</>,
                                        }, {
                                            title: 'Code produit',
                                            dataIndex: 'RefProduit',
                                            key: 'refProduit',
                                        }, {
                                            title: 'Nom produit',

                                            key: 'titre',
                                            render: (text) => <>{text.Titre + (text.Artiste && ' -' + text.Artiste)}</>,
                                        },
                                        {
                                            title: 'etat',
                                            dataIndex: '',
                                            key: 'procress',
                                            render: (data) => {
                                                if (data.ImportTrait_) {
                                                    if (data.ImportTrait_Libell_ErreurClient !== '') {
                                                        return <Tag color="red">{data.ImportTrait_Libell_ErreurClient}</Tag>
                                                    } else {
                                                        return <Tag color="green">Traité</Tag>
                                                    }
                                                } else {
                                                    return <Tag color="blue">En cour de traitement</Tag> //Vous pouvez changer cette partie selon vos besoins
                                                }
                                            }
                                        },

                                        {
                                            title: 'Action',
                                            dataIndex: 'IdCdeLogis',
                                            width: 100,
                                            key: 'action',

                                            render: (text) => <a onClick={
                                                (event) => {
                                                    event.preventDefault();
                                                }
                                            }>Plus d'infos</a>,
                                        },
                                    ]}
                                />
                            </>
                        )
                    },
                    expandedRowKeys: expandedRowKeys,
                    onExpand: handleExpand,
                    rowExpandable: record => record.length !== 0 && Array.isArray(dataItem.data),
                }}
                columns={columns}
                dataSource={Array.isArray(dataItem.data) ? dataItem.data : []}
            />
        </>
    )
}

export default memo(ItemTable);