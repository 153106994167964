import React, {createContext, ReactNode, useContext, useEffect} from "react";
import {io} from "socket.io-client";
import useAuth from "auth-sna";
import {useNavigate} from "react-router-dom";
import {ToastContext} from "toast-provider";
import Cookies from 'js-cookie';


const REACT_APP_API_V2 = process.env.REACT_APP_API_V2;

export let webSocketContext = createContext(null);

export function WebSocketProvider({ children }: { children: ReactNode }) {
    const [socket, setSocket] = React.useState(null);
    const [currentWebSocketId, setCurrentWebSocketId] = React.useState(null);
    const auth = useAuth();
    const toast = useContext(ToastContext);
    const navigate = useNavigate();
    const userId = auth.login

    useEffect(() => {
        const existingSocketId = Cookies.get(`wsId_${userId}`);
        const isDev = process.env.NODE_ENV === 'development';
        if (existingSocketId) {
            return;
        }


        const socket = io(isDev ? 'http://localhost:446' : `${REACT_APP_API_V2}/`, {

            withCredentials: true,
            transports: ["websocket"],
        });

        socket.on("connect", () => {
            setCurrentWebSocketId(socket.id);

            //send data to server

            Cookies.set(`wsId_${userId}`, socket.id);

        });


        socket.on("UnauthorizedConnection", () => {
            auth.signOut(() => {
                toast.error("Vous êtes déjà connecté sur un autre appareil", 15);
                navigate("/login");
            });
        });

        setSocket(socket);

        return () => {
            if (socket) {
                socket.disconnect();
                Cookies.remove(`wsId_${userId}`);
            }
        };

    }, [auth, navigate, toast]);


    /**
    * Fonction pour récupérer l'ID de la connexion WebSocket courante
    * @returns {string} L'ID de la connexion WebSocket courante
    */
    const getId = () => {
        return currentWebSocketId;
    };

    /**
    * Fonction pour verrouiller une commande
    * @param {string} id - L'identifiant de la commande
    * @param {string} user - L'utilisateur verrouillant la commande
    */
    const lockOrder = (id, user) => {
        if (socket) {
            socket.emit("lockOrder", { order: id, client: user });
        }
    };

    /**
    * Fonction pour déverrouiller une commande
    * @param {string} id - L'identifiant de la commande
    * @param {string} user - L'utilisateur déverrouillant la commande
    */
    const unlockOrder = (id, user) => {
        if (socket) {
            socket.emit("unlockOrder", { order: id, client: user });
        }
    };

    let value = { socket, currentWebSocketId, getId, lockOrder, unlockOrder };

    return (
        <webSocketContext.Provider value={value}>
            {children}
        </webSocketContext.Provider>
    );
}
