import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Typography, Input, Row, Col, Form, Checkbox } from 'antd';
import { ToastContext } from "toast-provider";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const BASE_URL = process.env.REACT_APP_API_V2;

export default function ResetPassword() {
    const { token } = useParams();
    let navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordCriteria, setPasswordCriteria] = useState({
        length: false,
        uppercase: false,
        number: false,
        specialChar: false,
    });
    const [form] = Form.useForm();
    const toast = useContext(ToastContext);

    // Fonction pour vérifier les critères du mot de passe
    const checkPasswordCriteria = (password) => {
        setPasswordCriteria({
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            number: /[0-9]/.test(password),
            specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        });
    };

    // Mettre à jour les critères à chaque changement de mot de passe
    useEffect(() => {
        checkPasswordCriteria(newPassword);
    }, [newPassword]);

    // Fonction pour vérifier si le bouton doit être désactivé
    const isButtonDisabled = () => {
        return !(
            newPassword &&
            confirmPassword &&
            newPassword === confirmPassword &&
            Object.values(passwordCriteria).every(Boolean)
        );
    };

    const handleResetPassword = async () => {
        if (newPassword !== confirmPassword) {
            toast.error('Les mots de passe ne correspondent pas', 20);
            return;
        }

        try {
            await form.validateFields();

            const resetPasswordUrl = `${BASE_URL}/auth/new-password`;
            const response = await fetch(resetPasswordUrl, {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password: newPassword, token }), // Inclure le token dans la requête
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData.message || 'Erreur lors de la réinitialisation du mot de passe', 20);
                return;
            }

            toast.success('Le mot de passe a été réinitialisé avec succès', 20);
            navigate('/dashboard')
        } catch (error) {
            console.error('Erreur lors de la réinitialisation du mot de passe :', error);
            toast.error('Erreur lors de la réinitialisation du mot de passe', 20);
        }
    };

    return (
        <div style={{ width: '100%' }}>
            <Title level={1} style={{fontWeight: 400}}>Réinitialiser le mot de passe</Title>

            <Row justify="center">
                <Col span={12} xs={24} sm={18} md={12} lg={8} >
                    <Form form={form} layout="vertical" className="divResetPassword" style={{ padding: 20, borderRadius: 15, background: "rgb(38, 44, 58)" }}>
                        <Form.Item
                            label={<span className="divResetPassword">Nouveau mot de passe</span>}
                            name="newPassword"
                            rules={[
                                { required: true, message: 'Veuillez entrer votre nouveau mot de passe' },
                            ]}
                        >
                            <Input.Password
                                className="textResetPassword"
                                placeholder="Nouveau mot de passe"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item
                            label={<span className="divResetPassword">Confirmer le nouveau mot de passe</span>}
                            name="confirmPassword"
                            rules={[
                                { required: true, message: 'Veuillez confirmer votre nouveau mot de passe' },
                            ]}
                        >
                            <Input.Password
                                className="textResetPassword"
                                placeholder="Confirmer le nouveau mot de passe"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </Form.Item>

                        <div style={{ marginBottom: 16, textAlign: 'left' }}>
                            <Checkbox checked={passwordCriteria.length} className="divResetPassword">Au moins 8 caractères</Checkbox><br />
                            <Checkbox checked={passwordCriteria.uppercase} className="divResetPassword">Au moins une majuscule</Checkbox><br />
                            <Checkbox checked={passwordCriteria.number} className="divResetPassword">Au moins un chiffre</Checkbox><br />
                            <Checkbox checked={passwordCriteria.specialChar} className="divResetPassword">Au moins un caractère spécial</Checkbox>
                        </div>

                        <Button
                            type="primary"
                            className="divResetPassword"
                            onClick={handleResetPassword}
                            style={{ width: '50%' }}
                            disabled={isButtonDisabled()}
                        >
                            Valider
                        </Button>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}
