import React, {memo} from 'react';
import {ColumnsType} from "antd/es/table";
import {Badge, Popover, Space, Table, Tag, Typography} from "antd";
import {MdSell} from "react-icons/md";
import {DisplayIconProduct} from "sna-components";

const {Link} = Typography;
function ColisTable({data}: any) {

    if(data === null) return
    const filteredData = data.filter(item => item !== null);

    const columns: ColumnsType= [
        {
            title: 'Id Colis SNA',
            dataIndex: ['IdContenantTMS'],
            key: 'Id',
            render: (id) => <>{id || '-'}</>,
        },{
            title: 'article(s)',
            dataIndex: ['CdeLogisColisD_tail'],
            key: 'article',
            render: (articles) => {

                if (!articles) return <Tag color="red">Aucun article</Tag>;

                const content=
                    articles.map((article: any) => <div key={article.CdeLogisArticle.IdProduit}>
                        <Space direction={'vertical'}>
                            <Space>
                                <DisplayIconProduct iconProps={{size:20}} product={article.CdeLogisArticle.EltClient.TypeProduit}/>
                                <Link underline type='secondary' href={'#'}>{article.CdeLogisArticle.NaturePrd + '-' + article.CdeLogisArticle.IdProduit} - {article.CdeLogisArticle.EltClient.Titre}</Link>
                            </Space>
                        </Space>
                    </div>)

                return <Popover content={content} trigger='hover'>
                        <Badge count={articles.length}>
                            <MdSell size={20}/>
                        </Badge>
                </Popover>
            },
        },
        {
            title: 'Poids',
            dataIndex: 'Poids',
            key: 'quantité',
            render: (qte) => <>{qte + " kg" || '-'}</>,
        },
        {
            title: ' Dimensions (L x l x H)',
            key: 'Dimensions',
            render: (dim) =>
            {

                if (dim.Longueur === 0 && dim.Largeur === 0 && dim.Hauteur === 0) {
                    return <>-</>
                }
                return <>{dim.Longueur} x {dim.Largeur} x {dim.Hauteur}</>
            }
        },
        {
            title: 'expédié le',
            dataIndex: ['KLS_data'],
            key: 'statut',
            render: (statut) =>

                //affichage de la date en 26/12/2020
                 statut !== null ? <Tag color="green">Expédiée le : {
                     new Date(statut.DateChargement).toLocaleDateString('fr-FR', {
                         day: '2-digit',
                         month: '2-digit',
                         year: 'numeric'
                     })
                 }</Tag> : '-'

            ,
        },{
            title: 'statut expédition',
            dataIndex: ['KLS_Contenant'],
            key: 'statut expédition',
            render: (KLS) =>{
                let statut = 'en attente d\'expedition';

                if(KLS.DateChargement !== "1900-01-01T00:00:00.000Z" && KLS.RetourDateLivr_ === "1900-01-01T00:00:00.000Z"){
                    statut = "en cours de livraison"
                }else if(KLS.RetourDateLivr_ !== "1900-01-01T00:00:00.000Z"){
                    statut = "livré"
                }else if(KLS.RetourDateLivr_Apr_sRetour !== "1900-01-01T00:00:00.000Z"){
                    statut = "retournée"
                }
                if(KLS.DateChargement === null){
                    statut = "en attente d'expedition"
                }



              switch (statut) {
                  case 'en attente d\'expedition':
                      return <Tag color="blue">{statut}</Tag>
                  case 'en cours de livraison':
                      return <Tag color="yellow">{statut}</Tag>
                    case 'livré':
                        return <Tag color="green">{statut}</Tag>
                  case 'retournée':
                      return <Tag color="red">{statut}</Tag>
                  default:
                      return <Tag color="blue">{statut}</Tag>
              }
            }
        },{
            title: 'tracking',
            dataIndex: ['KLS_Contenant','RetourURLTracking'],
            render: KLS => {
                if(KLS !== null && KLS !== ""){
                    return <a href={KLS} target="_blank" rel="noopener noreferrer">suivre le colis</a>
                }else{
                    return <p>-</p>
                }
            }
        }
    ];

    return (<Table   pagination={{ pageSize: 17 }} size={'small'} columns={columns} dataSource={filteredData} />)
}

export default memo(ColisTable);