import React, {memo, useContext, useEffect} from 'react';
import {Button, Modal, Space} from "antd";

import {TranslateContext} from "translate-provider";
import {ToastContext} from "toast-provider";
import {Stock} from "sna-components";
import {CredentialFetcher, useFetch} from "hooks-sna";

const BASE_URL = process.env.REACT_APP_API_V2;

function ModalStock({show, setShow, id}){

    // ${BASE_URL}/article/stock-mouv?idProduit=${id}&NaturePrd=EC`
    const params = {
        url: `${BASE_URL}/stock/${id}`,
    }
    const [loading, error, data, hasMore, metadata, progress, fetchData] = useFetch(params)

    let toast = useContext(ToastContext);
    const {t} = useContext(TranslateContext)

    useEffect(() => {
        if (show) fetchData()
    }, [show, id]);

    /**
     * Save the order
     *
     * @returns {JSX.Element}
     * @constructor
     */
    const CustomFooter = () => (
        <div>  <Button onClick={()=>{setShow(false)}}>Close</Button>
        </div>
    );

    const modalTitle = () =>{
        return <Space>Article : {id}</Space>
    }

    const dataDisplay= {
        // FabSNAPrixVente: {
        //     suffix: '€',
        //     NameToDisplay: 'Prix de vente'
        // },
        Titre:'',
        CodeEAN13: '',
        DateCommercialisation:{
          default: '',
          operation: (data)=> {
            return new Date(data).toLocaleDateString()
          }
        },
        CodeDouane:'',
        Longueur: 'cm',
        Largeur: 'cm',
        Hauteur: 'cm',
        Poids: 'g'}

    return(

        <Modal
            mask={false}
            destroyOnClose={true}
            // maskClosable={false}
            footer={<CustomFooter />}
            title={modalTitle()}
            centered
            onCancel={() => setShow(false)}
            open={show}
            width={1500}
        >

          <Stock data={data} dataDisplay={dataDisplay} />


        </Modal>
    );
}
export default memo(ModalStock);