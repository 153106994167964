import React, { memo, useContext } from "react";
import { Tag } from "antd";
import { TranslateContext } from "translate-provider";

function TagOrder({ data }) {
  const { t } = useContext(TranslateContext);
  let statut = null;

  if (!data) return;
  data.syntheseCdeLivraison?.libelleStatut !== "En attente de traitement"
    ? (statut = data.syntheseCdeLivraison?.libelleStatut)
    : (statut = data.syntheseCdeCommande.libelleStatut);

  const transpose = {
    Annulée: "canceled",
    "Annulation en cours": "canceling",
    Expédiée: "shipped",
    "Expédiée partiellement": "partiallyShipped",
    "Expédiée partiellement et soldée": "partiallyShippedAndPaid",
    Reçue: "enAttente",
    Préparée: "prepare",
    "En attente de traitement": "enAttente",
    "En préparation": "enPreparation",
    Livrée: "delivered",
    "Livrée partiellement": "partiallyDelivered",
  };

  switch (statut) {
    case "Annulée":
      return (
        <Tag color="red" key={statut}>
          {t(transpose[statut])}
        </Tag>
      );
    case "Annulation en cours":
      return (
        <Tag color="blue" key={statut}>
          {t(transpose[statut])}
        </Tag>
      );
    case "Expédiée":
      return (
        <Tag color="blue" key={statut}>
          {t(transpose[statut])}
        </Tag>
      );
    case "Expédiée partiellement":
      return (
        <Tag color="orange" key={statut}>
          {t(transpose[statut])}
        </Tag>
      );
    case "Livrée partiellement":
      return (
        <Tag color="orange" key={statut}>
          {t(transpose[statut])}
        </Tag>
      );
    case "Expédiée partiellement et soldée":
      return (
        <Tag color="purple" key={statut}>
          {t(transpose[statut])}
        </Tag>
      );
    case "Reçue":
      return (
        <Tag color="grey" key={statut}>
          {t(transpose[statut])}
        </Tag>
      );
    case "Préparée":
      return (
        <Tag color="cyan" key={statut}>
          {t(transpose[statut])}
        </Tag>
      );

    case "En attente de traitement":
      return (
        <Tag color="yellow" key={statut}>
          {t(transpose[statut])}
        </Tag>
      );
    case "Livrée":
      return (
        <Tag color="green" key={statut}>
          {t(transpose[statut])}
        </Tag>
      );

    case "":
      return (
        <Tag color="grey" key={statut}>
          {t(transpose[statut])}
        </Tag>
      );

    //case for colis handling

    default:
      return (
        <Tag color="grey" key={statut}>
          {t(transpose[statut])}
        </Tag>
      );
  }
}
export default memo(TagOrder);