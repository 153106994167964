import {errorType} from "./useCsvToJson";

export class ControlOperation {

    errors:errorType[] = [];

    constructor(json, fields, refField) {
        this.json = json;
        this.fields = fields;
        this.refField = refField;
        this.removeEmptyRows();
    }

    /**
     * remove Empty Rows based on the refField
     */
    removeEmptyRows() {
        this.json = this.json.filter(item => item[this.refField].trim() !== "");
    }
    getOrderlines() {
        const orderlines = {};
        const refField = this.fields[this.refField].name;
        const removeEmptyOrderNumbers = this.json.filter(item => item[refField].trim() !== "");
        for (let item of removeEmptyOrderNumbers) {
            const lines = removeEmptyOrderNumbers.filter((line) => line[refField] === item[refField]).map((line) => ({
                itemRef: this.checkUTF8(line.RefProduit.trim(), ""),
                itemQty: Number(line.Quantite),
                unitPrice: Number(line.Prix.replace(",", ".") || 0.0),
            }));
            // Remove all other RefCde
            this.json = this.json.filter((line) => line[refField] !== item[refField]);

            // Use RefCde as key in the orderlines object
            orderlines[item[refField]] = lines;
        }
        return orderlines;
    }

    getDistinctRows() {
        return this.json.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t[this.refField] === item[this.refField].trim()
            ))
        );
    }

    getDistinctValues(field) {
        return [...new Set(this.json.map(item => item[field]))];
    }


    /**
     * we check if json key are the exact same as the fields
     */
    checkCsvFile() {
        //this.fields = ProductLightCsv;
       const missingKeys = [];

       if(this.json.length === 0) {
           this.errors.push({message: "The CSV file is empty."});
           return null
       }

       if(Object.keys(this.fields).length !== Object.keys(this.json[0]).length) {
           this.errors.push({message: `The CSV file has the wrong number of columns. Expected ${Object.keys(this.fields).length} columns, but got ${Object.keys(this.json[0]).length} columns.`});
       }


       Object.keys(this.fields).forEach(key => {
            if (!this.json[0].hasOwnProperty(key)) {
                missingKeys.push(key);
            }
        });

        if (missingKeys.length > 0) {
            this.errors.push({message:'The CSV file is missing the following columns', list: missingKeys});
        }

        return true
    }

    checkUTF8(text, defaultValue = "", fieldName = "") {
        if (!text) return defaultValue;
        const nonUTF8Regex = /[^\x00-\x7F]/g;

        if (nonUTF8Regex.test(text)) {
            this.errors.push(`${fieldName}: The text "${text}" contains special characters that are not in the UTF-8 character set.`);
            return text.replace(nonUTF8Regex, "");
        }

        return text;
    }


     mappedToJson = () => {
        return this.json.map(item => {
            const mappedItem = {};
            Object.entries(this.fields).forEach(([key, { name, operation }]) => {
                const value = this.checkUTF8(item[key], "", name);
                if (value !== undefined) {
                    // Assure que value est bien une chaîne avant de l'utiliser
                    mappedItem[name] = typeof value === "string" ? operation(value) : value;
                }
            });
            return mappedItem;
        });
    };
    
    getErrors() {
        return this.errors;
    }

}