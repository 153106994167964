import React, {createContext,} from 'react';
import {useLocation} from 'react-router-dom';
import dayjs from 'dayjs';

export let FilterContext = createContext(null);

function FilterProvider({ children }) {

  //récupération des params d'url
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);


  /**
   * Fonction pour récupérer les valeurs d'un paramètre de l'URL
   * @param param nom du paramètre
   * @param defaultValue valeur par défaut si le paramètre n'existe pas
   * @return {string|string[]|string} valeur du paramètre
   */
  const getQueryParam = (param, defaultValue = '') => {
    const value = queryParams.getAll(param);
    if(value.length===1) return value[0];
    if(value.length>1) return value;
    return defaultValue;
  };

  /**
   * Fonction pour récupérer les valeurs d'un paramètre de l'URL sous forme de tableau
   * @param param
   * @return {[]}
   */
  const getArrayQueryParam = (param) => {
    const ArrayValues = queryParams.getAll(param);
    let result = [];

    ArrayValues.forEach(value => {
      const splitValues = value.split(',');
      // Supprimer les espaces autour de chaque élément du tableau résultant
      const trimmedValues = splitValues.map(val => val.trim());
      // Ajouter les éléments au résultat final
      result = result.concat(trimmedValues);
    });
    return result;
  };

  /**
   * Fonction pour récupérer tous les paramètres de l'URL
   * @return {{}}
   */
  const getAllParam = () => {
    const params = {};
    for (const [key, value] of queryParams.entries()) {
      params[key] = value;
    }
    return params;
  }


  /**
   * Fonction pour créer un nouvel objet URLSearchParams à partir des valeurs de filtre
   * @param values
   * @return {URLSearchParams}
   */
  function getNewQueryParams(values) {
    const newQueryParams = new URLSearchParams();

    Object.entries(values).forEach(([key, value]) => {
      if (!value || value === 'default' || value.length === 0) return;
      if (Array.isArray(value)) {
        // Si c'est un tableau, vérifiez si la valeur contient une virgule
        const formattedValue = value.map((item) => (item.includes(',') ? item.split(',') : item));
        newQueryParams.append(key, formattedValue);
      } else {
        const paramValue = key.includes('Date') ? dayjs(value).format('YYYY-MM-DD') : value;
        newQueryParams.append(key, paramValue);
      }
    });
    return newQueryParams;
  }

  /**
   * Fonction pour mettre à jour les paramètres de l'URL
   * @param values
   */
  const updateURL = (values) => {
    const newQueryParams = getNewQueryParams(values);
    //renvoi l'url avec les nouveaux paramètres
    const queryString = [...newQueryParams.entries()].map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&');

    if (queryString || window.location.search) {

      const newURL = queryString ? `?${queryString}` : '';
      const currentPath = window.location.pathname;

      // Utilisez location.pathname pour éviter de rafraîchir la page
      if (newURL !== window.location.search) window.history.replaceState({}, '', `${currentPath}${newURL}`);
    }
  };

  // Fournissez les fonctions de filtre via le contexte
  return (
      <FilterContext.Provider value={{ getQueryParam, getAllParam, getArrayQueryParam, updateURL }}>
        {children}
      </FilterContext.Provider>
  );
}

export default FilterProvider;
