import {memo, useCallback, useContext, useEffect, useRef, useState} from "react";
import axios from "axios";


const cache = new Map();



function useFetchOrder(url, filter) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [currentPage, setCurrentPage] = useState("");
    const [firstPage, setFirstPage] = useState("");
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setupRequestInterceptor(setProgress);
        setupResponseInterceptor(setProgress);
    }, []);

    let params = {
        withCredentials: true,
        method: 'GET',
        url: url,
        params: {...filter},
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };

    const Load = useCallback(async () => {
        const cacheKey = JSON.stringify({url, filter});

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setHasMore(true);
        setLoading(true);
        setError(false);

        axios(params,{
            cancelToken: source.token,
            onDownloadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(percentCompleted);
            },
        }).then(response=>{
            setData(response.data);

            if(data.length >= response.data['hydra:totalItems']){
                setHasMore(false);
            }
            setLoading(false);

        }).catch(e=>{
            if(axios.isCancel(e)) return;
            setLoading(false);
            setError(true);
            setHasMore(false);
        });

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, [url, filter]);

    return [loading, error, data, Load];
}

function setupRequestInterceptor(setProgress) {
    axios.interceptors.request.use((config) => {
        setProgress(0);
        return config;
    });
}

function setupResponseInterceptor(setProgress) {
    axios.interceptors.response.use(
        (response) => {
            setProgress(100);
            return response;
        },
        (error) => {
            setProgress(100);
            return Promise.reject(error);
        }
    );
}

export default useFetchOrder;



// function useFetchOrder(url, filter) {
//
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(false);
//     const [data, setData] = useState([]);
//     const [metadata, setMetadata] = useState([]);
//     const [hasMore, setHasMore] = useState(false);
//     const [currentPage, setCurrentPage] = useState("");
//     const [firstPage, setFirstPage] = useState("");
//     const [progress, setProgress] = useState(0);
//
//
//     useEffect(() => {
//         setupRequestInterceptor(setProgress);
//         setupResponseInterceptor(setProgress);
//     }, []);
//
//     let params = {};
//     params =Object.assign({
//         withCredentials: true,
//         method: 'GET',
//         url: url,
//         params: {...filter},
//         headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json',
//         }
//     }, params);
//
//
//     const Load = useCallback(async () => {
//         console.log("fetch Order")
//         //if the url and filter are the same, we don't need to fetch again
//         const cacheKey = JSON.stringify({url, filter});
//         console.log(cacheKey)
//
//         // if (cache.has(cacheKey)) {
//         //     console.log('cache hit')
//         //     setData(cache.get(cacheKey));
//         //     setLoading(false);
//         //     return;
//         // }
//
//         const CancelToken = axios.CancelToken;
//         const source = CancelToken.source();
//         setHasMore(true);
//         setLoading(true);
//         setError(false);
//
//         console.log(params)
//
//         axios(params,{
//             cancelToken: source.token,
//             onDownloadProgress: (progressEvent) => {
//                 const percentCompleted = Math.round(
//                     (progressEvent.loaded * 100) / progressEvent.total
//                 );
//                 setProgress(percentCompleted);
//             },
//         }).then(response=>{
//             cache.set(cacheKey, response.data);
//             // setData(prevData => {
//             //     return [...new Set([...prevData, ...response.data['hydra:member']])]
//             // });
//
//             setData([])
//             setData(response.data)
//
//
//             if(data.length >= response.data['hydra:totalItems']){
//                 setHasMore(false);
//             }
//             setLoading(false);
//
//         }).catch(e=>{
//             if(axios.isCancel(e)) return;
//             setLoading(false);
//             setError(true);
//             setHasMore(false);
//
//         });
//
//         // return () => cancel();
//         return () => {
//             source.cancel('Operation canceled by the user.');
//         };
//     }, [url, filter]);
//
//
//
//     return [loading, error, data, Load];
// }
//
// export default useFetchOrder;
//
// function setupRequestInterceptor(setProgress) {
//     axios.interceptors.request.use((config) => {
//         setProgress(0);
//         return config;
//     });
// }
//
// function setupResponseInterceptor(setProgress) {
//     axios.interceptors.response.use(
//         (response) => {
//             setProgress(100);
//             return response;
//         },
//         (error) => {
//             setProgress(100);
//             return Promise.reject(error);
//         }
//     );
// }