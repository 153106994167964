import useAuth from "auth-sna";

export default function NoMatch() {


    return (
        <div className='h-100 d-flex justify-content-center align-items-center'>
                 <h1>404: Page not found</h1>
        </div>
    );
}