import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContext } from "toast-provider";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

export let AuthContext = createContext(null);
const BASE_URL = process.env.REACT_APP_API_V2;
const INTRANET_URL = process.env.REACT_APP_API_INTRANET;

export default function AuthProvider({ children }: { children: ReactNode }) {

    let location = useLocation();
    let navigate = useNavigate();
    let toast = useContext(ToastContext);
    const [navigation, setNavigation] = useState([]);
    let [, setSessionExpired] = useState(false);

    const getCookie = () => {
        let cookieStr = document.cookie;
        let cookieObj = {};
        let cookieArr = cookieStr.split(';');
        cookieArr.forEach(cookie => {
            let [key, value] = cookie.trim().split('=');
            cookieObj[key] = value;
        });
        return cookieObj;
    }

    let getRole = () => {
        let cookie = getCookie().role || null;
        if (!cookie) return null;
        const role = JSON.parse(decodeURIComponent(getCookie().role));
        return role.roles;
    };

    const admin = ['ROLE_ADMIN', 'ROLE_STAFF'];
    let isAdmin = () => {
        let role = getRole();
        return admin.includes(role);
    };

    let getUser = () => {
        let cookie = getCookie().role || null;
        if (!cookie) return null;
        return JSON.parse(decodeURIComponent(getCookie().role));
    };

    let getLogin = () => {
        let user = getUser();
        if (!user) return null;
        return user.Login;
    }

    async function getNavigation({ exclude = [] } = {}) {

        if (navigation.length > 0) return navigation;
        //fetch /auth/userNavigation

        try {
            const response = await fetch(`${BASE_URL}/auth/userNavigation`, {
                method: "GET",
                credentials: 'include'
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.message || "an error occurred");
            }
            const data = await response.json();
            const filterData = data.filter((item) => {
                return !exclude.includes(item.API_navigation.webSite)
            });

            setNavigation(filterData);
            return filterData;
        } catch (e) {
            console.error(e);
            return [];
        }

    }

    let [user, setUser] = useState(getUser());
    let [role, setRole] = useState(getRole());
    let [login, setLogin] = useState(getLogin());

    const checkUser = useCallback(() => {
        return document.cookie.includes('markerLoginCookie=true');
    }, []);

    const excludedRoutes = ['/request-new-password', '/signin'];

    // Check if the route should be excluded, including dynamic routes
    const isExcludedRoute = (pathname) => {
        // Define a regex pattern for the dynamic route
        const dynamicRoutePattern = /^\/new-password\/[^/]+$/;
        return excludedRoutes.includes(pathname) || dynamicRoutePattern.test(pathname);
    };

    let signIn = async (newUser: string, password: string, callback: VoidFunction, setLoading, remember, setError, mode) => {
        const httpMode = window.location.protocol;
        setLoading(true);
        try {
            if (mode === 'intranet') {
                const responseApi = await fetch(`${INTRANET_URL}/auth`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        username: newUser,
                        password: password,
                        remember: remember,
                        fullProfile: true,
                        secure: httpMode === 'https:',
                    }),
                    credentials: 'include'
                });
            }

            const response = await fetch(`${BASE_URL}/auth`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: newUser,
                    password: password,
                    remember: remember,
                    fullProfile: true,
                    secure: httpMode === 'https:',
                }),
                credentials: 'include'
            });

            if (!response.ok) {
                const data = await response.json();
                setError(data.message || "an error occurred");
                throw new Error(data.message || "an error occurred");
            }

            const data = await response.json();
            setLoading(false);

            localStorage.setItem("user", JSON.stringify(data['access_token']));
            localStorage.setItem("transporteurs", JSON.stringify(data['transporteurs']));
            localStorage.setItem("cities", JSON.stringify(data['cities']));
            localStorage.setItem("services", JSON.stringify(data['services']));
            localStorage.setItem("countries", JSON.stringify(data['countries']));

            setUser(getUser());
            setRole(getRole());
            setLogin(getLogin());
            callback();
        } catch (e) {
            console.error(e);
            setLoading(false);
            setError(e.message);
        }
    };

    const havePermissionTo = (accessName: string) => {
        const user = localStorage.getItem('user');
        if (!user) return false;
        const tokenDecode = jwt_decode(JSON.parse(user));
        return tokenDecode[accessName];
    }

    const removeCookie = (name, path, domain) => {
        if (document.cookie.split(';').some((item) => item.trim().startsWith(`${name}=`))) {
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}; domain=${domain};`;
        }
    };

    let signOut = (callback: VoidFunction) => {
        setUser(null);
        const cookieName = 'markerLoginCookie';
        const cookiePath = '/';
        const cookieDomain = '.snagz.fr';  // Replace with the actual domain used to set the cookie
        const cookieDomain2 = 'api.snagz.fr';  // Replace with the actual domain used to set the cookie

        fetch(`${BASE_URL}/auth/logout`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
            },
            credentials: 'include'
        }).then(r => {
            localStorage.clear();
            Cookies.remove(cookieName);
            removeCookie(cookieName, cookiePath, cookieDomain);
            removeCookie(cookieName, cookiePath, cookieDomain2);
            callback();
            navigate('/signin');
        });
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!checkUser() && !isExcludedRoute(location.pathname)) {
                signOut(() => {
                    toast.error('Session expired, You must be logged in to view this page.', 10);
                });
            }
        }, 10000);

        return () => clearInterval(intervalId);
    }, [checkUser, location.pathname, signOut, toast]);

    useEffect(() => {
        // Ensure public routes are accessible without authentication
        if (isExcludedRoute(location.pathname)) return;

        if (location.pathname === '/' && checkUser()) {
            navigate('/dashboard');
        } else if (checkUser() && isExcludedRoute(location.pathname)) {
            navigate('/dashboard');
        } else if (!checkUser() && !isExcludedRoute(location.pathname)) {
            navigate('/signin');
        }
    }, [checkUser, location.pathname, navigate]);

    let value = { user, login, role, signIn, signOut, getUser, getNavigation, getRole, isAdmin, havePermissionTo };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
