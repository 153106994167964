import React, { useEffect, useState, useContext } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Space, Modal, Image } from 'antd';
import { InputNumber } from "antd";
import { ToastContext } from "toast-provider";
import { ClientSearch } from "sna-components";
import { spinner } from "sna-style";

const REACT_APP_API_V2 = process.env.REACT_APP_API_V2

const REACT_APP_API_INTRANET = process.env.REACT_APP_API_INTRANET

const UseNewKit = ({ prev, jsonData , resetFields}) => {
    const [form] = Form.useForm();
    const toast = useContext(ToastContext);
    const [client, setClient] = useState(jsonData.length > 0 ? jsonData[0].CptClient : "");
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false); 

    const showModal = () => setVisible(true);

    const handleCancel = () => setVisible(false);

    const handleConfirm = () => {
        onFinish();
        setVisible(false);
    };

    const handleVerifyOccurrence = async () => {
        try {
            if (!client || client.length !== 6) {
                toast.error("Veuillez entrer un CptClient valide", 10);
            } else {
                const formData = await form.validateFields();
                const verifyNbrOccurence = formData.items.some(item =>
                    item.list.some(subItem => subItem.NbrOccurence >= 2)
                );
                verifyNbrOccurence ? showModal() : onFinish();
            }
        } catch (error) {
            toast.error("Des champs remplis sont incorrects ou manquants", 10);
        }
    };

    useEffect(() => {
        const groupedItems = jsonData.reduce((grouped, item) => {
            const existingItem = grouped.find(existing => existing.RefKit === item.RefKit);

            existingItem ? existingItem.list.push({ ...item }) :
                grouped.push({ RefKit: item.RefKit, CptClient: client, list: [{ ...item }] });

            return grouped;
        }, []);

        form.setFieldsValue({ items: groupedItems });
    }, [jsonData, form, client]);


    const onFinish = async () => {
        //Ajouter une verification pour vérifier si le RefKit avec le compte client existe
        try {
            setLoading(true);
            const formData = await form.validateFields();
            const restructuredData = {};

            const refProduitsParKit = {};

            formData.items.forEach(item => {
                const { RefKit, list } = item;

                // Réinitialiser le tableau des RefProduit pour chaque nouveau kit
                refProduitsParKit[RefKit] = [];

                list.forEach(subItem => {
                    let { RefProduit } = subItem;

                    RefProduit = RefProduit.toLowerCase();

                    // Vérifier si la RefProduit existe déjà dans le kit actuel (en minuscules)
                    if (refProduitsParKit[RefKit].includes(RefProduit)) {
                        throw new Error(`La RefProduit '${RefProduit}' est présente plusieurs fois dans le kit '${RefKit}'`);
                    }

                    // Ajouter la RefProduit au tableau des RefProduit pour ce kit
                    refProduitsParKit[RefKit].push(RefProduit);

                    const modifiedSubItem = Object.fromEntries(
                        Object.entries(subItem).map(([key, value]) => [key, value === undefined ? "" : value])
                    );

                    if (!restructuredData[RefKit]) {
                        restructuredData[RefKit] = [];
                    }
                    restructuredData[RefKit].push({
                        ...modifiedSubItem,
                        CptClient: client,
                        RefKit
                    });
                });
            });

            const response = await fetch(`${REACT_APP_API_INTRANET}/import-kit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(restructuredData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                const errorMessage = errorData.message;

                throw new Error(errorMessage);
            }

            resetFields();

            const message = await response.text();
            toast.success("Import effectué avec succès");
            setLoading(false); 
            return message;

        } catch (error) {
            setLoading(false); 
            console.log("erreur:", error)
            toast.error(error.message || "Des champs remplis sont incorrects ou manquants", 10);
        }
    };

    function verifierEAN13(code) {
        let somme = 0;
        for (let i = 0; i < 12; i++) {
            somme += parseInt(code[i]) * (i % 2 === 0 ? 1 : 3);
        }
        let chiffreControle = (10 - (somme % 10)) % 10;
        return chiffreControle === parseInt(code[12]);
    }

    const checkRefExistence = async (ref, index) => {
        try {
            const response = await fetch(`${REACT_APP_API_V2}/article?RefProduit=${ref}`, { credentials: 'include' });
            const result = await response.json();

            if (!result || result.length === 0) {
                return false;
            } else {
                return true;
            }
        } catch (error) {
            return false;
        }
    };

    const addNewItem = () => {
        const newItem = { RefKit: "", CptClient: client, list: [{ EAN13Kit: "", NbrOccurence: "", PrixUnitTTC: "", RefProduit: "", Remarque: "" }] };
        form.setFieldsValue({ items: [...form.getFieldValue('items'), newItem] });
    };

    return (
        <>
            <Form
                form={form}
                name="dynamic_form_complex"
                autoComplete="off">
                     {loading && (
                <div className='w-full d-flex justify-content-center align-items-center' style={{ height: '10vh' }}>
                    <Image src={spinner} width={50} />
                </div>
            )}
                <Form.List name="items">
                    {(fields, { add, remove }) => (
                        <div
                            style={{
                                display: 'flex',
                                rowGap: 16,
                                flexDirection: 'column',
                            }}>
                            <div style={{ flex: 1, marginBottom: 30 }}>
                                <Form.Item label="CptClient" name="CptClient"
                                    onChange={(e) => setClient(e.target.value)}
                                >
                                    <ClientSearch
                                        name={'CptClient'}
                                        form={form}
                                        defaultValue={client}
                                        setClient={setClient} />
                                </Form.Item>
                            </div>
                            {fields.map((field, index) => (
                                <Card
                                    size="small"
                                    style={{ paddingBottom: "30px" }}
                                    title={`Kit ${field.name + 1}`}
                                    key={field.key}
                                    extra={
                                        <CloseOutlined
                                            onClick={() => {
                                                remove(field.name);
                                            }}
                                        />
                                    }>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25, marginTop: 25, width: '100%' }}>
                                        <div style={{ display: 'flex', maxWidth: '60%', width: '100%', marginBottom: '30px' }}>
                                            <div style={{ flex: 1, marginRight: '25px' }}>
                                                <Form.Item label="RefKit" name={[field.name, 'RefKit']} style={{ marginBottom: 0 }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Entrer un RefKit"
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                const allRefKits = getFieldValue('items').map(item => item.RefKit);
                                                                const isUnique = allRefKits.filter(refKit => refKit === value).length === 1;
                                                                if (!isUnique) {
                                                                    return Promise.reject('Chaque RefKit doit être unique');
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}>
                                                    <Input />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: "center" }}>
                                        <p style={{ marginRight: 100 }}>RefProduit</p>
                                        <p style={{ marginRight: 20 }}>EAN13Kit</p>
                                        <p style={{ marginLeft: 80, marginRight: 20 }}>NbrOccurence</p>
                                        <p style={{ marginRight: 50 }}>PrixUnitTTC</p>
                                        <p style={{ marginRight: 50 }}>Remarque</p>
                                    </div>
                                    <Form.List name={[field.name, 'list']} >
                                        {(subFields, subOpt) => (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    rowGap: 40,
                                                }}>
                                                {subFields.map((subField) => (
                                                    <Space key={subField.key}>
                                                        <Form.Item name={[subField.name, 'RefProduit']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    validator: async (_, value) => {
                                                                        if (!value) {
                                                                            throw new Error('Veuillez entrer une valeur pour RefProduit');
                                                                        }
                                                                        const refExists = await checkRefExistence(value);
                                                                        if (!refExists) {
                                                                            throw new Error('La référence RefProduit n\'existe pas');
                                                                        }
                                                                    }
                                                                }
                                                            ]}>
                                                            <Input placeholder="Référence Produit" />
                                                        </Form.Item>
                                                        <Form.Item name={[subField.name, 'EAN13Kit']}
                                                            rules={[
                                                                {
                                                                    validator: async (_, fieldValue) => {
                                                                        if (fieldValue && !verifierEAN13(fieldValue)) {
                                                                            throw new Error('Veuillez entrer un EAN13Kit valide');
                                                                        }
                                                                    }
                                                                }
                                                            ]}
                                                        >
                                                            <Input placeholder="EAN13Kit" />
                                                        </Form.Item>
                                                        <Form.Item name={[subField.name, 'NbrOccurence']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    validator: async (_, value) => {
                                                                        if (value >= 1) {
                                                                            return Promise.resolve(); // Pas d'erreur si le nombre est égal ou supérieur à 1
                                                                        } else {
                                                                            return Promise.reject(new Error('Nombre min 1')); // Renvoie une erreur sinon
                                                                        }
                                                                    }
                                                                }
                                                            ]}
                                                        >
                                                            <InputNumber min={1} max={99} placeholder="NbrOccurence" />
                                                        </Form.Item>
                                                        <Form.Item name={[subField.name, 'PrixUnitTTC']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                            ]}
                                                        >
                                                            <InputNumber  placeholder="PrixUnitTTC"  step={0.01} />
                                                        </Form.Item>
                                                        <Form.Item name={[subField.name, 'Remarque']}>
                                                            <Input placeholder="Remarque" />
                                                        </Form.Item>
                                                        <CloseOutlined
                                                            onClick={() => {
                                                                subOpt.remove(subField.name);
                                                                // Supprime l'élément parent si toutes les lignes sont supprimées
                                                                if (subFields.length === 1) {
                                                                    remove(field.name);
                                                                }
                                                            }}
                                                        />
                                                    </Space>
                                                ))}
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                    <Button type="dashed" onClick={() =>
                                                        subOpt.add()
                                                    }>
                                                        Ajouter un produit au kit
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </Form.List>
                                </Card>
                            ))}
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <Button style={{ margin: "30px", width: '15%' }} type="dashed" onClick={() => {
                                    addNewItem(client)
                                }
                                } block>
                                    Ajouter un nouveau kit
                                </Button>
                            </div>
                        </div>
                    )}
                </Form.List>
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: 10 }}>
                    <Button type="primary" onClick={prev}>Prev</Button>
                    <Button style={{ marginBottom: 20 }} type="primary" htmlType="button" onClick={handleVerifyOccurrence} >Valider</Button>
                </div>
            </Form>
            <Modal
                open={visible}
                title="Confirmation"
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Annuler
                    </Button>,
                    <Button key="confirm" type="primary" onClick={handleConfirm}>
                        Confirmer
                    </Button>,
                ]}>
                <p>Êtes-vous sûr du nombre d'occurence?</p>
            </Modal>
        </>
    );
};
export default UseNewKit;

