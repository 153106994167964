import React, {memo, useCallback, useState} from 'react';
import {DatePicker, Form, Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {debounce} from "lodash";
import {useUserCookie} from "hooks-sna";

const { RangePicker } = DatePicker;

function SearchForm(props) {
    const [
        transporteurs,
        cities,
        countries,
        services,
    ] = useUserCookie([
        'transporteurs',
        'cities',
        'countries',
        'services',
    ]);

    const[showOptions, setShowOptions] = useState(false);

    const debouncedUpdateFilter = debounce((query) => {
        props.updateFilter({ query: query });
    }, 500);

    const debouncedCityUpdate = useCallback(debounce((e) => {
        props.updateFilter({ city: e ? e : null });
    }, 500), []); // Added debounced city update

    /**
    * Gestion de la recherche
    * @param event
    */
    const handleQueryChange = (event) => {
        const query = event.target.value.trim();

        debouncedUpdateFilter(query);
    };

  return (
    <Form className="bg-transparent">
    <div className="ant-form-item d-flex flex-row justify-content-between align-items-center mb-4">
        <Input
            rootClassName="flex-grow-1"
            bordered={true}

            allowClear={true}
            onClear={()=>{props.updateFilter({query: null})}}
            size={'large'}
            prefix={<SearchOutlined className="site-form-item-icon" />}
            placeholder="id produit, ref produit, titre ..."
            onChange={handleQueryChange}
            // onKeyDown={handleKeyDown}
        />
        {/* <Button onClick={()=>{setShowOptions(!showOptions)}} type="text" className="flex-grow-1 w-10" >Plus d'options <CaretDownOutlined /></Button> */}
    </div>

    {/*<div className={!showOptions ? "container-fluid hidden z-1": "container-fluid z-1"}>*/}
    {/*    <div className="row gap-2 justify-content-center ">*/}
    {/*        <Form.Item className="col" name={"country"}>*/}
    {/*            <Select*/}

    {/*                mode="multiple"*/}
    {/*                allowClear*/}
    {/*                placeholder="Pays de livraison"*/}
    {/*                onChange={(e)=>{*/}
    {/*                    props.updateFilter({*/}
    {/*                        country: e*/}
    {/*                    })*/}
    {/*                }}*/}
    {/*                options={countries}*/}
    {/*            />*/}
    {/*        </Form.Item>*/}
    {/*        <Form.Item  className=" col flex-grow-1" name={"city"}>*/}
    {/*            <AutoComplete*/}
    {/*                allowClear*/}

    {/*                options={cities}*/}

    {/*                onChange={debouncedCityUpdate}*/}
    {/*                placeholder="Ville de livraison"*/}
    {/*                filterOption={(inputValue, option) =>*/}
    {/*                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1*/}
    {/*                }*/}
    {/*            />*/}
    {/*        </Form.Item>*/}
    {/*        <Form.Item o className="col"  name={"transporteur"}>*/}
    {/*            <Select*/}

    {/*                mode="multiple"*/}
    {/*                allowClear*/}
    {/*                placeholder="Transporteur"*/}
    {/*                onChange={(e)=>{*/}
    {/*                    props.updateFilter({*/}
    {/*                        transporteur: e*/}
    {/*                    })*/}
    {/*                }}*/}
    {/*                options={transporteurs}*/}
    {/*            />*/}
    {/*        </Form.Item>*/}
    {/*        <Form.Item className="col" name={"Services"}>*/}
    {/*            <Select*/}

    {/*                mode="multiple"*/}
    {/*                allowClear*/}
    {/*                placeholder="Services"*/}
    {/*                onChange={(e)=>{*/}
    {/*                    props.updateFilter({*/}
    {/*                        service: e*/}
    {/*                    })*/}
    {/*                }}*/}
    {/*                options={services}*/}
    {/*            />*/}
    {/*        </Form.Item>*/}


    {/*    </div>*/}
    {/*   */}

    {/*</div>*/}
</Form>
  );
}

export default memo(SearchForm);