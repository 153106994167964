import {LogoSNA as logoBlanc, LogoSNANoir as logo} from "sna-style";
import {Typography} from "antd";
import React, {useContext} from "react";
import {Outlet} from "react-router-dom";
import {CustomThemeContext} from "theme-provider";

const { Text,  } = Typography;
const BASE_URL = process.env.REACT_APP_API_V2;
export default function Home(){
    const { theme, getTheme } = useContext(CustomThemeContext);



    return (
        <div className="container-fluid h-100 d-flex flex-column justify-content-between">
            <div className="row vh-90 align-items-center">
                <div className="col-lg-6">
                </div>
                <div className="col-lg-6 col-sm-12 d-flex flex-column align-items-center z-2">
                    {
                        theme !== 'light' ?
                            <img className="mb-5" width="400px" src={logoBlanc} alt="Logo SNA-GZ"/>
                            :
                            <img className="mb-5" width="400px" src={logo} alt="Logo SNA-GZ"/>
                    }

                    <Outlet/>
                </div>
            </div>
            <footer className="h-25">
                <Text style={{color:'white',fontWeight:100, opacity:0.8, letterSpacing:1}} type="secondary">Copyright © SNA-GZ {new Date().getFullYear()} - {process.env.REACT_APP_VERSION || '1.0.0'}</Text>
                <p className="fw-lighter fs-6"></p>
            </footer>
        </div>

    );
}