import React, {memo, useMemo} from 'react';
import Cookies from 'js-cookie';

/**
 * transform cookie data to SelectProps['option'] array
 */

export function useUserCookie(cookieKeys) {
    return useMemo(() => {
        return cookieKeys.map((cookieKey) => {
            let cookieValue;
            try {
                cookieValue = localStorage.getItem(cookieKey);
                if (cookieValue !== undefined) {
                    const parsedValue = JSON.parse(cookieValue);

                    if (parsedValue !== null) {  // Ajout de cette condition
                        if (cookieKey === 'countries') {
                            return parsedValue.map((item) => ({ label: item.Nom, value: item.CodeLettre }));
                        }

                        if (Array.isArray(parsedValue)) {
                            return parsedValue.map((item) => ({ label: item, value: item }));
                        } else {
                            return parsedValue;
                        }
                    }
                }
                return null;
            } catch (e) {
                console.log(e);
                return null;
            }
        });
    }, [cookieKeys]);
}
