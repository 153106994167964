import React from "react";
import {ColumnsType} from "antd/es/table";
import {Carousel, Col, Collapse, Descriptions, Row, Space, Typography} from "antd";
import BilanStock from "./BilanStock";
import {ImageCustom, ItemOrders} from "sna-components"

import StockMouvTable from "./StockMouvTable";
import useAuth from "auth-sna";

const { Text } = Typography
const BASE_URL = process.env.REACT_APP_API_V2;
function Stock({data, dataDisplay}){
    const user = useAuth()



    if(data.length === 0) {
          return (<h5 className='text-center' >Stock indisponible </h5>)
      }

    async function updateArticle(values) {
        const response = await fetch(`${BASE_URL}/article/update`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        });

        if (response.ok) {
            const article = await response.json();
            toast.success('Order successfully Updated');
            return article;
        } else {
            toast.error('Failed to update order, try later');
            return null;
        }
    }


    const columns: ColumnsType= [
        {
            title: 'En Stock',
            dataIndex: 'stock',
            key: 'stock',
            render: (qte) => <>{qte || '0'}</>,
        },
        {
            title: 'A venir',
            dataIndex: 'aVenir',
            key: 'aVenir',
            render: (qte) => <>{qte || '0'}</>,
        },
        {
            title: 'Stock + A Venir',
            dataIndex: 'enStockAvenir',
            key: 'StockAvenir',
            render: (qte) => <>{qte || '0'}</>,
        },
        {
            title: 'Stock Réservé',
            dataIndex: 'stockReserve',
            key: 'stockReserve',
            render: (qte) => <>{qte || '0'}</>,
        },
        {
            title: 'TOTAL',
            dataIndex: 'stockNonreserve',
            key: 'stockNonreserve',
            render: (qte) => <>{qte || ''}</>,
        },

    ];

    const displayData = (data, dataName) => {
        return Object.entries(dataName).map(([propertyName, propertyAttributes], index) => {

            let item = data[propertyName];
            if (item === undefined) {
                return null;
            }

            let suffix = '';
            let displayName = propertyName;

            if (typeof propertyAttributes === 'object') {
                suffix = propertyAttributes.suffix || '';
                displayName = propertyAttributes.NameToDisplay || propertyName;
                if (propertyAttributes.operation) {
                    item = propertyAttributes.operation(item);
                }
            } else {
                suffix = propertyAttributes;
            }

            return (

                <Descriptions.Item label={displayName}>{item +' '+ suffix}</Descriptions.Item>
                // <li key={index}>
                //     {displayName + ' : ' + item +' '+ suffix}
                // </li>
            );
        });
    }


    const contentStyle: React.CSSProperties = {
        margin: 0,
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };

    const displayImage = (data, dataName: []) => {

          return (
              <Carousel>
                  <div>
                      <h3 style={contentStyle}>1</h3>
                  </div>
                  <div>
                      <h3 style={contentStyle}>2</h3>
                  </div>
                  <div>
                      <h3 style={contentStyle}>3</h3>
                  </div>
                  <div>
                      <h3 style={contentStyle}>4</h3>
                  </div>
              </Carousel>
          )

    }




    return(<h2 >

        <Row>
            <Col span={12}>
                <Row>
                    {
                        data.LienURLImage !== '' && data.LienURLImageA !== null ?
                                    <ImageCustom src={data.LienURLImage} />
                            : null
                    }
                    <Col className="m-2" span={12}>
                        <Descriptions column={1} >
                            {displayData(data, dataDisplay)}
                        </Descriptions>

                    </Col>
                </Row>



            </Col>
            <Col span={12}>
                {
                    data ?
                        <BilanStock idProduit={data.id_Produit} />
                        : null
                }
            </Col>
        </Row>

    <Space className="w-100" direction={"vertical"}>
        <Collapse ghost >
            <Collapse.Panel forceRender header="Mouvements de stock" key="1">
                <StockMouvTable id={data.id_Produit} />
            </Collapse.Panel>
            <Collapse.Panel forceRender header="Commandes avec cet article" key="2">
                <ItemOrders id={data.id_Produit} />
            </Collapse.Panel>
        </Collapse>
    </Space>

    </h2>)
}
export default Stock;