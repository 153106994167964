import React, {useEffect, useState} from "react";

export default function ProgressBarLoader({loading,progress}){
    const [displayLoading, setDisplayLoading] = useState(false);

    // useEffect(() => {
    //     if (loading) {
    //         setDisplayLoading(true);
    //         setTimeout(() => {
    //             setDisplayLoading(false);
    //         }, 2000);
    //     }
    // }, [loading]);


    return (

        <div height="5px" className="progress-container d-flex w-100 justify-content-start">
            <div className="progress-bar" role="progressbar" height="5px"  aria-valuemin="0" aria-valuemax="100" style={{width: `${progress}%`}}>

            </div>

        </div>
    )
}